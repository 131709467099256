import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import {
  MozartSelectField,
  MozartTextBox,
  MozartIcon,
  MozartTable,
  MozartDatePicker,
  MozartModal,
  MozartPopover
} from '@pgforsta/mozart_ui';
import './PriorityTable.scss';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { loadPriorityTable, loadMorePriorityTable, exportReport } from 'redux/actions/priorityTableAction';
import PropTypes from 'prop-types';
import moment from 'moment';
import { convertUTCToCSTTime } from 'utils/util-element';

class PriorityTable extends Component {
  constructor(props) {
    super(props);
    let defaultPriority = 3;
    if (this.props.currentPriorityLevel === 1 || this.props.currentPriorityLevel === 0) {
      defaultPriority = 'HIGH';
    }
    if (this.props.currentPriorityLevel === 2) {
      defaultPriority = 'MEDIUM';
    }
    if (this.props.currentPriorityLevel === 3) {
      defaultPriority = 'NORMAL';
    }
    this.startDate = moment().subtract(30, 'days').format('YYYYMMDD');
    this.endDate = moment(new Date()).tz('America/Chicago').format('YYYYMMDD');
    this.state = {
      currentRole: this.props.meRolesReducer && this.props.meRolesReducer.data[2] ? this.props.meRolesReducer.data[2].roleId : 471,
      currentFromDate: this.startDate,
      currentToDate: this.endDate,
      currentUser: '',
      currentPriorityLevel: defaultPriority,
      offset: 0,
      limit: 20,
      currentSpecialCase: 30,
      curreentClient: this.props.currentClient,
      notification_msg: '',
      notification_open: false,
      pageLoadMore: 0
    };
    this.datePick = React.createRef();
    // this.handleScrollForPagination = this.handleScrollForPagination.bind(this);
  }

  componentDidMount() {
    document.removeEventListener('scroll', this.handleScrollForPagination.bind(this), false);
    document.addEventListener('scroll', this.handleScrollForPagination.bind(this));
  }

  getFilterBlock() {
    const responderOptions = [];
    if (this.props.meRolesReducer && this.props.meRolesReducer.data) {
      this.props.meRolesReducer.data.map((item) => {
        if (item.roleName !== 'Manager') {
          responderOptions.push({ name: item.roleName, value: item.roleId });
        }
        return true;
      });
    }
    let defaultPriority = 3;
    if (this.props.currentPriorityLevel === 1) {
      defaultPriority = 'HIGH';
    }
    if (this.props.currentPriorityLevel === 2) {
      defaultPriority = 'MEDIUM';
    }
    if (this.props.currentPriorityLevel === 3) {
      defaultPriority = 'NORMAL';
    }
    const priorityOptions = [{ name: 'High', value: 'HIGH' }, { name: 'Medium', value: 'MEDIUM' }, { name: 'Normal', value: 'NORMAL' }];
    return (
      <Row style={{ paddingBottom: '10px', margin: '0px' }}>
        <Col md="1" sm="2">
          <MozartSelectField
            data={responderOptions}
            onChange={(data) => { this.handleResponder(data); }}
            value={responderOptions && responderOptions[1] ? responderOptions[1].value : null}
            defaultValue={this.state.currentRole ? this.state.currentRole : null}
            width="140px"
          />
        </Col>
        <Col md="2" sm="2">
          <MozartTextBox
            key="user_search"
            id="user_search"
            className="user_search"
            name="user_search"
            hintText="Search User"
            value={this.state.currentUser}
            onChange={(data) => { this.handleUserSearchState(data); }}
            icon={<span className="icon-Search" onClick={(dataItem) => this.handleUserSearch(dataItem)} />}
            validationBool={false}
            type="text"
          />
        </Col>
        <Col md="7" sm="12" className="date-filter" style={{ display: 'flex', alignItems: 'baseline' }}>
          <div className="date-filter-label" style={{ width: '12%', textAlign: 'justify' }}>Select Date Range</div>
          <MozartDatePicker
            ref={this.datePick}
            id="taskDateRange"
            key="taskDateRange"
            timezone="UTC"
            className="taskDateRange"
            noprefix={false}
            prevFilterDataStart={this.state.currentFromDate ? moment(this.state.currentFromDate) : null}
            prevFilterDataEnd={this.state.currentToDate ? moment(this.state.currentToDate) : null}
            horizontal
            inline
            specialcase={this.state.currentSpecialCase}
            handleChange={(dateData) => this.handleDatePicker(dateData)}
          />
        </Col>
        <Col md="2" sm="4" className="priority-filter" style={{ display: 'flex', alignItems: 'baseline' }}>
          <div className="date-filter-label" style={{ textAlign: 'justify', paddingRight: '5px' }}>Select Priority</div>
          <MozartSelectField
            data={priorityOptions}
            onChange={(data) => { this.handlePriority(data); }}
            width="120px"
            value={this.props.currentPriorityLevel && this.props.currentPriorityLevel > 1 ? defaultPriority : 'NORMAL'}
            defaultValue={this.state.currentPriorityLevel ? this.state.currentPriorityLevel : 'NORMAL'}
          />
          {<MozartIcon
            data={<span className="icon-Xls" />}
            style={{
              margin: '0px 10px',
              borderRadius: '0px',
              width: '39px',
              height: '39px',
              border: '1px solid #715AFF',
              paddingTop: '6px'
            }}
            onClick={(dateData) => this.generateFilter(dateData, 'reportExportAsExcel')}
          />}
        </Col>
      </Row>
    );
  }

  getFilterResponsiveBlock() {
    const responderOptions = [];
    if (this.props.meRolesReducer && this.props.meRolesReducer.data) {
      this.props.meRolesReducer.data.map((item) => {
        if (item.roleName !== 'Manager') {
          responderOptions.push({ name: item.roleName, value: item.roleId });
        }
        return true;
      });
    }
    let defaultPriority = 3;
    if (this.props.currentPriorityLevel === 1) {
      defaultPriority = 'HIGH';
    }
    if (this.props.currentPriorityLevel === 2) {
      defaultPriority = 'MEDIUM';
    }
    if (this.props.currentPriorityLevel === 3) {
      defaultPriority = 'NORMAL';
    }
    const rowPriorityTableData = Object.values(this.props.priorityTableReducer.data);
    const priorityOptions = [{ name: 'High', value: 'HIGH' }, { name: 'Medium', value: 'MEDIUM' }, { name: 'Normal', value: 'NORMAL' }];
    return (
      <div style={{
        paddingBottom: '10px',
        margin: '0px',
        display: 'flex',
        justifyContent: 'center'
      }}
      >
        <div style={{ width: '10%', fontSize: '14px' }}>
          <MozartSelectField
            data={responderOptions}
            onChange={(data) => { this.handleResponder(data); }}
            value={responderOptions && responderOptions[1] ? responderOptions[1].value : null}
            defaultValue={this.state.currentRole ? this.state.currentRole : null}
            width="120px"
          />
        </div>
        <div style={{ width: '14%', fontSize: '14px' }}>
          <MozartTextBox
            key="user_search"
            id="user_search"
            className="user_search"
            name="user_search"
            hintText="Search User"
            value={this.state.currentUser}
            onChange={(data) => { this.handleUserSearchState(data); }}
            onKeyPress={(event, data) => {
              if (event.key === 'Enter') {
                this.handleUserSearch(data);
              }
            }}
            icon={<span className="icon-Search" onClick={(dataItem) => this.handleUserSearch(dataItem)} />}
            validationBool={false}
            type="text"
          />
        </div>
        <div className="date-filter" style={{ display: 'flex', alignItems: 'baseline' }}>
          <div
            className="date-filter-label"
            style={{
              width: 'auto',
              textAlign: 'justify',
              paddingRight: '0px',
              paddingLeft: '4px'
            }}
          >
            Date Range
          </div>
          {/* {this.getFIlterDate()} */}
          <MozartDatePicker
            ref={this.datePick}
            id="taskDateRange"
            key="taskDateRange"
            timezone="UTC"
            className="taskDateRange"
            noprefix={false}
            prevFilterDataStart={this.state.currentFromDate ? moment(this.state.currentFromDate) : null}
            prevFilterDataEnd={this.state.currentToDate ? moment(this.state.currentToDate) : null}
            horizontal
            inline
            specialcase={this.state.currentSpecialCase}
            handleChange={(dateData) => this.handleDatePicker(dateData)}
          />
        </div>
        <div className="priority-filter" style={{ display: 'flex', alignItems: 'baseline' }}>
          {this.props.currentChartLevel < 2 &&
                      [<div className="date-filter-label" style={{ textAlign: 'justify', paddingRight: '5px', width: 'auto' }}>Priority</div>]}
          {this.props.currentChartLevel < 2 && [<MozartSelectField
            data={priorityOptions}
            onChange={(data) => { this.handlePriority(data); }}
            width="120px"
            value={this.props.currentPriorityLevel && this.props.currentPriorityLevel > 1 ? defaultPriority : 'NORMAL'}
            defaultValue={this.state.currentPriorityLevel ? this.state.currentPriorityLevel : 'NORMAL'}
          />]}
          {rowPriorityTableData && rowPriorityTableData.length > 0 &&
            [<MozartIcon
              data={<span className="icon-Xls" />}
              style={{
                margin: '0px 10px',
                borderRadius: '0px',
                width: '39px',
                height: '39px',
                border: '1px solid #715AFF',
                paddingTop: '3px'
              }}
              onClick={(dateData) => this.generateFilter(dateData, 'reportExportAsExcel')}
            />]}
        </div>
      </div>
    );
  }

  getFIlterDate() {
    return (
      <MozartPopover
        icon={
          (
            <div className="custom-date-filter-btn">
              <span>02/02/2020</span>
              <span><MozartIcon data={<span className="icon-RightAlternative2" />} /></span>
              <span>02/02/2020</span>
            </div>
          )
        }
        style={{ width: 'auto', padding: '0 10px', minHeight: '250px' }}
        closeOnClick={false}
        node={
          (
            <div xs="7" md="9" className="taskDateRangeDiv" style={{ paddingLeft: '0px', fontSize: '15px' }}>
              <MozartDatePicker
                ref={this.datePick}
                id="taskDateRange"
                key="taskDateRange"
                timezone="UTC"
                className="taskDateRange"
                noprefix={false}
                prevFilterDataStart={this.state.currentFromDate ? moment(this.state.currentFromDate) : null}
                prevFilterDataEnd={this.state.currentToDate ? moment(this.state.currentToDate) : null}
                horizontal
                specialcase={this.state.currentSpecialCase}
                handleChange={(dateData) => this.handleDatePicker(dateData)}
              />
            </div>
          )
        }
      />
    );
  }

  getDatePickerModal() {
    return (
      <MozartModal
        show={this.state.datepicker_open}
        onClose={() => { this.setState({ datepicker_open: false }); }}
        title=""
        // children={this.state.notification_msg}
        disableSubmit
      >
        hfghdsgfdsf dsfjhsdjfh
      </MozartModal>
    );
  }

  getFilterBlockOld() {
    const responderOptions = [];
    if (this.props.meRolesReducer && this.props.meRolesReducer.data) {
      this.props.meRolesReducer.data.map((item) => {
        if (item.roleName !== 'Manager') {
          responderOptions.push({ name: item.roleName, value: item.roleId });
        }
        return true;
      });
    }
    let defaultPriority = 3;
    if (this.props.currentPriorityLevel === 1) {
      defaultPriority = 'HIGH';
    }
    if (this.props.currentPriorityLevel === 2) {
      defaultPriority = 'MEDIUM';
    }
    if (this.props.currentPriorityLevel === 3) {
      defaultPriority = 'NORMAL';
    }
    const rowPriorityTableData = Object.values(this.props.priorityTableReducer.data);
    const priorityOptions = [{ name: 'High', value: 'HIGH' }, { name: 'Medium', value: 'MEDIUM' }, { name: 'Normal', value: 'NORMAL' }];
    return (
      <Row className="priority-table-header-block">
        <div xs="4" md="2" className="responder-filter">
          <MozartSelectField
            data={responderOptions}
            onChange={(data) => { this.handleResponder(data); }}
            value={responderOptions && responderOptions[1] ? responderOptions[1].value : null}
            defaultValue={this.state.currentRole ? this.state.currentRole : null}
            width="140px"
          />
        </div>
        <Col xs="4" md="2" className="user-search-filter">
          <MozartTextBox
            key="user_search"
            id="user_search"
            className="user_search"
            name="user_search"
            hintText="Search User"
            value={this.state.currentUser}
            onChange={(data) => { this.handleUserSearchState(data); }}
            onKeyPress={(event, data) => {
              if (event.key === 'Enter') {
                this.handleUserSearchState(data);
              }
            }}
            icon={<span className="icon-Search" onClick={(dataItem) => this.handleUserSearch(dataItem)} />}
            validationBool={false}
            type="text"
          />
        </Col>
        <Col xs="7" md="8" className="date-filter" style={{ display: 'flex' }}>
          <div className="date-filter-label">
            Select Date Range
          </div>
          <Col xs="7" md="9" className="taskDateRangeDiv" style={{ paddingLeft: '0px', fontSize: '15px' }}>
            <MozartDatePicker
              ref={this.datePick}
              id="taskDateRange"
              key={this.datePick}
              timezone="UTC"
              className="taskDateRange"
              noprefix={false}
              prevFilterDataStart={this.state.currentFromDate ? moment(this.state.currentFromDate) : null}
              prevFilterDataEnd={this.state.currentToDate ? moment(this.state.currentToDate) : null}
              horizontal
              specialcase={this.state.currentSpecialCase}
              handleChange={(dateData) => this.handleDatePicker(dateData)}
            />
          </Col>
          <Col xs="4" md="4" style={{ paddingLeft: '0px', textAlign: 'left', display: 'flex' }}>
            {this.props.currentChartLevel < 1 &&
              [
                <div className="date-filter-label">
                  Select Priority
                </div>]}
            {this.props.currentChartLevel < 1 &&
                [<MozartSelectField
                  data={priorityOptions}
                  onChange={(data) => { this.handlePriority(data); }}
                  width="140px"
                  value={this.props.currentPriorityLevel && this.props.currentPriorityLevel > 1 ? defaultPriority : 'NORMAL'}
                  defaultValue={this.state.currentPriorityLevel ? this.state.currentPriorityLevel : 'NORMAL'}
                />]}
            {rowPriorityTableData && rowPriorityTableData.length > 5 &&
              [<MozartIcon
                data={<span className="icon-Xls" />}
                style={{
                  margin: '0px 10px',
                  borderRadius: '0px',
                  width: '39px',
                  height: '39px',
                  border: '1px solid #715AFF',
                  paddingTop: '8px'
                }}
                onClick={(dateData) => this.generateFilter(dateData, 'reportExportAsExcel')}
              />]}
          </Col>
        </Col>
      </Row>
    );
  }

  getSelectedDate(name, value) {
    if (name === 'start_date') {
      this.setState({
        currentFromDate: value
      });
    }
    if (name === 'end_date') {
      this.setState({
        currentToDate: value
      });
    }
  }

  getRowData() {
    let rowData = Object.values(this.props.priorityTableReducer.data);
    if (this.props.priorityTableReducer.dataLoadMore && Object.values(this.props.priorityTableReducer.dataLoadMore).length > 0) {
      const rowDataMore = { ...this.props.priorityTableReducer.data, ...this.props.priorityTableReducer.dataLoadMore };
      rowData = Object.values(rowDataMore);
    }
    const dataArray = [];
    if (rowData.length > 0) {
      rowData.forEach((item) => {
        const documentCount = item.queueSize ? item.queueSize : '--';
        const dateConversionCST = convertUTCToCSTTime(item.queueLastAccessedTime, 'DD-MMM-YYYY HH:mm:ss').replace(/--/g, '-');
        const pendingApprovalCount = item.roleName !== 'Approver' ? item.numberOfTasksPendingApprovalCount : '--';
        dataArray.push([item.userId, item.roleName, item.numberOfTasksClosedCount, pendingApprovalCount, item.numberOfTaskSendForSupportCount, item.numberOfTaskFailedCount, documentCount, dateConversionCST]);
      });
    }
    return dataArray;
  }

  getPriorityNumber(priority) {
    let defaultPriority = 3;
    if (priority === 'HIGH') {
      defaultPriority = 1;
    }
    if (priority === 'MEDIUM') {
      defaultPriority = 2;
    }
    if (priority === 'NORMAL') {
      defaultPriority = 3;
    }
    return defaultPriority;
  }

  getPriorityTableData() {
    return (
      <div className="user-data-table" key={Math.random()}>
        <MozartTable
          headers={[
            'USER',
            'ROLE',
            'COMPLETED',
            'Pending Approval',
            'Support Request',
            'Failed',
            'Total Reviews',
            'Queue Last Accessed'
          ]}
          rows={this.getRowData()}
          key={this.state.currentPriorityLevel + this.state.currentRole}
        />
      </div>
    );
  }

  generateFilter(data, exportType) {
    console.log(data, exportType);
    const filters = {};
    if (this.state.currentRole) {
      filters.roleIds = [this.state.currentRole];
    }
    if (this.state.currentPriorityLevel) {
      filters.priorityId = this.getPriorityNumber(this.state.currentPriorityLevel);
    }
    if (this.state.curreentClient) {
      filters.meClientId = this.state.curreentClient;
    }
    const sourceId = this.props.currentSource ? this.props.currentSource : this.state.currentSource;
    if (sourceId && sourceId !== 100000) {
      filters.sourceID = sourceId;
    }
    if (this.state.currentUser) {
      filters.userName = this.state.currentUser;
    }
    filters.offset = 0;
    filters.limit = 7000;
    filters.start_date = moment(this.state.currentFromDate).format('MM/DD/YYYY');
    filters.end_date = moment(this.state.currentToDate).format('MM/DD/YYYY');
    const filterConfig = filters;
    let email = '';
    if (this.props.authReducer.email) {
      email = this.props.authReducer.email;
    }
    const postData = {
      filterConfig: filterConfig,
      emailId: email,
      clientId: 'admin',
      reportId: 'managed_engagements_user_report',
      scheduleSendNow: false,
    };
    this.props.exportReport('admin', exportType, postData).then((resObject) => resObject.payload.data).then((response) => {
      this.setState({
        notification_open: true,
        notification_msg: (response.taskId && response.taskId > 0) ?
          'An email to ' + email + ' will be sent soon with the exported file' :
          'Sorry, we are having technical difficulties. Please try your request again later.'
      });
    }).catch(() => {
      this.setState({
        notification_open: true,
        notification_msg: 'Sorry, we are having technical difficulties. Please try your request again later.'
      });
    });
  }

  handleScrollForPagination() {
    const dHeight = document.documentElement;
    const offset = dHeight.scrollTop + window.innerHeight;
    const height = dHeight.offsetHeight;
    if (offset >= height && this.props.priorityTableReducer.pagination && Object.values(this.props.priorityTableReducer.data).length > 0 && Object.values(this.props.priorityTableReducer.data).length >= 20) {
      const pageCount = this.state.pageLoadMore + 1;
      this.setState(previousState => ({
        ...previousState,
        pageLoadMore: pageCount
      }), () => {
        this.handledPriorityData('more');
      });
    }
  }

  handleScrollForDatePicker() {
    window.scrollTo(0, document.body.scrollHeight - 100);
  }

  handledPriorityData(type) {
    const postData = {
      roleIds: [this.state.currentRole],
      fromDate: this.state.currentFromDate ? this.state.currentFromDate : this.startDate,
      toDate: this.state.currentToDate ? this.state.currentToDate : this.endDate,
      // offset: Object.values(this.props.priorityTableReducer.data).length >= 20 ? this.state.offset + Object.values(this.props.priorityTableReducer.data).length : Object.values(this.props.priorityTableReducer.data).length,
      offset: Object.values(this.props.priorityTableReducer.data).length >= 20 ? this.state.pageLoadMore * 20 : Object.values(this.props.priorityTableReducer.data).length,
      // offset: this.props.priorityTableReducer.dataLoadMore ? Object.values(this.props.priorityTableReducer.dataLoadMore).length : Object.values(this.props.priorityTableReducer.data).length,
      limit: this.state.limit,
      userName: this.state.currentUser
    };
    if (this.props.currentPriorityLevel === 0) {
      postData.priorityId = 1;
    } else if (this.props.currentPriorityLevel > 0) {
      postData.priorityId = this.props.currentPriorityLevel;
    }
    if (this.props.currentChartLevel > 1) {
      postData.clientId = this.props.currentClient;
    }
    if (this.props.currentChartLevel > 2) {
      postData.sourceId = this.props.currentSource;
    }
    if (this.props.currentClient && this.props.currentChartLevel > 1) {
      postData.clientId = this.props.currentClient;
    }
    if (this.props.currentChartLevel > 2 && this.props.currentSource) {
      postData.sourceId = this.props.currentSource;
    }
    if (type === 'more' && postData.offset >= Object.values(this.props.priorityTableReducer.data).length) {
      this.props.loadMorePriorityTable(postData);
    }
  }

  handleResponder(data) {
    this.setState({
      currentRole: data
    }, () => {
      const postData = {
        roleIds: [data],
        fromDate: this.state.currentFromDate ? this.state.currentFromDate : this.startDate,
        toDate: this.state.currentToDate ? this.state.currentToDate : this.endDate,
        offset: 0,
        limit: this.state.limit,
        userName: this.state.currentUser
      };
      if (this.getPriorityNumber(this.state.currentPriorityLevel) > 0) {
        postData.priorityId = this.getPriorityNumber(this.state.currentPriorityLevel);
      }
      if (this.props.currentClient && this.props.currentChartLevel > 1) {
        postData.clientId = this.props.currentClient;
      }
      if (this.props.currentChartLevel > 2 && this.props.currentSource) {
        postData.sourceId = this.props.currentSource;
      }
      // this.props.handlePriorityTableFromChild(postData);
      this.props.loadPriorityTable(postData);
    });
  }

  handleDatePicker(data) {
    let startDate = data.start_date;
    if (data.specialcase === 1) {
      startDate = data.end_date;
    }
    this.setState({
      currentSpecialCase: data.specialcase ? data.specialcase : null,
      currentFromDate: data.start_date ? new Date(startDate).getFullYear() + '' + (new Date(startDate).getMonth() + 1).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }) + '' + (new Date(startDate).getDate().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })) : '',
      currentToDate: data.end_date ? new Date(data.end_date).getFullYear() + '' + (new Date(data.end_date).getMonth() + 1).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }) + '' + (new Date(data.end_date).getDate().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })) : '',
    }, () => {
      const postData = {
        roleIds: [this.state.currentRole],
        fromDate: this.state.currentFromDate ? this.state.currentFromDate : this.startDate,
        toDate: this.state.currentToDate ? this.state.currentToDate : this.endDate,
        offset: 0,
        limit: this.state.limit,
        userName: this.state.currentUser
      };
      if (this.getPriorityNumber(this.state.currentPriorityLevel) > 0) {
        postData.priorityId = this.getPriorityNumber(this.state.currentPriorityLevel);
      }
      if (this.state.currentChartLevel > 1) {
        postData.clientId = this.state.currentClient;
      }
      if (this.state.currentChartLevel > 2) {
        postData.sourceId = this.state.currentSource;
      }
      if (this.props.currentClient && this.props.currentChartLevel > 1) {
        postData.clientId = this.props.currentClient;
      }
      if (this.props.currentChartLevel > 2 && this.props.currentSource) {
        postData.sourceId = this.props.currentSource;
      }
      // this.props.handlePriorityTableFromChild(postData);
      this.props.loadPriorityTable(postData);
    });
  }

  handlePriority(data) {
    this.setState({
      currentPriorityLevel: data,
    }, () => {
      const postData = {
        roleIds: [this.state.currentRole],
        fromDate: this.state.currentFromDate ? this.state.currentFromDate : this.startDate,
        toDate: this.state.currentToDate ? this.state.currentToDate : this.endDate,
        offset: 0,
        limit: this.state.limit,
        userName: this.state.currentUser
      };
      if (this.getPriorityNumber(this.state.currentPriorityLevel) > 0) {
        postData.priorityId = this.getPriorityNumber(this.state.currentPriorityLevel);
      }
      if (this.state.currentChartLevel > 1) {
        postData.clientId = this.state.currentClient;
      }
      if (this.state.currentChartLevel > 2) {
        postData.sourceId = this.state.currentSource;
      }
      if (this.props.currentClient && this.props.currentChartLevel > 1) {
        postData.clientId = this.props.currentClient;
      }
      if (this.props.currentChartLevel > 2 && this.props.currentSource) {
        postData.sourceId = this.props.currentSource;
      }
      // this.props.handlePriorityTableFromChild(postData);
      this.props.loadPriorityTable(postData);
      // this.props.loadMorePriorityTable(postData);
    });
  }

  handleUserSearchState(data) {
    this.state.currentUser = data;
  }

  handleUserSearch() {
    const userName = this.state.currentUser;
    this.setState(previousState => ({
      ...previousState,
      currentUser: userName,
    }), () => {
      const postData = {
        roleIds: [this.state.currentRole],
        fromDate: this.state.currentFromDate ? this.state.currentFromDate : this.startDate,
        toDate: this.state.currentToDate ? this.state.currentToDate : this.endDate,
        offset: 0,
        limit: this.state.limit,
        userName: this.state.currentUser
      };
      if (this.getPriorityNumber(this.state.currentPriorityLevel) > 0) {
        postData.priorityId = this.getPriorityNumber(this.state.currentPriorityLevel);
      }
      if (this.state.currentChartLevel > 1) {
        postData.clientId = this.state.currentClient;
      }
      if (this.state.currentChartLevel > 2) {
        postData.sourceId = this.state.currentSource;
      }
      if (this.props.currentClient && this.props.currentChartLevel > 1) {
        postData.clientId = this.props.currentClient;
      }
      if (this.props.currentChartLevel > 2 && this.props.currentSource) {
        postData.sourceId = this.props.currentSource;
      }
      this.props.loadPriorityTable(postData);
      // this.props.handlePriorityTableFromChild(postData);
    });
    // this.props.loadMorePriorityTable(postData);
  }

  render() {
    return (
      <div style={{ width: '100%' }} key={Math.random()}>
        {this.getFilterResponsiveBlock()}
        {/* {this.getFilterBlock()} */}
        {this.getPriorityTableData()}
        <MozartModal
          show={this.state.notification_open}
          onClose={() => { this.setState({ notification_open: false, notification_msg: '' }); }}
          title="Export Notification"
          // children={this.state.notification_msg}
          disableSubmit
        >
          {this.state.notification_msg}
        </MozartModal>
        {this.getDatePickerModal()}
      </div>
    );
  }
}

PriorityTable.propTypes = {
  priorityTableReducer: PropTypes.object,
  meRolesReducer: PropTypes.object,
  authReducer: PropTypes.object,
  loadPriorityTable: PropTypes.func,
  loadMorePriorityTable: PropTypes.func,
  exportReport: PropTypes.func,
  // handlePriorityTableFromChild: PropTypes.func,
  currentPriorityLevel: PropTypes.number,
  currentClient: PropTypes.string,
  currentChartLevel: PropTypes.number,
  currentSource: PropTypes.number
//   currentSource: PropTypes.number
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({ loadPriorityTable, loadMorePriorityTable, exportReport }, dispatch)
);

const mapStateToProps = state => ({
  priorityTableReducer: state.priorityTableReducer,
  meRolesReducer: state.meRolesReducer,
  authReducer: state.authReducer,
});
export default connect(mapStateToProps, mapDispatchToProps)(PriorityTable);
