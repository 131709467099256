import React, { Component } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { Authenticated } from 'containers';
import './App.scss';
import { connect } from 'react-redux';
import { MozartImage } from '@pgforsta/mozart_ui';
import { bindActionCreators } from 'redux';
import { logout } from 'utils/util-element';

function Error() {
  return (
    <div fixedTop={false}>
      <div style={{ marginLeft: 'auto', marginRight: 'auto', width: '600px' }}>
        <MozartImage style={{ width: '600px', height: '300px' }} src="/broken_robot.png" />
        <p style={{ fontSize: '30px', fontWeight: 300, textAlign: 'center' }}>Sorry, we are having technical difficulties.</p>
        <p style={{ fontSize: '20px', fontWeight: 300, textAlign: 'center' }}>
          Please try your request again or click
          <a href="/"> here </a>
          to go to the homepage.
        </p>
      </div>
    </div>
  );
}

class App extends Component {
  getChildren() {
    return (
      <Router>
        <Switch>
          <Route path="/error" component={Error} />
          <Route path="/logout" component={logout} />
          <Route exact render={() => <Authenticated />} />
        </Switch>
      </Router>
    );
  }

  subComponentProps(props) {
    return {
      props
    };
  }

  render() {
    const responsiveStyle = (!this.isMobile || !this.isResponsive) ? { minWidth: '1250px' } : { };
    return (
      <div className="appContent" style={responsiveStyle}>
        <div className="binary_container">
          {this.getChildren()}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => (
  bindActionCreators({}, dispatch)
);

const mapStateToProps = state => ({
  authReducer: state.authReducer
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
