import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Header, QueueAssignmentCard } from 'components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadBreadCrums } from 'redux/actions/breadcrumAction';
import { load as loadAuth, loadPermission } from 'redux/actions/authAction';
import './QueueAssignmentView.scss';
import { meUserRole } from 'utils/util-element';

class QueueAssignmentView extends Component {
  constructor(props) {
    super(props);
    meUserRole(this.props.permissions, 'MANAGER');
  }

  componentDidMount() {
    if (!this.props.authReducer.user) {
      this.getUserInfo();
    }
    this.props.loadBreadCrums(this.getBreadCrums());
  }

  getUserInfo() {
    this.props.loadAuth().then(() => {
      if (this.props.authReducer.email) {
        this.props.loadPermission(this.props.authReducer.email);
      }
    });
  }

  getBreadCrums() {
    return [
      { id: 1, name: 'Queue Assignment', href: 'queueAssignmentView' }
    ];
  }

  render() {
    return [<Header />,
      <Container fluid className="queue-assignment">
        <QueueAssignmentCard
          userInfo={this.props}
          // userRole={this.props.authReducer.permissions}
        />
      </Container>
    ];
  }
}

QueueAssignmentView.propTypes = {
  permissions: PropTypes.object,
  loadBreadCrums: PropTypes.func,
  loadAuth: PropTypes.func,
  authReducer: PropTypes.object,
  loadPermission: PropTypes.object,
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    loadBreadCrums, loadAuth, loadPermission
  }, dispatch)
);

const mapStateToProps = state => ({
  permissions: state.authReducer.permissions,
  authReducer: state.authReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(QueueAssignmentView);
