import {
  LOAD,
  LOAD_STATUS_SUCCESS,
  LOAD_STATISTICS_SUCCESS,
  REPUSH_HISTORY_SUCCESS,
  DELETE_HISTORY_SUCCESS,
  LOAD_FAIL
} from 'redux/actions/reportMonitorAction';

const initialState = {
  loaded: false,
  loading: false,
  data: [],
  statisticsdata: [],
  repushHistory: null,
  deleteHistory: null
};

function reportMonitorReducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case LOAD_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload.data
      };
    case LOAD_STATISTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        statisticsdata: action.payload.data
      };
    case REPUSH_HISTORY_SUCCESS:
      let updateData = [];
      if (action.payload.data && action.payload.data.length > 0 && state.data && state.data.length > 0) {
        state.data.forEach(row => {
          if (!action.payload.data.includes(row.historyId)) {
            updateData.push(row);
          }
        });
      } else {
        updateData = state.data;
      }
      return {
        ...state,
        loading: false,
        loaded: true,
        data: updateData,
        repushHistory: action.payload.data
      };
    case DELETE_HISTORY_SUCCESS:
      let updateDataHistory = [];
      if (action.payload.data && action.payload.data.length > 0 && state.data && state.data.length > 0) {
        state.data.forEach(row => {
          if (!action.payload.data.includes(row.historyId)) {
            updateDataHistory.push(row);
          }
        });
      } else {
        updateDataHistory = state.data;
      }
      return {
        ...state,
        loading: false,
        loaded: true,
        data: updateDataHistory,
        deleteHistory: action.payload.data
      };
    case LOAD_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
}

export default reportMonitorReducer;
