export const REASSIGN_TASK = 'binary/reAssign/REASSIGN_TASK';
export const REASSIGN_TASK_SUCCESS = 'binary/reAssign/REASSIGN_TASK_SUCCESS';
export const REASSIGN_TASK_FAIL = 'binary/reAssign/REASSIGN_TASK_FAIL';

export function reAssignTaskToUser(taskId, postData) {
  return {
    types: [REASSIGN_TASK, REASSIGN_TASK_SUCCESS, REASSIGN_TASK_FAIL],
    payload: {
      client: 'managementengagement',
      request: {
        method: 'post',
        data: postData,
        url: '/api/tasks/' + taskId + '/reassign'
      }
    }
  };
}
