import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Col, Row } from 'react-bootstrap';
import {
  MozartRadio,
  MozartTable,
  MozartButton,
  MozartLoading,
  MozartModal
} from '@pgforsta/mozart_ui';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadConfigClient, saveConfig } from 'redux/actions/queueConfigAction';
import './QueueConfigureCard.scss';

class QueueConfigureCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      queueConfig: [],
      modal: false,
      modal_description: '',
      load: false
    };
  }

  componentDidMount() {
    this.props.loadConfigClient().then(() => {
      if (this.props.config) {
        this.setState({
          queueConfig: this.props.config ? this.props.config : [],
        });
      }
    });
  }

  onInputChange(e) {
    const clientClicked = e.target.name;
    const valueClicked = e.target.value;
    const { queueConfig } = this.state;
    const nexState = queueConfig.map(card => {
      if (card.clientId === clientClicked) {
        const checked = card.queueId === valueClicked ? card.queueId : valueClicked;
        return {
          ...card,
          clientId: card.clientId,
          clientName: card.clientName,
          queueId: parseInt(checked, 10),
          approvalRequired: card.approvalRequired,
          ratingPriority: card.ratingPriority
        };
      }
      return card;
    });
    this.setState({ queueConfig: nexState });
  }

  onApproverChange(e) {
    const clientClicked = e.target.name;
    const valueClicked = e.target.value;
    const { queueConfig } = this.state;
    const nexState = queueConfig.map(card => {
      if (`approve_name_${card.clientId}` === clientClicked) {
        const checked = JSON.parse(valueClicked);
        return {
          ...card,
          clientId: card.clientId,
          clientName: card.clientName,
          queueId: card.queueId,
          approvalRequired: checked,
          ratingPriority: card.ratingPriority
        };
      }
      return card;
    });
    this.setState({ queueConfig: nexState });
  }

  onRatingOrderChangeAsc(e) {
    const clientClicked = e.target.name;
    const valueClicked = e.target.value;
    const { queueConfig } = this.state;
    const nexState = queueConfig.map(card => {
      if (`rating_name_${card.clientId}` === clientClicked) {
        const checked = JSON.parse(valueClicked);
        return {
          ...card,
          clientId: card.clientId,
          clientName: card.clientName,
          queueId: card.queueId,
          approvalRequired: card.approvalRequired,
          ratingPriority: checked
        };
      }
      return card;
    });
    console.log('queueConfig');
    console.log(queueConfig);
    this.setState({ queueConfig: nexState });
  }

  onSubmit() {
    const updateConfig = this.state.queueConfig;
    this.props.saveConfig(updateConfig).then(() => {
      const msg = 'Queue configuration for clients has been saved';
      this.setState({ load: true }, () => {
        setTimeout(() => {
          this.setState({ load: false }, () => {
            setTimeout(() => {
              this.setState({ modal: true, modal_description: msg });
            }, 500);
          });
        }, 3000);
      });
    });
  }

  getTitleData() {
    const DATA = [];
    DATA.push(
      'CLIENTS',
      'HIGH',
      'Medium',
      'NORMAL',
      'APPROVAL REQUIRED',
      'RATING ORDER'
    );
    return DATA;
  }

  getRowData() {
    const DATA = [];
    const { queueConfig } = this.state;
    console.log('render');
    console.log(queueConfig);
    queueConfig.map((items) => {
      const QUEUE_RENDER = [];
      const ownerStatus = (
        <Row className="queue-status">
          <Col xs={2} md={2} className="queue-status-client">{items.clientName}</Col>
          <Col xs={2} md={2} className="queue-status-high">
            <MozartRadio>
              <input
                value={1}
                onChange={(e) => this.onInputChange(e)}
                type="radio"
                id={`high_${items.clientId}`}
                name={items.clientId}
                checked={items.queueId === 1 ? 'checked' : ''}
              />
              <label htmlFor={`high_${items.clientId}`} title="radio">&nbsp;</label>
            </MozartRadio>
          </Col>
          <Col xs={2} md={2} className="queue-status-medium">
            <MozartRadio>
              <input
                value={2}
                onChange={(e) => this.onInputChange(e)}
                type="radio"
                id={`medium_${items.clientId}`}
                name={items.clientId}
                checked={items.queueId === 2 ? 'checked' : ''}
              />
              <label htmlFor={`medium_${items.clientId}`} title="radio">&nbsp;</label>
            </MozartRadio>
          </Col>
          <Col xs={2} md={2} className="queue-status-normal">
            <MozartRadio>
              <input
                value={3}
                onChange={(e) => this.onInputChange(e)}
                type="radio"
                id={`normal_${items.clientId}`}
                name={items.clientId}
                checked={items.queueId === 3 ? 'checked' : ''}
              />
              <label htmlFor={`normal_${items.clientId}`} title="radio">&nbsp;</label>
            </MozartRadio>
          </Col>
          <Col xs={2} md={2} className="queue-status-approv">
            <MozartRadio>
              <input
                value="false"
                onChange={(e) => this.onApproverChange(e)}
                type="radio"
                id={`approve_no_${items.clientId}`}
                name={`approve_name_${items.clientId}`}
                checked={items.approvalRequired ? '' : 'checked'}
              />
              <label htmlFor={`approve_no_${items.clientId}`} title="radio">No</label>
            </MozartRadio>
            <MozartRadio>
              <input
                value="true"
                onChange={(e) => this.onApproverChange(e)}
                type="radio"
                id={`approve_yes_${items.clientId}`}
                name={`approve_name_${items.clientId}`}
                checked={items.approvalRequired ? 'checked' : ''}
              />
              <label htmlFor={`approve_yes_${items.clientId}`} title="radio">Yes</label>
            </MozartRadio>
          </Col>
          <Col xs={2} md={2} className="queue-status-last">
            <MozartRadio>
              <input
                value={1}
                onChange={(e) => this.onRatingOrderChangeAsc(e)}
                type="radio"
                id={`rating_asc_${items.clientId}`}
                name={`rating_name_${items.clientId}`}
                checked={items.ratingPriority === 1 ? 'checked' : ''}
              />
              <label htmlFor={`rating_asc_${items.clientId}`} title="radio">Ascending</label>
            </MozartRadio>
            <MozartRadio>
              <input
                value={2}
                onChange={(e) => this.onRatingOrderChangeAsc(e)}
                type="radio"
                id={`rating_desc_${items.clientId}`}
                name={`rating_name_${items.clientId}`}
                checked={items.ratingPriority === 2 ? 'checked' : ''}
              />
              <label htmlFor={`rating_desc_${items.clientId}`} title="radio">Descending</label>
            </MozartRadio>
          </Col>
        </Row>
      );
      QUEUE_RENDER.push(ownerStatus);
      DATA.push(QUEUE_RENDER);
      return DATA;
    });
    return DATA;
  }

  goToPage() {
    window.location.href = '/#/me/manager';
  }

  closeModal() {
    this.setState({ modal: false, modal_description: '' });
    window.location.href = '/#/me/manager';
  }

  render() {
    const dataSave =
      (
        <div style={{ color: '#FFFFFF', fontSize: '15px', paddingTop: '0px' }}>
          <span style={{ color: '#FFFFFF', fontSize: '14px', paddingLeft: '4px' }}>Save</span>
          <span
            className="icon-Save"
            style={{
              color: '#FFFFFF',
              fontSize: '20px',
              paddingLeft: '34px',
              position: 'relative',
              verticalAlign: 'middle'
            }}
          />
        </div>
      );
    return [
      <Container fluid className="queue-config-card">
        <Row>
          <Col xs={6} md={6} className="lable-header">Queue Configuration</Col>
          <Col xs={6} md={6} className="lable-header-button">
            <div className="lable-header-cancel"><span className="lable-header-cancel1" onClick={(e) => this.goToPage(e)}>Cancel</span></div>
            <div className="lable-header-save">
              <MozartButton
                background
                data={dataSave}
                // disabled={this.state.queueConfig.length > 0 ? 'false' : 'true'}
                onClick={(e) => this.onSubmit(e)}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}><hr /></Col>
        </Row>
        {this.props.loadingConfig ?
          <MozartLoading inline loading={this.props.loadingConfig} />
          :
          this.state.queueConfig &&
          (
            <MozartTable
              headers={this.getTitleData()}
              rows={this.getRowData()}
              style={{ margin: '0', width: '100%' }}
              headStyle={{ fontSize: '0.75em', width: '350px', paddingLeft: '68px' }}
              mainHeaderStyle={
                {
                  display: 'inline-block', textAlign: 'center', borderRadius: '55px'
                }
              }
              bodyStyle={{ fontSize: '14px', display: 'block' }}
              rowStyle={{ height: '4em' }}
              // sticky={this.state.queueConfig && this.state.queueConfig.length > 0}
              scrollUpPos="0"
              colsStyle={[{ minWidth: '6em', width: '57%' }, { minWidth: '10em' }, { minWidth: '2em' }, { minWidth: '16em' }, { minWidth: '33em', width: '100%' }]}
            />
          )}
        {this.state.load && (
          <MozartLoading inlinefull loading />
        )}
        <MozartModal
          title="Queue Configuration"
          key="queue_notification"
          show={this.state.modal}
          onClose={() => this.closeModal()}
          handleSubmit={() => this.closeModal()}
          submitLabel="OK"
        >
          {this.state.modal_description}
        </MozartModal>
      </Container>
    ];
  }
}

QueueConfigureCard.propTypes = {
  loadConfigClient: PropTypes.func,
  saveConfig: PropTypes.func,
  config: PropTypes.object,
  loadingConfig: PropTypes.bool
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    loadConfigClient, saveConfig
  }, dispatch)
);

const mapStateToProps = state => ({
  loadingConfig: state.configClientReducer.loadingConfig,
  saveConfig: state.configSaveReducer,
  config: state.configClientReducer.config,
});

export default connect(mapStateToProps, mapDispatchToProps)(QueueConfigureCard);
