import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { setBreadCrumLevel } from 'redux/actions/breadcrumAction';
import { MozartButton, MozartBreadCrum } from '@pgforsta/mozart_ui';
import './MenuHeader.scss';

class MenuHeader extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    console.log(this.props.currentLevel);
  }

  onBreadcrumpClick(data) {
    console.log(data);
    this.props.setBreadCrumLevel(data);
    if (this.props.breadcrumsDashboard && this.props.breadcrumsDashboard.length > 0) {
      this.props.setNextLevelChart(this.props.breadcrumsDashboard);
    }
  }

  getHeaderItems() {
    const { breadcrumsDashboard } = this.props;
    let title = 'Queue Status Dashboard';
    if (breadcrumsDashboard && breadcrumsDashboard.length) {
      title = breadcrumsDashboard[breadcrumsDashboard.length - 1].name;
    }
    return (
      <Row className="menu-header-block">
        <Col xs="6" md="6" className="menu-header-title-block">
          <div>{title}</div>
          <div className="breadcrumbs" style={{ width: '100%' }}>
            <MozartBreadCrum data={breadcrumsDashboard} />
          </div>
        </Col>
        <Col xs="6" md="6" className="menu-header-button-block">
          <div className="action-btn-queue-config">
            <MozartButton
              background
              data="Queue Configuration"
              onClick={() => this.goToConfigPage()}
            />
          </div>
          <div className="action-btn-queue-assignment">
            <MozartButton
              background
              data="Queue Assignment"
              onClick={() => this.goToPage()}
            />
          </div>
        </Col>
      </Row>
    );
  }

  goToPage() {
    window.location.href = '/#/me/queueAssignment';
  }

  goToConfigPage() {
    window.location.href = '/#/me/queueConfigure';
  }

  render() {
    return (
      <div>
        {this.getHeaderItems()}
      </div>
    );
  }
}

MenuHeader.propTypes = {
  setNextLevelChart: PropTypes.func,
  breadcrumsDashboard: PropTypes.array,
  setBreadCrumLevel: PropTypes.func,
  currentLevel: PropTypes.number
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setBreadCrumLevel
  }, dispatch)
);

const mapStateToProps = state => ({
  breadcrumReducer: state.breadcrumReducer,
  breadcrumsDashboard: state.breadcrumReducer.breadcrumsDashboard,
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuHeader);
