import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  MozartLoading,
  MozartButton,
  MozartTable,
  MozartModal,
  MozartSelectField,
  MozartToolTip,
  MozartPopover,
  MozartRadio,
  MozartCheckBox,
  MozartStarRating
} from '@pgforsta/mozart_ui';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { loadClients } from 'redux/actions/clientAction';
import './SupportRequestPage.scss';
import { meUserRole, triggerFetch } from 'utils/util-element';
import {
  Client, Location, Category, Source
} from 'components/Filters';
import { loadSupportTask, loadSupportTaskPageLoad } from 'redux/actions/sendSupportAction';
import { loadLocations } from 'redux/actions/locationAction';
import { loadSource } from 'redux/actions/sourceAction';
import { loadResponderList } from 'redux/actions/responderListAction';
import { reAssignTaskToUser } from 'redux/actions/reassignAction';

class SupportRequestPage extends Component {
  constructor(props) {
    super(props);
    meUserRole(this.props.permissions, 'MANAGER');
    this.state = {
      filter: {
        clientId: null,
        locationId: null,
        sourceId: null,
        status: null,
        sortOrder: 'desc',
        offset: 0,
        limit: 20
      },
      onChangefilter: {
        clientId: null,
        locationId: null,
        sourceId: null,
        status: null,
        sortOrder: 'desc',
        offset: 0,
        limit: 20
      },
      openAssignmentModal: false,
      assignmentData: '',
      locations: [],
      sources: [],
      assignee: '',
      taskList: [],
      initialTaskListCount: 0,
      currentTaskDetails: [{
        taskId: null,
        assignee: null,
        documentId: null,
        clientId: null,
        key: null
      }],
      pageLoadMore: 0,
      offset: 0,
      notification_msg: '',
      errorMsg: false,
      loader: false,
      triggerRetry: false,
      doneRetry: false,
      triggerAssign: false,
      deleteReason:'',
      deleteReviewFlag:false
    };
    this.handleOnChange = this.handleOnChange.bind(this);
    this.successCallBack = this.successCallBack.bind(this);
    this.errorCallBack = this.errorCallBack.bind(this);
    this.onRequest = this.onRequest.bind(this);
  }

  componentDidMount() {
    document.removeEventListener('scroll', this.handleScrollForPagination.bind(this), false);
    document.addEventListener('scroll', this.handleScrollForPagination.bind(this));
    let tableData = [];
    this.props.loadSupportTask(this.getFilterData()).then(() => {
      tableData = this.props.sendSupportReducer.taskList;
      this.setState({
        taskList: tableData,
        initialTaskListCount: Object.values(tableData).length
      });
    });
  }

  onRequest() {
    this.setState(previousState => ({
      ...previousState,
      loader: true
    }));
  }

  getPageTitle() {
    return (
      <div className="page-title">Support Requests</div>
    );
  }

  getSupportRequestPanel() {
    return (
      <Row className="support-request-panel">
        {this.props.loading && <MozartLoading />}
        <Col xs={12} md={2} sm={12} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
          {this.getPageTitle()}
        </Col>
        <Col xs={12} md={10} sm={12} className="support-request-filters">
          <Client handleOnChange={this.handleOnChange} />
          <Location handleOnChange={this.handleOnChange} locations={this.state.locations} />
          <Source handleOnChange={this.handleOnChange} sources={this.state.sources} />
          <Category handleOnChange={this.handleOnChange} status={this.state.filter.status} />
          <div>
            <MozartButton
              background
              data="Filter"
              style={{ height: '40px', width: '160px', padding: '5px' }}
              onClick={() => { this.applyFilter('filter'); }}
            />
          </div>
        </Col>
      </Row>
    );
  }

  getRowData() {
    const sortedActivities = Object.values(this.state.taskList);
    const dataArray = [];
    if (sortedActivities.length > 0) {
      sortedActivities.forEach((item, key) => {
        const actionBtn = [<MozartButton
          background
          data="Assign"
          style={{ height: '32px', width: '100px' }}
          onClick={() => { this.handleAssignPopup(key, item.taskId, item.documentId, item.clientId); }}
        />];
        const disableBtn = [<MozartButton
          background
          data="Task Assigned"
          style={{ height: '32px', width: '100px' }}
          disabled
        />];
        let statusLabel = item.status;
        if (item.status === 'SEND_TO_SUPPORT') {
          statusLabel = 'SEND TO SUPPORT';
        }
        const clientName = this.getCustomText(item.client, item.reassigned);
        const locationName = this.getCustomText(item.locationName, item.reassigned);
        const sourceName = this.getCustomText(item.sourceName, item.reassigned);
        const respondedBy = this.getCustomText(item.respondedBy, item.reassigned);
        const timeIn = this.getCustomText(item.timeIn, item.reassigned);
        const status = this.getCustomText(statusLabel, item.reassigned);
        dataArray.push([clientName, locationName, sourceName, respondedBy, timeIn, status, this.getCustomText(this.getSubString(item.review), item.reassigned), this.getCustomText(this.getSubString(item.response), item.reassigned), this.getCustomText(this.getSubString(item.comments), item.reassigned), (item.reassigned ? disableBtn : actionBtn)]);
      });
    }
    return dataArray;
  }

  getCustomText(text, reassigned) {
    const reassignedBlock = (
      <span className={reassigned ? 'reAssigned' : ''}>{text}</span>
    );
    return reassignedBlock;
  }

  getSubString(text) {
    let substringText = text;
    if (text && text.length > 44) {
      substringText = text.substring(0, 44) + '...';
    }
    return substringText;
  }

  getStringWithToolTip(text, fieldType) {
    const scoreLabel = (
      <MozartToolTip
        key={Math.random()}
        node={(
          <span>
            <span>{this.getSubString(text)}</span>
          </span>
        )}
        id={fieldType}
        data={<p style={{ minWidth: '150px', width: 'auto' }}>{text}</p>}
        placement="top"
      />
    );
    return scoreLabel;
  }

  getStringWithPopOver(text) {
    const scoreLabel = (
      <MozartPopover
        icon={(
          <span>
            <span>{this.getSubString(text)}</span>
            <a style={{ cursor: 'pointer', color: '#17A589', fontSize: '12px' }} href>view more</a>
          </span>
        )}
        style={{
          minWidth: '600px', padding: '20px 10px', maxHeight: 'auto', zIndex: 100000, position: 'absolute', border: '1px solid #D3CFF8', boxShadow: 'unset'
        }}
        node={(
          <div>
            {text}
          </div>
        )}
      />
    );
    return scoreLabel;
  }

  getSupportRequestTableSort() {
    const sortOrder = [];
    sortOrder.push({ value: 'desc', id: 'desc', text: 'Newest-Oldest' });
    sortOrder.push({ value: 'asc', id: 'asc', text: 'Oldest-Newest' });
    return (
      <Col md="12" className="sort-filter-block">
        <MozartSelectField
          data={sortOrder}
          onChange={(data) => { this.handleSortOrder(data); }}
          width="200px"
          defaultValue={this.state.filter.sortOrder ? this.state.filter.sortOrder : sortOrder[0].id}
        />
      </Col>
    );
  }

  getSupportRequestTable() {
    return (
      <Row className="support-task-table" key={Math.random()}>
        <MozartTable
          headers={[
            'CLIENT',
            'LOCATION / Provider',
            'SOURCE',
            'Responder / Approver',
            'Time In',
            'Category',
            'Review',
            'Response',
            'Comments',
            'Action'
          ]}
          rows={this.getRowData()}
        />
      </Row>
    );
  }

  deleteSuccessCallBack=(res)=>{
    // this.state.loader = true;
    this.setState(previousState => ({
      ...previousState,
      taskList: previousState.taskList,
      currentTaskDetails: previousState.currentTaskDetails,
      notification_msg: "Deleted Successfully",
      errorMsg: false,
      loader: false,
    }));
    this.closeModal();
    let tableData = [];
    this.props.loadSupportTask(this.getFilterData()).then(() => {
      tableData = this.props.sendSupportReducer.taskList;
      this.setState({
        taskList: tableData,
        initialTaskListCount: Object.values(tableData).length
      });
    });
   
  }
  deleteErrorCallBack=()=>{
    // this.state.loader = true;
    this.setState(previousState => ({
      ...previousState,
      errorMsg: true,
      loader: false,
      taskList: previousState.taskList,
      currentTaskDetails: previousState.currentTaskDetails,
      notification_msg: 'Review Deletion Failed',
      triggerAssign: false,
    }));
  }

  handleDeleteResponseReason=(data)=>{
  this.setState({deleteReason:data.value});
  }

  handleDeleteResponseButton=()=>{
    const taskId =this.state.currentTaskDetails.taskId
    const absoluteUrl =  process.env.REACT_APP_ME_HOST+'/api/tasks/'+taskId
    const deleteData = {}
    deleteData.reason=this.state.deleteReason;
    triggerFetch(absoluteUrl, this.onRequest, this.deleteSuccessCallBack, this.deleteErrorCallBack, 'DELETE', JSON.stringify(deleteData));
  }
  
  handleDeleteReview = (data) =>{
    let deleteReviewVal = false;
    if (data && data.length > 0) {
      deleteReviewVal = true;
    }
    this.setState({
      deleteReviewFlag: deleteReviewVal
    });
  }
  

  getAssignmentModalData() {
    const rowData = Object.values(this.state.taskList);
    let styleAssignButton = {
      height: '42px', paddingTop: '8px', display: 'none'
    };
    if (this.state.assignee) {
      styleAssignButton = { height: '42px', paddingTop: '8px' };
    }
    const currentKey = this.state.currentTaskDetails.key;
    const currentTaskId = this.state.currentTaskDetails.taskId;
    const currentDocumentId = this.state.currentTaskDetails.documentId;
    const currentClientId = this.state.currentTaskDetails.clientId;
    const styleButtonDisplay = { ...styleAssignButton };
    let displayRetryAndOrLabel = 'none';
    let selectResponderPaddingLeft = '15px';
    if (rowData[currentKey].status === 'FAILED') {
      Object.assign(styleButtonDisplay, { display: 'block' });
      displayRetryAndOrLabel = 'block';
      selectResponderPaddingLeft = '0px';
    }
    const modalData = (
      <div>
        <Row className="assignment-popup-panel">
          <div className="task-details-items" style={{ width: '13%' }}>
            <div className="task-details-label">CLIENT</div>
            <div className="task-details-value">{rowData[currentKey].client}</div>
          </div>
          <div className="task-details-items">
            <div className="task-details-label">Location</div>
            <div className="task-details-value">{rowData[currentKey].locationName}</div>
          </div>
          <div className="task-details-items">
            <div className="task-details-label">Source</div>
            <div className="task-details-value"><a style={{ color: '#403788' }} href={rowData[currentKey].documentLink} rel="noopener noreferrer" target="_blank">{rowData[currentKey].sourceName}</a></div>
          </div>
          <div className="task-details-items">
            <div className="task-details-label">Task Id</div>
            <div className="task-details-value">{rowData[currentKey].taskId}</div>
          </div>
          <div className="task-details-items">
            <div className="task-details-label">Time In</div>
            <div className="task-details-value">{rowData[currentKey].timeIn}</div>
          </div>
          <div className="task-details-items">
            <div className="task-details-label">Category</div>
            <div className="task-details-value">{rowData[currentKey].status === 'SEND_TO_SUPPORT' ? 'SEND TO SUPPORT' : rowData[currentKey].status }</div>
          </div>
          <div className="task-details-items no-border-required">
            <div className="task-details-label">Responder</div>
            <div className="task-details-value">{rowData[currentKey].respondedBy}</div>
          </div>
        </Row>
        <Row className="review-block">
          <Col xs={8} md={8} style={{marginLeft: '-14px' }}>
            <div className="task-details-items">
              <div className="task-details-label">Reviews</div>
              <div className="task-details-value">{rowData[currentKey].review}</div>
            </div>
          </Col>
          <Col xs={4} md={4}>
            {rowData[currentKey].hasRating &&
              <div className="rating-block">
                <div className="task-details-label-star">Rating</div>
                <div className="task-details-value-star">
                  <MozartStarRating style={{fontSize: '1.3em', textAlign: 'left'}} key="filter_rating" name="filter rating" stars={parseInt(rowData[currentKey].rating, 10)} />
                </div>
              </div>
            }
          </Col>
        </Row>
        <Row className="response-block">
          <div className="task-details-items">
            <div className="task-details-label">Response</div>
            <div className="task-details-value">{rowData[currentKey].response}</div>
          </div>
        </Row>
        <Row className="comment-block">
          <div className="task-details-items">
            <div className="task-details-label">Comment</div>
            <div className="task-details-value">{rowData[currentKey].comments}</div>
          </div>
        </Row>
        <Row className="new-responder" style={{ alignItems: 'center', marginTop: '20px' }}>
          <Col  xs='8' md="8">
            <Row className="new-responder" style={{ alignItems: 'center', marginTop: '20px' }}>
          { (!this.state.triggerRetry || !this.state.doneRetry) &&
          (
            <Col xs="2" md="2" style={{ padding: '0px', marginLeft: '15px', display: displayRetryAndOrLabel }}>
              <MozartButton
                background
                disabled={this.state.triggerAssign}
                data="Retry Now"
                style={styleButtonDisplay}
                onClick={() => { this.handleRetry(currentKey, currentTaskId, currentDocumentId, currentClientId, rowData[currentKey].respondedBy); }}
              />
            </Col>
          )}
          { !this.state.doneRetry &&
          (
            <Col xs="1" md="1" style={{ padding: '0px', display: displayRetryAndOrLabel }}>
              <div className="task-details-label">OR</div>
            </Col>
          )}
          { !this.state.doneRetry &&
          (
            <Col xs="4" md="4" style={{ paddingLeft: selectResponderPaddingLeft, maxWidth: 'fit-content' }}>
              {this.getResponder(this.state.assignee)}
            </Col>
          )}
          { !this.state.doneRetry &&
          (
            <Col xs="2" md="2" style={{ padding: '0px' }}>
              <MozartButton
                background
                data="Assign"
                style={styleAssignButton}
                onClick={() => {
                  this.setState(previousState => ({
                    ...previousState,
                    triggerAssign: true,
                  }), () => {
                    this.handleTaskAssignToNew(currentKey, currentTaskId, currentDocumentId, currentClientId);
                  });
                }}
              />
            </Col>
          )}
          </Row>
          </Col>
          <Col xs="3" md="3" >
            <Row style={{ alignItems: 'center', marginTop: '20px' }}>
              <Col xs="12" md="12" > 
              <MozartCheckBox
                colorStyle="purple"
                onChange={(data) => { this.handleDeleteReview(data); }}
                id="deleteCheckbox"
                content={[
                  { name: 'Delete Review', value: 1, checked: this.state.deleteReviewFlag ? this.state.deleteReviewFlag : false, },
                ]}
                name="shareReview"
                key={Math.random()}
              >
                Delete Review
              </MozartCheckBox>
              {this.state.deleteReviewFlag && 
                <div>
                  <MozartRadio
                    key="deleteResponse"
                    onChange={(data) => {this.handleDeleteResponseReason(data) }}
                    groupname="delete_response_radio"
                    content={[
                      {name:"Externally Closed", value:"EXTERNALLY_CLOSED"},
                      {name:"Send to DRM", value:"SEND_TO_DRM"}
                    ]}
                    />
                    <MozartButton
                      background
                      data="Delete"
                      style={{ height: '40px', width: '100px', padding: '5px',float: 'right',marginBottom:'0.6rem' }}
                      onClick={() => { this.handleDeleteResponseButton(); }}
                    />
                </div>}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="12" style={{ color: this.state.errorMsg ? 'red' : 'darkgreen', paddingTop: '10px' }}>
            {this.state.notification_msg}
          </Col>
        </Row>
      </div>
    );
    return modalData;
  }

  getAssignmentModal() {
    return (
      <MozartModal
        show={this.state.openAssignmentModal}
        onClose={() => { this.closeModal(); }}
        title="Assign New Responder"
        buttonText="Assign"
        disableSubmit
        width="65%"
        contentPadding="25px"
      >
        {this.state.openAssignmentModal && this.getAssignmentModalData()}
      </MozartModal>
    );
  }

  getResponder(customData) {
    const result = Object.values(this.props.responderListReducer.data);
    const responderList = [];
    if (result.length > 0) {
      result.forEach((data) => {
        responderList.push({ value: data.id, id: data.id, text: data.name });
      });
    }
    return (
      <div style={{ display: 'flex' }}>
        <MozartSelectField
          data={responderList}
          onChange={(data) => { this.handleResponder(data); }}
          width="200px"
          hintText="Select Responder"
          defaultValue={customData}
        />
      </div>
    );
  }

  getFilterData(requestType) {
    const postData = {};
    if (this.state.onChangefilter) {
      if (this.state.onChangefilter.clientId) {
        postData.clientId = [this.state.onChangefilter.clientId];
      }
      if (this.state.onChangefilter.locationId) {
        postData.locationId = [this.state.onChangefilter.locationId];
      }
      if (this.state.onChangefilter.sourceId) {
        postData.sourceId = [this.state.onChangefilter.sourceId];
      }
      postData.status = (this.state.onChangefilter.status === 0 || this.state.onChangefilter.status === null) ? ['SEND_TO_SUPPORT', 'FAILED'] : [this.state.onChangefilter.status];
      if (this.state.onChangefilter.sortOrder) {
        postData.sortOrder = this.state.onChangefilter.sortOrder;
      }
      if (this.state.onChangefilter.limit) {
        postData.limit = this.state.onChangefilter.limit;
      }
      postData.offset = requestType === 'pageMore' ? (this.state.pageLoadMore * 20) : this.state.onChangefilter.offset;
    }
    return postData;
  }

  handleResponder(data) {
    this.setState({
      assignee: data
    });
  }

  handleSortOrder(data) {
    this.state.filter.sortOrder = data;
    this.state.filter.offset = 0;
    this.state.onChangefilter.sortOrder = data;
    this.state.onChangefilter.offset = 0;
    this.setState(previousState => ({
      ...previousState,
      filter: previousState.filter,
      onChangefilter: previousState.onChangefilter,
      pageLoadMore: 0
    }), () => {
      this.applyFilter('sort');
    });
  }

  applyFilter(pageMore) {
    let tableData = [];
    const postData = {};
    if (pageMore === 'filter') {
      const updateFilterObj = [];
      if (this.state.filter.clientId) {
        updateFilterObj.clientId = this.state.filter.clientId;
      }
      if (this.state.filter.locationId) {
        updateFilterObj.locationId = this.state.filter.locationId;
      }
      if (this.state.filter.sourceId) {
        updateFilterObj.sourceId = this.state.filter.sourceId;
      }
      if (this.state.filter.sortOrder) {
        updateFilterObj.sortOrder = this.state.filter.sortOrder;
      }
      if (this.state.filter.limit) {
        updateFilterObj.limit = this.state.filter.limit;
      }
      updateFilterObj.status = this.state.filter.status === 0 || this.state.filter.status === null ? 0 : this.state.filter.status;
      updateFilterObj.offset = this.state.filter.offset;
      this.setState({
        onChangefilter: updateFilterObj,
      }, () => {
        this.props.loadSupportTask(this.getFilterData('filter')).then(() => {
          tableData = this.props.sendSupportReducer.taskList;
          this.setState(previousState => ({
            ...previousState,
            taskList: tableData,
            initialTaskListCount: Object.values(tableData).length,
            pageLoadMore: 0
          }));
        });
      });
    }
    if (this.state.filter) {
      if (this.state.filter.clientId) {
        postData.clientId = [this.state.filter.clientId];
      }
      if (this.state.filter.locationId) {
        postData.locationId = [this.state.filter.locationId];
      }
      if (this.state.filter.sourceId) {
        postData.sourceId = [this.state.filter.sourceId];
      }
      if (this.state.filter.status) {
        postData.status = [this.state.filter.status];
      }
      if (this.state.filter.status === 0 || this.state.filter.status === null) {
        postData.status = ['SEND_TO_SUPPORT', 'FAILED'];
      }
      if (this.state.filter.sortOrder) {
        postData.sortOrder = this.state.filter.sortOrder;
      }
      if (this.state.filter.limit) {
        postData.limit = this.state.filter.limit;
      }
      postData.offset = pageMore === 'pageMore' ? (this.state.pageLoadMore * 20) : this.state.filter.offset;
    }
    if (pageMore === 'pageMore') {
      this.props.loadSupportTaskPageLoad(this.getFilterData('pageMore')).then(() => {
        tableData = this.props.sendSupportReducer.taskList;
        this.setState({
          taskList: tableData,
        });
      });
    }
    if (pageMore === 'sort') {
      this.props.loadSupportTask(this.getFilterData('sort')).then(() => {
        tableData = this.props.sendSupportReducer.taskList;
        this.setState(previousState => ({
          ...previousState,
          taskList: tableData,
          initialTaskListCount: Object.values(tableData).length,
          pageLoadMore: 0
        }));
      });
    }
  }

  handleScrollForPagination() {
    const dHeight = document.documentElement;
    const offset = (dHeight.scrollTop + window.innerHeight) + 1.9;
    const height = dHeight.offsetHeight;
    // const perfectPageCountByDefault = Object.values(this.state.taskList).length % 20;
    if (offset >= height && Object.values(this.state.taskList).length > 0 && (Object.values(this.state.taskList).length >= 20 || this.state.initialTaskListCount >= 20)) {
      const pageCount = this.state.pageLoadMore + 1;
      this.setState(previousState => ({
        ...previousState,
        pageLoadMore: pageCount,
        offset: pageCount * 20
      }), () => {
        this.applyFilter('pageMore');
      });
    }
  }

  handleOnChange(data, selectionType) {
    this.state.filter[selectionType] = data;
    const stateData = this.state.onChangefilter[selectionType];
    this.state.onChangefilter[selectionType] = stateData;
    if (selectionType === 'clientId') {
      this.state.filter.locationId = null;
      this.state.filter.sourceId = null;
      if (data === 0) {
        this.state.filter.clientId = null;
        this.state.locations = [];
        this.state.sources = [];
      } else {
        this.props.loadLocations(data).then(() => {
          this.setState({
            locations: this.props.locationReducer.data ? this.props.locationReducer.data : []
          });
        });
        this.props.loadSource(data).then(() => {
          this.setState({
            sources: this.props.sourceReducer.data ? this.props.sourceReducer.data : []
          });
        });
      }
      if (selectionType === 'status') {
        this.state.filter[selectionType] = data !== 0 ? data : 0;
      }
    }
    this.setState(previousState => ({
      ...previousState,
      filter: previousState.filter,
      locations: previousState.locations,
      sources: previousState.sources,
      onChangefilter: previousState.onChangefilter
      // pageLoadMore: 0
    }));
  }

  handleTaskAssignToNew(key, taskId, documentId, clientId) {
    console.log(key, taskId, documentId, clientId);
    // this.state.taskList[key].reassigned = true;
    this.state.currentTaskDetails.taskId = taskId;
    this.state.currentTaskDetails.documentId = documentId;
    this.state.currentTaskDetails.assignee = this.state.assignee;
    this.state.loader = true;
    const postData = {};
    if (taskId) {
      postData.clientId = clientId;
      postData.mentionId = documentId;
      postData.ownerId = this.state.assignee;
      const absoluteUrl = process.env.REACT_APP_ME_HOST + '/api/tasks/' + taskId + '/reassign';
      triggerFetch(absoluteUrl, this.onRequest, this.successCallBack, this.errorCallBack, 'POST', JSON.stringify(postData));
    }
  }

  handleRetry(key, taskId, documentId, clientId, assignee) {
    this.setState(previousState => ({
      ...previousState,
      currentTaskDetails: {
        ...previousState.currentTaskDetails,
        taskId: taskId,
        documentId: documentId,
        assignee: assignee,
      },
      loader: true,
      triggerRetry: true,
    }));

    if (taskId) {
      const postData = { taskId };
      const absoluteUrl = process.env.REACT_APP_ME_HOST + '/api/tasks/' + taskId + '/retry';
      triggerFetch(absoluteUrl, this.onRequest, this.successCallBack, this.errorCallBack, 'POST', JSON.stringify(postData), true);
    }
  }

  handleAssignPopup(key, taskId, documentId, clientId) {
    this.props.loadResponderList(clientId, documentId).then(() => {
      this.state.currentTaskDetails.taskId = taskId;
      this.state.currentTaskDetails.documentId = documentId;
      this.state.currentTaskDetails.assignee = '';
      this.state.currentTaskDetails.clientId = clientId;
      this.state.currentTaskDetails.key = key;
      this.setState(previousState => ({
        ...previousState,
        openAssignmentModal: true,
        assignee: '',
        currentTaskDetails: previousState.currentTaskDetails
      }));
    });
  }

  successCallBack(res) {
    const { taskStatus } = res;
    this.state.taskList[this.state.currentTaskDetails.key].reassigned = true;
    let msg = 'Task Assigned to ' + this.state.currentTaskDetails.assignee + ' !';
    let errorMsgFlag = false;
    let doneRetryFlag = false;
    let triggerRetryFlag = false;
    // retry fail then display fail message
    if (this.state.triggerRetry) {
      if (taskStatus === 'FAILED') {
        msg = 'Retry Failed!';
        this.state.taskList[this.state.currentTaskDetails.key].reassigned = false;
        errorMsgFlag = true;
      } else if (taskStatus === 'CLOSED') {
        // retry success, hide both and display successful message
        msg = 'Retry Successful';
        doneRetryFlag = true;
        triggerRetryFlag = true;
      }
    }
    // set retry disabled when re-assign successfully
    let triggerAssignFlag = this.state.triggerAssign;
    if (this.state.triggerAssign) {
      triggerAssignFlag = true;
    }
    this.state.loader = true;
    this.setState(previousState => ({
      ...previousState,
      taskList: previousState.taskList,
      currentTaskDetails: previousState.currentTaskDetails,
      notification_msg: msg,
      errorMsg: errorMsgFlag,
      loader: false,
      doneRetry: doneRetryFlag,
      triggerRetry: triggerRetryFlag,
      triggerAssign: triggerAssignFlag
    }));
  }

  errorCallBack() {
    // this.state.taskList[this.state.currentTaskDetails.key].reassigned = true;
    if (this.state.currentTaskDetails.key && this.state.taskList[this.state.currentTaskDetails.key] && this.state.taskList[this.state.currentTaskDetails.key].taskId) {
      this.state.currentTaskDetails.taskId = this.state.taskList[this.state.currentTaskDetails.key].taskId;
      this.state.currentTaskDetails.documentId = this.state.taskList[this.state.currentTaskDetails.key].documentId;
      this.state.currentTaskDetails.assignee = this.state.assignee;
    }
    this.state.loader = true;
    this.setState(previousState => ({
      ...previousState,
      errorMsg: true,
      loader: false,
      taskList: previousState.taskList,
      currentTaskDetails: previousState.currentTaskDetails,
      notification_msg: previousState.triggerRetry ? 'Retry Failed!' : 'Task Assignment Failed!',
      triggerAssign: false,
    }));
  }

  closeModal() {
    if (this.state.currentTaskDetails.taskId) {
      if (this.state.taskList[this.state.currentTaskDetails.key].reassigned !== undefined && this.state.taskList[this.state.currentTaskDetails.key].reassigned) {
        delete this.state.taskList[this.state.currentTaskDetails.key];
        this.state.taskList.splice(this.state.currentTaskDetails.key, 1);
      }
    }
    this.setState(previousState => ({
      ...previousState,
      taskList: previousState.taskList,
      openAssignmentModal: false,
      assignmentData: '',
      notification_msg: '',
      triggerRetry: false,
      doneRetry: false,
      triggerAssign: false,
      deleteReason:'',
      deleteReviewFlag:false
    }));
  }

  render() {
    return [
      <div>
        {this.getSupportRequestPanel()}
        {this.getSupportRequestTableSort()}
        {this.getSupportRequestTable()}
        {this.getAssignmentModal()}
        {(this.props.loading || this.state.loader) && <MozartLoading />}
      </div>
    ];
  }
}

SupportRequestPage.propTypes = {
  responderListReducer: PropTypes.object,
  sendSupportReducer: PropTypes.object,
  locationReducer: PropTypes.object,
  sourceReducer: PropTypes.object,
  permissions: PropTypes.object,
  // reAssignReducer: PropTypes.object,
  loadSupportTaskPageLoad: PropTypes.func,
  // reAssignTaskToUser: PropTypes.func,
  loadResponderList: PropTypes.func,
  loadSupportTask: PropTypes.func,
  loadLocations: PropTypes.func,
  loadSource: PropTypes.func,
  loading: PropTypes.bool,
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    loadClients,
    loadSupportTaskPageLoad,
    loadSupportTask,
    loadLocations,
    loadSource,
    loadResponderList,
    reAssignTaskToUser
  }, dispatch)
);

const mapStateToProps = state => ({
  loading: state.responderListReducer.loading || state.reAssignReducer.loading || state.sendSupportReducer.loadingPageLoad || state.clientReducer.loading || state.sourceReducer.loading || state.sendSupportReducer.loading,
  clientReducer: state.clientReducer,
  permissions: state.authReducer.permissions,
  sendSupportReducer: state.sendSupportReducer,
  locationReducer: state.locationReducer,
  sourceReducer: state.sourceReducer,
  responderListReducer: state.responderListReducer,
  reAssignReducer: state.reAssignReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(SupportRequestPage);
