export const LOAD_CONFIG = 'binary/queconfig/LOAD_CONFIG';
export const LOAD_CONFIG_SUCCESS = 'binary/queconfig/LOAD_CONFIG_SUCCESS';
export const LOAD_CONFIG_FAIL = 'binary/queconfig/LOAD_CONFIG_FAIL';
export const LOAD_CONFIG_SAVE = 'binary/queconfig/LOAD_CONFIG_SAVE';
export const LOAD_CONFIG_SAVE_SUCCESS = 'binary/queconfig/LOAD_CONFIG_SAVE_SUCCESS';
export const LOAD_CONFIG_SAVE_FAIL = 'binary/queconfig/LOAD_CONFIG_SAVE_FAIL';

export function loadConfigClient() {
  return {
    types: [LOAD_CONFIG, LOAD_CONFIG_SUCCESS, LOAD_CONFIG_FAIL],
    payload: {
      client: 'managementengagement',
      request: {
        method: 'get',
        url: '/api/clients'
      }
    }
  };
}

export function saveConfig(data) {
  return {
    types: [LOAD_CONFIG_SAVE, LOAD_CONFIG_SAVE_SUCCESS, LOAD_CONFIG_SAVE_FAIL],
    payload: {
      client: 'managementengagement',
      request: {
        method: 'post',
        url: '/api/clients',
        data: data
      }
    }
  };
}
