export const LOAD = 'binary/priorityTable/LOAD';
export const LOAD_SUCCESS = 'binary/priorityTable/LOAD_SUCCESS';
export const LOAD_FAIL = 'binary/priorityTable/LOAD_FAIL';
export const LOAD_MORE = 'binary/priorityTable/LOAD_MORE';
export const LOAD_MORE_SUCCESS = 'binary/priorityTable/LOAD_MORE_SUCCESS';
export const LOAD_MORE_FAIL = 'binary/priorityTable/LOAD_MORE_FAIL';
export const CREATE_EXPORT = 'binary/priorityTable/CREATE_EXPORT';
export const CREATE_EXPORT_SUCCESS = 'binary/priorityTable/CREATE_EXPORT_SUCCESS';
export const CREATE_EXPORT_FAIL = 'binary/priorityTable/CREATE_EXPORT_FAIL';

export function loadPriorityTable(postData) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],

    payload: {
      client: 'managementengagement',
      request: {
        method: 'post',
        data: postData,
        url: '/api/engagements/users/taskstatus'
      }
    }
  };
}

export function loadMorePriorityTable(postData) {
  return {
    types: [LOAD_MORE, LOAD_MORE_SUCCESS, LOAD_MORE_FAIL],

    payload: {
      client: 'managementengagement',
      request: {
        method: 'post',
        data: postData,
        url: '/api/engagements/users/taskstatus'
      }
    }
  };
}

export function exportReport(clientId, type, data) {
  return {
    types: [CREATE_EXPORT, CREATE_EXPORT_SUCCESS, CREATE_EXPORT_FAIL],
    payload: {
      client: 'nextgen',
      request: {
        method: 'post',
        data: data,
        url: '/api/reports/' + clientId + '/export/' + type
      }
    }
  };
}
