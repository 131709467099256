import {
  LOAD,
  LOAD_SUCCESS,
  LOAD_FAIL,
  LOAD_FLAT_CLIENT_SUCCESS,
  LOAD_FLAT_CLIENT_FAIL,
} from 'redux/actions/clientAction';

const initialState = {
  loaded: false,
  loading: false,
  data: [],
  clients: [],
  highLevelClients: [],
  mediumLevelClients: [],
  normalLevelClients: [],
};

function clientReducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case LOAD_SUCCESS:
      const clientList = state.data
        ? { ...state.data, ...action.payload.data }
        : action.payload.data;
      const highQueueClients = [];
      const mediumQueueClients = [];
      const normalQueueClients = [];
      const result = Object.values(clientList);
      if (result.length > 0) {
        result.forEach((client) => {
          if (client.queueId === 1) {
            highQueueClients.push({
              clientId: client.clientId,
              clientName: client.clientName,
              queueId: client.queueId,
            });
          }
          if (client.queueId === 2) {
            mediumQueueClients.push({
              clientId: client.clientId,
              clientName: client.clientName,
              queueId: client.queueId,
            });
          }
          if (client.queueId === 3) {
            normalQueueClients.push({
              clientId: client.clientId,
              clientName: client.clientName,
              queueId: client.queueId,
            });
          }
        });
      }
      return {
        ...state,
        loading: false,
        loaded: true,
        data: clientList,
        highLevelClients: highQueueClients,
        mediumLevelClients: mediumQueueClients,
        normalLevelClients: normalQueueClients,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case LOAD_FLAT_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        clients: action.payload.data,
      };
    case LOAD_FLAT_CLIENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        loaded: false,
      };
    default:
      return state;
  }
}

export default clientReducer;
