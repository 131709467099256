import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Header, QueueConfigureCard } from 'components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadBreadCrums } from 'redux/actions/breadcrumAction';
import './QueueConfigureView.scss';
import { meUserRole } from 'utils/util-element';

class QueueConfigureView extends Component {
  constructor(props) {
    super(props);
    meUserRole(this.props.permissions, 'MANAGER');
  }

  componentDidMount() {
    this.props.loadBreadCrums(this.getBreadCrums());
  }

  getBreadCrums() {
    return [
      { id: 1, name: 'Queue Configuration', href: 'QueueConfigureView' }
    ];
  }

  render() {
    return [<Header />,
      <Container fluid className="queue-config">
        <QueueConfigureCard />
      </Container>
    ];
  }
}

QueueConfigureView.propTypes = {
  permissions: PropTypes.object,
  loadBreadCrums: PropTypes.func,
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    loadBreadCrums
  }, dispatch)
);

const mapStateToProps = state => ({
  permissions: state.authReducer.permissions,
});

export default connect(mapStateToProps, mapDispatchToProps)(QueueConfigureView);
