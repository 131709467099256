import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './ReviewStatus.scss';
import moment from 'moment-timezone';

class ReviewStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // opinionModal: false
    };
  }

  render() {
    const { statusData, viewContainer } = this.props;
    return (
      <Row className="response_status">
        <Col xs={2} md={2} className="response_status_client">
          <Row>
            <Col xs={10} md={10}>
              <div className="response_status_client_title">CLIENT</div>
              <div className="response_status_client_value">{statusData.clientName}</div>
            </Col>
            <Col xs={2} md={2}>
              <div className="response_status_client_line">&nbsp;</div>
            </Col>
          </Row>
        </Col>
        <Col xs={1} md={1} className="response_status_taskId">
          <Row>
            <Col xs={8} md={8}>
              <div className="response_status_client_title">TASK ID</div>
              <div className="response_status_client_value">{statusData.taskId}</div>
            </Col>
            <Col xs={1} md={1}>
              <div className="response_status_client_line">&nbsp;</div>
            </Col>
          </Row>
        </Col>
        {
          viewContainer === 'RESPONDER' && (
            <Col xs={3} md={3} className="response_status_client">
              <Row>
                <Col xs={10} md={10}>
                  <div className="response_status_client_title">MENTION TIME</div>
                  <div className="response_status_client_value">{statusData.mentionTime !== 'null' ? moment(statusData.mentionTime).tz('America/Chicago').format('DD-MMM-YYYY HH:MM:SS') : ''}</div>
                </Col>
                <Col xs={2} md={2}>
                  <div className="response_status_client_line">&nbsp;</div>
                </Col>
              </Row>
            </Col>
          )
        }
        <Col xs={viewContainer !== 'RESPONDER' ? 2 : 3} md={viewContainer !== 'RESPONDER' ? 2 : 3} className="response_status_client">
          <Row>
            <Col xs={10} md={10}>
              <div className="response_status_client_title">IN-TIME</div>
              <div className="response_status_client_value">{statusData.inTime !== 'null' ? moment(statusData.inTime).tz('America/Chicago').format('DD-MMM-YYYY HH:MM:SS') : ''}</div>
            </Col>
            <Col xs={2} md={2}>
              <div className="response_status_client_line">&nbsp;</div>
            </Col>
          </Row>
        </Col>
        <Col xs={viewContainer !== 'RESPONDER' ? 2 : 3} md={viewContainer !== 'RESPONDER' ? 2 : 3} className="response_status_client">
          <Row>
            <Col xs={10} md={10}>
              <div className="response_status_client_title">TIME ELAPSED</div>
              <div className="response_status_client_value">{statusData.timeElapsed}</div>
            </Col>
            {
              viewContainer !== 'RESPONDER' && (
                <Col xs={2} md={2}>
                  <div className="response_status_client_line">&nbsp;</div>
                </Col>
              )
            }
          </Row>
        </Col>
        {
          viewContainer !== 'RESPONDER' && (
            <Col xs={2} md={2} className="response_status_client">
              <Row>
                <Col xs={10} md={10}>
                  <div className="response_status_client_title">RESPONDER</div>
                  <div className="response_status_client_value">
                    {statusData.responder}
                  </div>
                </Col>
                <Col xs={2} md={2}>
                  <div className="response_status_client_line">&nbsp;</div>
                </Col>
              </Row>
            </Col>
          )
        }
        {
          viewContainer !== 'RESPONDER' && (
            <Col xs={3} md={3} className="response_status_client">
              <Row>
                <Col>
                  <div className="response_status_client_title">STATUS</div>
                  <div className="response_status_client_value">
                    {statusData.status}
                  </div>
                </Col>
              </Row>
            </Col>
          )
        }
      </Row>
    );
  }
}

ReviewStatus.propTypes = {
  statusData: PropTypes.object,
  viewContainer: PropTypes.string
};

export default ReviewStatus;
