import {
  LOAD_TEMPLATE,
  LOAD_TEMPLATE_SUCCESS,
  LOAD_TEMPLATE_FAIL
} from 'redux/actions/templateAction';

const initialState = {
  loadedResponder: false,
  loadingResponder: false,
  template: []
};

export default function templateReducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_TEMPLATE:
      return {
        ...state,
        loadingResponder: true,
        loadedResponder: false
      };
    case LOAD_TEMPLATE_SUCCESS:
      const queueList = state.data ? { ...state.data, ...action.payload.data } : action.payload.data;
      return {
        ...state,
        loadingResponder: false,
        loadedResponder: true,
        template: queueList
      };
    case LOAD_TEMPLATE_FAIL:
      return {
        ...state,
        loadingResponder: false,
        loadedResponder: false,
        error: action.error
      };
    default:
      return state;
  }
}
