import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Header,
  SupportRequestPage
} from 'components';
import {
  MozartLoading
} from '@pgforsta/mozart_ui';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { meUserRole } from 'utils/util-element';

class SupportRequest extends Component {
  constructor(props) {
    super(props);
    meUserRole(this.props.permissions, 'MANAGER');
  }

  render() {
    return [
      <Header />,
      <div>
        <SupportRequestPage />
        {this.props.loading && <MozartLoading />}
      </div>
    ];
  }
}

SupportRequest.propTypes = {
  permissions: PropTypes.array,
  loading: PropTypes.bool
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({ }, dispatch)
);

const mapStateToProps = state => ({
  loading: state.clientReducer.loading || state.sourceReducer.loading || state.sendSupportReducer.loading,
  clientReducer: state.clientReducer,
  permissions: state.authReducer.permissions,
  sendSupportReducer: state.sendSupportReducer
});

export default connect(mapStateToProps, mapDispatchToProps)(SupportRequest);
