import {
  LOAD,
  LOAD_SUCCESS,
  LOAD_FAIL,
  DELETE,
  DELETE_SUCCESS,
  DELETE_FAIL,
  LOCK,
  LOCK_SUCCESS,
  LOCK_FAIL,
  LOCK_DOCUMENT,
  LOCK_DOCUMENT_SUCCESS,
  LOCK_DOCUMENT_FAIL
} from 'redux/actions/documentAction';

const initialState = {
  loaded: false,
  loading: false,
  loadedLockDocument: false,
  loadingLockDocument: false,
  documentData: [],
  deleteStatus: [],
  sentences: [],
  savedData: []
};

function documentReducer(state = initialState, action = {}) {
  switch (action.type) {
    case DELETE:
    case LOCK:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case DELETE_FAIL:
    case LOCK_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    case DELETE_SUCCESS:
      const dataList = action.payload.data ? action.payload.data : '';
      return {
        ...state,
        loading: false,
        loaded: true,
        deleteStatus: dataList
      };
    case LOCK_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true
      };
    case LOAD:
    case LOCK_DOCUMENT:
      return {
        ...state,
        documentData: [],
        loadingDocument: true,
        loadedDocument: false
      };
    case LOAD_SUCCESS:
      const documentDataList = action.payload.data ? action.payload.data : '';
      return {
        ...state,
        loadingDocument: false,
        loadedDocument: true,
        documentData: documentDataList
      };
    case LOCK_DOCUMENT_SUCCESS:
      const lockDocumentDataList = action.payload.data && action.payload.data.contentEditDTO ? action.payload.data.contentEditDTO : '';
      return {
        ...state,
        loadingDocument: false,
        loadedDocument: true,
        documentData: lockDocumentDataList
      };
    case LOAD_FAIL:
    case LOCK_DOCUMENT_FAIL:
      return {
        ...state,
        loadingDocument: false,
        loadedDocument: false,
        error: action.error
      };
    default:
      return state;
  }
}

export default documentReducer;
