import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Header, NotAvailable, Logo } from 'components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadBreadCrums } from 'redux/actions/breadcrumAction';
import { loadTaskUser } from 'redux/actions/taskUserAction';
import { MozartIcon, MozartWidgetLayOut, MozartLoading } from '@pgforsta/mozart_ui';
import './Home.scss';
import _ from 'lodash';

class Home extends Component {
  constructor(props) {
    super(props);
    const existingPages = [{
      id: [702, 703, 704, 705],
      url: '/#/me/manager',
      name: 'ME: Manager View',
      className: 'icon-EmManager',
      newTab: false
    }, {
      id: [707],
      url: '/#/me/responder',
      name: 'ME: Responder View',
      className: 'icon-EmFeedback',
      newTab: false
    }, {
      id: [708],
      url: '/#/me/approver',
      name: 'ME: Approver View',
      className: 'icon-Complete',
      newTab: false
    }, {
      id: [578, 580, 581],
      url: '/#/content',
      name: 'Content Tagging',
      className: 'icon-Edit'
    }, {
      id: [715, 716, 717],
      url: '/#/report/watch',
      name: 'Report Monitor',
      className: 'icon-Calendar',
      newTab: false
    }, {
      id: [714],
      url: process.env.REACT_APP_REPORT_UI_HOST,
      name: 'Reports',
      className: 'icon-ReportDetailed',
      newTab: true
    }, {
      id: [719],
      url: process.env.REACT_APP_CRAWLER_HOST,
      name: 'Crawler Management',
      className: 'icon-Crawler',
      newTab: true
    }];
    const userPages = this.getUserPages(existingPages);
    this.state = {
      userPages: userPages,
      configResponse: [],
      contentPage: false,
      role: ''
    };
  }

  componentDidMount() {
    const { permissions } = this.props;
    this.props.loadBreadCrums(this.getBreadCrums());
    const role = this.userRole(permissions);
    if (role === 'RESPONDER' || role === 'APPROVER') {
      this.props.loadTaskUser(role).then(() => {
        this.setState({
          configResponse: this.props.task ? this.props.task : []
        });
      });
    }
    if (role === 'CONTENT') {
      this.setState({
        contentPage: true
      });
    }
  }

  getUserPages(existingPages) {
    const { permissions } = this.props;
    const userPages = [];
    existingPages.forEach((item) => {
      if (item.id.some(r => permissions.includes(r))) {
        userPages.push(item);
      }
    });
    return userPages;
  }

  getBreadCrums() {
    return [
      { id: 1, name: 'Home', href: 'home' }
    ];
  }

  getPages() {
    const pages = [];
    if (this.state.userPages.length === 0) {
      pages.push(<NotAvailable />);
    } else {
      this.state.userPages.forEach((userPage) => {
        pages.push(
          <Col className="page-box" xs={4} md={4}>
            <Col xs={12} md={12}>
              <MozartIcon
                data={<span onClick={this.goToPage.bind(this, userPage)} style={{ fontSize: '9em', cursor: 'pointer' }} className={userPage.className} />}
                style={{
                  margin: '0 auto', height: '100px', width: '100px', paddingTop: '20px', fontSize: '6px'
                }}
              />
            </Col>
            <Col xs={12} md={12}>
              <div className="label" onClick={this.goToPage.bind(this, userPage)}>
                {userPage.name}
              </div>
            </Col>
          </Col>
        );
      });
    }
    return pages;
  }

  goToPage(userPage) {
    if (userPage.newTab) {
      window.open(userPage.url, '_blank');
    } else {
      window.location.href = userPage.url;
    }
  }

  userRole(permissionArray) {
    const managerArray = [702, 703, 704, 705];
    const responderArray = [707];
    const approvalArray = [708];
    const managerRole = _.some(permissionArray, item => managerArray.includes(item));
    const responderRole = _.some(permissionArray, item => responderArray.includes(item));
    const approvalRole = _.some(permissionArray, item => approvalArray.includes(item));
    let nlpQcToolRole = '';
    if (permissionArray.includes(578) && permissionArray.includes(580)) {
      nlpQcToolRole = 'CONTENT';
    }
    if (permissionArray.includes(578) && permissionArray.includes(581)) {
      nlpQcToolRole = 'CONTENT';
    }
    if (permissionArray.includes(714)) {
      nlpQcToolRole = 'CONTENT';
    }
    if (permissionArray.includes(578) && !permissionArray.includes(580) && !permissionArray.includes(581)) {
      nlpQcToolRole = 'CONTENT';
    }
    let Role = '';
    if (managerRole) {
      Role = 'MANAGER';
    } else if (responderRole) {
      Role = 'RESPONDER';
    } else if (approvalRole) {
      Role = 'APPROVER';
    } else if (nlpQcToolRole) {
      Role = nlpQcToolRole;
    } else if (permissionArray.length === 0) {
      Role = 'unknown';
    } else if (permissionArray.includes(719)) {
      Role = 'CRAWLERADMIN';
    }
    this.setState({ role: Role });
    return Role;
  }

  render() {
    const { loadingTask } = this.props;
    return [<Header />,
      <Container fluid className="home">
        <Helmet title="Home" />
        <Container fluid className="header">
          <Col xs={12} md={12} className="home-cotainer-logo">
            <Logo logo="/no_logo.png" />
            <span className="title">Internal Tools</span>
          </Col>
        </Container>
        {(['MANAGER', 'CRAWLERADMIN'].includes(this.state.role) || this.state.contentPage) ?
          (
            <Container fluid>
              <Row xs={12} md={12} className="pages">
                {this.getPages()}
              </Row>
            </Container>
          )
          :
          (
            <Container fluid>
              {(loadingTask) ?
                <MozartLoading inline loading={loadingTask} />
                :
                (
                  (this.state.configResponse.queueConfigured && this.state.configResponse.taskId > 0) &&
                    (
                      <Row xs={12} md={12} className="pages">
                        {this.getPages()}
                      </Row>
                    )
                )}
              {this.state.configResponse.queueConfigured === false &&
                (
                  <MozartWidgetLayOut
                    contentStyle={{ height: '100px' }}
                  >
                    <div style={{ padding: '1em' }}>There is no queue assigned to you. Please contact Manager to get a Queue Assigned</div>
                  </MozartWidgetLayOut>
                )}
              {this.state.configResponse.queueConfigured && this.state.configResponse.taskId === 0 &&
                (
                  <MozartWidgetLayOut
                    contentStyle={{ height: '100px' }}
                  >
                    <div style={{ padding: '1em' }}>There is no Task available to engage in any queues</div>
                  </MozartWidgetLayOut>
                )}
              {this.state.role === 'unknown' &&
                (
                  <MozartWidgetLayOut
                    contentStyle={{ height: '100px' }}
                  >
                    <div style={{ padding: '1em' }}>Sorry, you don&lsquo;t have access to this page. Please contact the administrator.</div>
                  </MozartWidgetLayOut>
                )}
            </Container>
          )}
      </Container>
    ];
  }
}

Home.propTypes = {
  permissions: PropTypes.object,
  loadBreadCrums: PropTypes.func,
  loadTaskUser: PropTypes.func,
  loadingTask: PropTypes.bool,
  task: PropTypes.object,
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    loadBreadCrums, loadTaskUser
  }, dispatch)
);

const mapStateToProps = state => ({
  permissions: state.authReducer.permissions,
  task: state.taskUserReducer.task,
  loadingTask: state.taskUserReducer.loadingTask,
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
