import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import { Profile } from 'components';
import Col from 'react-bootstrap/Col';
import { MozartIcon } from '@pgforsta/mozart_ui';

class NotAvailable extends Component {
  render() {
    return (
      <div>
        <Col xs={12} md={12} style={{ marginTop: '225px' }}>
          <Col xs={4} md={4}>
            {this.props.loadingMessage && <MozartIcon width="65px" height="65px" data={<span className="icon-Information" />} style={{ fontSize: '20px', paddingTop: '12px' }} />}
          </Col>
        </Col>
        <Col xs={12} md={12} style={{ marginTop: '20px', paddingLeft: '35px' }}>
          <p>{this.props.loadingMessage ? this.props.loadingMessage : ''}</p>
        </Col>
      </div>
    );
  }
}

NotAvailable.propTypes = {
  loadingMessage: PropTypes.string
};
export default connect()(NotAvailable);
