import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Header, DocumentBlock, NotAvailable } from 'components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadBreadCrums } from 'redux/actions/breadcrumAction';
import { load as loadUnlockAll } from 'redux/actions/unlockAllAction';
import { load as loadDocumentAutoGenerate } from 'redux/actions/documentAutoGenerateAction';
import { MozartLoading } from '@pgforsta/mozart_ui';
import { load as loadDocument, lock, lockDocument } from 'redux/actions/documentAction';
import { load as loadCategory } from 'redux/actions/categoryAction';

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bucketId: '',
      initLoad: true,
      category: []
    };

    this.onBucketChange = this.onBucketChange.bind(this);
    this.handleScrollForFixedSentenceTextHeader = this.handleScrollForFixedSentenceTextHeader.bind(this);
    this.handleNextDocument = this.handleNextDocument.bind(this);
    this.handleNextAllDocument = this.handleNextAllDocument.bind(this);
  }

  componentDidMount() {
    document.removeEventListener('scroll', this.handleScrollForFixedSentenceTextHeader, false);
    document.addEventListener('scroll', this.handleScrollForFixedSentenceTextHeader);
    this.props.loadBreadCrums(this.getBreadCrums());
  }

  onBucketChange(data) {
    this.state.category = [];
    const permissionRole = this.getPermissionRole();
    if (data && permissionRole) {
      this.state.initLoad = false;
      this.props.loadUnlockAll().then(() => {
        if (this.props.unlockAll) {
          const postData = {
            bucketId: data,
            limit: 5,
            offset: 0
          };
          this.props.loadDocumentAutoGenerate(false, postData).then((result) => {
            this.setState({
              bucketId: data
            }, () => {
              this.loadNextDocument(result.payload.data);
            });
          });
        }
      });
    } else {
      this.state.initLoad = false;
    }
  }

  getBreadCrums() {
    return [
      {
        id: 1,
        name: 'Home',
        href: process.env.REACT_APP_TOOLS_SOCIAL_URL,
        absolutePath: true
      },
      {
        id: 2,
        name: 'Content',
        href: process.env.REACT_APP_TOOLS_SOCIAL_URL,
        absolutePath: true
      },
      { id: 3, name: 'Editor', href: '#' }
    ];
  }

  getPermissionRole() {
    let permissionRole = '';
    if (this.props.authReducer.permissions && this.props.authReducer.permissions.length > 0) {
      if (this.props.authReducer.permissions.includes(578) && this.props.authReducer.permissions.includes(580)) {
        permissionRole = 'CONTENT_ADMINISTRATOR';
      }
      if (this.props.authReducer.permissions.includes(578) && this.props.authReducer.permissions.includes(581)) {
        permissionRole = 'CONTENT_QC';
      }
      if (this.props.authReducer.permissions.includes(578) && !this.props.authReducer.permissions.includes(580) && !this.props.authReducer.permissions.includes(581)) {
        permissionRole = 'CONTENT_EDITOR';
      }
      if (this.props.authReducer.permissions.includes(580)) {
        permissionRole = 'CONTENT_ADMINISTRATOR';
      }
      if (this.props.authReducer.permissions.includes(0)) {
        permissionRole = 'NO_PERMISSION';
      }
    }
    return permissionRole;
  }

  checkDataAvailable() {
    let message = '';
    let hasPermission = true;
    const { documentReducer } = this.props;
    const permissionRole = this.getPermissionRole();
    const { contentExist, contentId } = documentReducer.documentData;
    if (!this.props.loading) {
      if (!(this.props.authReducer.permissions && this.props.authReducer.permissions.length > 0 && (this.props.authReducer.permissions.includes(578) || this.props.authReducer.permissions.includes(580) || this.props.authReducer.permissions.includes(581)))) {
        hasPermission = false;
        message = 'Sorry, you don\'t have access to this page. Please contact the administrator. ';
      } else if (!this.props.queueReducer.loading && this.props.queueReducer.userBuckets && Object.keys(this.props.queueReducer.userBuckets).length === 0 && permissionRole) {
        hasPermission = false;
        message = 'No Queue assigned with you!. Please contact the administrator.';
      } else if (this.props.documentAutoGenerateReducer.contentId === null) {
        hasPermission = false;
        message = 'There are no records to show you right now. there may be no entries or your filters may be too tightly defined.';
      } else if (!contentExist || contentId === null) {
        hasPermission = false;
        message = 'The content details cannot be displayed as this content is no longer active.';
      } else if (this.props.authReducer.permissions && this.props.authReducer.permissions.length === 0) {
        hasPermission = false;
        message = 'Sorry, you don\'t have access to this page. Please contact the administrator.';
      }
    }
    return (!hasPermission && <NotAvailable loadingMessage={message} />);
  }

  handleInitialLoadData() {
    const permissionRole = this.getPermissionRole();
    if (permissionRole === 'ADMIN' || permissionRole === 'CONTENT_ADMINISTRATOR' || permissionRole === 'CONTENT_QC') {
      this.props.loadDocumentAutoGenerate(true).then((nextLockedStatus) => {
        if (!(nextLockedStatus && nextLockedStatus.payload.data && nextLockedStatus.payload.data > 0)) {
          window.location.href = process.env.REACT_APP_TOOLS_SOCIAL_URL + '/panel';
        } else {
          this.state.initLoad = false;
          this.loadNextDocument(nextLockedStatus.payload.data);
        }
      });
    }
  }

  handleScrollForFixedSentenceTextHeader() {
    return (() => {
      const scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
      if (scrollTop >= 150 && document.getElementById('documentContentBlock')) {
        document.getElementById('documentContentBlock').style.top = '0px';
        document.getElementById('documentContentBlock').style.position = 'stickey';
        document.getElementById('documentContentBlock').style.backgroundColor = '#F5F4FF';
        document.getElementById('documentContentBlock').style.width = '100%';
        document.getElementById('documentContentBlock').style.zIndex = '1000';
      } else if (document.getElementById('documentContentBlock')) {
        document.getElementById('documentContentBlock').style.backgroundColor = 'white';
        document.getElementById('documentContentBlock').style.zIndex = 'unset';
      }
    })();
  }

  handleNextDocument() {
    this.state.category = [];
    const permissionRole = this.getPermissionRole();
    const enableUserQueue = true;
    this.props.lock(this.props.documentAutoGenerateReducer.contentId).then((lockStatus) => {
      if (lockStatus.payload.status === 200) {
        const postData = {
          bucketId: this.state.bucketId,
          limit: 5,
          offset: 0
        };
        if (!enableUserQueue && (permissionRole === 'ADMIN' || permissionRole === 'CONTENT_ADMINISTRATOR' || permissionRole === 'CONTENT_QC')) {
          this.props.loadDocumentAutoGenerate(true).then((resData) => {
            if (!(resData.payload.data && resData.payload.data > 0)) {
              window.location.href = process.env.REACT_APP_TOOLS_SOCIAL_URL + '/panel';
            } else {
              this.loadNextDocument(resData.payload.data);
            }
          });
        } else {
          this.props.loadDocumentAutoGenerate(false, postData).then((resData) => {
            this.loadNextDocument(resData.payload.data);
          });
        }
      }
    });
  }

  handleNextAllDocument(currentContentId, qc1Completed, qc2Completed, nlpCompleted) {
    this.state.category = [];
    const permissionRole = this.getPermissionRole();
    let level = 0;
    if (nlpCompleted && !qc1Completed && !qc2Completed) {
      level = 1;
    }
    if (qc1Completed) {
      level = 2;
    }
    if (qc2Completed) {
      level = 2;
    }
    const postData = {
      bucketId: this.state.bucketId,
      limit: 5,
      offset: 0,
      contentIds: [currentContentId],
      release: true,
      contentId: currentContentId,
      qcLevel: level
    };
    if (permissionRole === 'ADMIN' || permissionRole === 'CONTENT_ADMINISTRATOR' || permissionRole === 'CONTENT_QC') {
      this.props.lockDocument(postData).then((response) => {
        if (response.payload && response.payload.data) {
          const result = response.payload.data;
          const documentData = JSON.parse(JSON.stringify(result.contentEditDTO));
          if (!(documentData && documentData.contentId > 0)) {
            window.location.href = process.env.REACT_APP_TOOLS_SOCIAL_URL + '/panel';
          } else if (documentData.product) {
            this.loadCategory(documentData.product);
          }
        }
      });
    } else {
      this.props.lockDocument(postData).then((response) => {
        if (response.payload && response.payload.data) {
          const result = response.payload.data;
          const documentData = JSON.parse(JSON.stringify(result.contentEditDTO));
          if (documentData && documentData.product) {
            this.loadCategory(documentData.product);
          }
        }
      });
    }
  }

  loadCategory(product) {
    this.props.loadCategory(product).then((categoryRespo) => {
      let catData = [];
      if (categoryRespo.payload.data) {
        if (categoryRespo.payload.data[1]) {
          catData = categoryRespo.payload.data && categoryRespo.payload.data[1] ? categoryRespo.payload.data[1] : [];
        } else {
          catData = categoryRespo.payload.data && categoryRespo.payload.data[0] ? categoryRespo.payload.data[0] : [];
        }
        this.setState({
          category: catData
        });
      }
    });
  }

  loadNextDocument(contentId) {
    this.props.loadDocument(contentId).then((response) => {
      if (response.payload && response.payload.data) {
        const documentData = JSON.parse(JSON.stringify(response.payload.data));
        if (documentData.contentId && documentData.contentId > 0) {
          if (documentData.product) {
            this.loadCategory(documentData.product);
          }
        }
      }
    });
  }

  render() {
    const { authReducer, documentReducer } = this.props;
    return [
      <Header hasBucket hasBreadcrum onChange={this.onBucketChange} />,
      <div>
        {(documentReducer.documentData && documentReducer.documentData.contentId > 0 && this.state.category.children) &&
          [<DocumentBlock
            key={documentReducer.documentData.contentId}
            userPermissions={authReducer.permissions}
            handleNextDocument={this.handleNextDocument}
            handleNextAllDocument={this.handleNextAllDocument}
            category={this.state.category}
          />]}
        {this.props.loading && <MozartLoading loading={this.props.loading} />}
        {this.checkDataAvailable()}
      </div>
    ];
  }
}

Content.propTypes = {
  loading: PropTypes.bool,
  unlockAll: PropTypes.bool,
  authReducer: PropTypes.object,
  queueReducer: PropTypes.object,
  documentAutoGenerateReducer: PropTypes.object,
  lock: PropTypes.func,
  lockDocument: PropTypes.func,
  loadDocument: PropTypes.func,
  loadUnlockAll: PropTypes.func,
  loadBreadCrums: PropTypes.func,
  documentReducer: PropTypes.func,
  loadDocumentAutoGenerate: PropTypes.func,
  loadCategory: PropTypes.func,
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    loadDocument, loadUnlockAll, lock, loadDocumentAutoGenerate, loadCategory, loadBreadCrums, lockDocument
  }, dispatch)
);

const mapStateToProps = state => ({
  authReducer: state.authReducer,
  unlockAll: state.unlockAllReducer.unlockAll,
  documentAutoGenerateReducer: state.documentAutoGenerateReducer,
  queueReducer: state.queueReducer,
  documentReducer: state.documentReducer,
  loading: state.queueReducer.loading || state.documentAutoGenerateReducer.loading || state.documentReducer.loading || state.documentReducer.loadingDocument || state.categoryReducer.loading || state.unlockAllReducer.loading || state.sentimentReducer.loading || state.contentReducer.loading
});

export default connect(mapStateToProps, mapDispatchToProps)(Content);
