export const LOAD_APPROVAL = 'binary/approval/LOAD_RESPONDER';
export const LOAD_APPROVAL_SUCCESS = 'binary/approval/LOAD_RESPONDER_SUCCESS';
export const LOAD_APPROVAL_FAIL = 'binary/approval/LOAD_RESPONDER_FAIL';

export const LOAD_NEXT = 'binary/approval/LOAD_NEXT';
export const LOAD_NEXT_SUCCESS = 'binary/approval/LOAD_NEXT_SUCCESS';
export const LOAD_NEXT_FAIL = 'binary/approval/LOAD_NEXT_FAIL';

export function loadApproval(id, role) {
  return {
    types: [LOAD_APPROVAL, LOAD_APPROVAL_SUCCESS, LOAD_APPROVAL_FAIL],
    payload: {
      client: 'managementengagement',
      request: {
        method: 'get',
        url: '/api/tasks/' + id + '?userRole=' + role
      }
    }
  };
}

export function approveAndNext(id, data) {
  return {
    types: [LOAD_NEXT, LOAD_NEXT_SUCCESS, LOAD_NEXT_FAIL],
    payload: {
      client: 'managementengagement',
      request: {
        method: 'post',
        url: '/api/tasks/' + id + '/approve',
        data: data
      }
    }
  };
}
