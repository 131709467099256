import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import {
  PriorityChartLegend,
} from 'components';
import {
  BarChart, Bar, YAxis, CartesianGrid, Tooltip, LabelList, Cell, ResponsiveContainer
} from 'recharts';
import { MozartButton, MozartBreadCrum, MozartSelectField } from '@pgforsta/mozart_ui';
import { loadPriority, loadClientPriority, loadSourcePriority } from 'redux/actions/priorityAction';
import { loadBreadCrumsDashboard } from 'redux/actions/breadcrumAction';
import { loadSource } from 'redux/actions/sourceAction';
// import { flattenChildren } from 'utils/util-element';
import PriorityTable from 'components/PriorityTable/PriorityTable';
import { loadPriorityTable } from 'redux/actions/priorityTableAction';
import { loadRoles } from 'redux/actions/meRolesAction';
import PropTypes from 'prop-types';
import './PriorityChartDashboard.scss';
import 'react-dates/initialize';
import moment from 'moment';

class PriorityChartDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      priorityData: this.props.priorityReducer ? this.props.priorityReducer.data : [],
      priorityClientData: this.props.priorityReducer ? this.props.priorityReducer.clientData : [],
      prioritySourceData: this.props.priorityReducer ? this.props.priorityReducer.clientData : [],
      currentChartLevel: 1,
      currentPriorityLevel: 3,
      currentClient: this.props.clientReducer.data && this.props.clientReducer.data[0] ? this.props.clientReducer.data[0].clientId : '',
      currentSource: '',
      currentSourceName: '',
      currentHeaderTitle: 'Queue Status Dashboard'
    };
    this.startDate = moment().subtract(30, 'days').format('YYYYMMDD');
    this.endDate = moment(new Date()).tz('America/Chicago').format('YYYYMMDD');
  }

  componentDidMount() {
    this.props.loadPriority().then(() => {
      if (this.props.priorityReducer && this.props.priorityReducer.data) {
        this.setState({
          priorityData: this.props.priorityReducer ? this.props.priorityReducer.data : [],
        });
      }
    });
    this.getPriorityTableData();
    this.props.loadRoles();
  }

  onBreadcrumpOnClick(data) {
    if (data.id) {
      this.getCustomBreadCrum(data.id, data.value);
      this.setState({
        currentChartLevel: data.id,
        currentHeaderTitle: data.name
      });
      this.getNextLevelChart(data.id, this.state.currentPriorityLevel);
    }
  }

  getCustomBreadCrum(currentChartLevel, currentPriorityLevel) {
    this.props.loadBreadCrumsDashboard(this.getBreadCrums(currentChartLevel, currentPriorityLevel));
  }

  getPriorityTableData() {
    const postData = {
      roleIds: [this.props.meRolesReducer && this.props.meRolesReducer.data && this.props.meRolesReducer.data[2] ? this.props.meRolesReducer.data[2].roleId : 471],
      fromDate: this.state.currentFromDate ? this.state.currentFromDate : this.startDate,
      toDate: this.state.currentToDate ? this.state.currentToDate : this.endDate,
      offset: 0,
      limit: 20,
      priorityId: 1,
      user: this.state.currentUser
    };
    if (this.state.currentPriorityLevel > 0) {
      postData.priorityId = this.state.currentPriorityLevel;
    }
    if (this.state.currentChartLevel > 1) {
      postData.clientId = this.state.currentClient;
    }
    if (this.state.currentChartLevel > 2) {
      postData.sourceId = this.state.currentSource;
    }
    this.props.loadPriorityTable(postData);
  }

  getBreadCrums(currentChartLevel, currentPriorityLevel) {
    const breadCrumArray = [];
    if (currentChartLevel === 2) {
      breadCrumArray.push({ id: 1, name: 'Queue Status Dashboard', value: currentPriorityLevel });
      breadCrumArray.push({ id: 2, name: this.getTitle(currentPriorityLevel), value: currentPriorityLevel });
    }
    if (currentChartLevel === 3) {
      breadCrumArray.push({ id: 1, name: 'Queue Status Dashboard', value: currentPriorityLevel });
      breadCrumArray.push({ id: 2, name: this.getTitle(currentPriorityLevel), value: currentPriorityLevel });
      breadCrumArray.push({ id: 3, name: this.state.currentClient, value: currentPriorityLevel });
    }
    return breadCrumArray;
  }

  getBarChart(priorityType) {
    const chartData = [];
    let priorutyDataObj = this.state.priorityData;
    if (this.state.currentChartLevel === 2) {
      priorutyDataObj = this.state.priorityClientData;
    }
    if (this.state.currentChartLevel === 3) {
      priorutyDataObj = this.state.prioritySourceData;
    }
    let dataBlock = '';
    if (priorityType === 1) {
      priorityType = 'HIGH';
    }
    if (priorityType === 2) {
      priorityType = 'MEDIUM';
    }
    if (priorityType === 3) {
      priorityType = 'NORMAL';
    }
    if (priorutyDataObj) {
      let chartDataObj = '';
      Object.keys(priorutyDataObj).forEach(key => {
        if (priorutyDataObj[key].queueName === priorityType) {
          chartDataObj = priorutyDataObj[key];
        }
      });
      chartData.push({ name: 'queueSize', count: chartDataObj.queueSize > 0 ? chartDataObj.queueSize : 0, fillColor: '#403788' });
      chartData.push({ name: 'numberOfTasksInprogressCount', count: chartDataObj.numberOfTasksInprogressCount ? chartDataObj.numberOfTasksInprogressCount : 0, fillColor: '#715AFF' });
      chartData.push({ name: 'numberOfTasksPendingApprovalCount', count: chartDataObj.numberOfTasksPendingApprovalCount ? chartDataObj.numberOfTasksPendingApprovalCount : 0, fillColor: '#D3CFF8' });
      chartData.push({ name: 'numberOfTasksClosedCount', count: chartDataObj.numberOfTasksClosedCount ? chartDataObj.numberOfTasksClosedCount : 0, fillColor: '#47B300' });
      chartData.push({ name: 'numberOfTaskSentForSupportCount', count: chartDataObj.numberOfTaskSentForSupportCount ? chartDataObj.numberOfTaskSentForSupportCount : 0, fillColor: '#F76E27' });
      chartData.push({ name: 'numberOfTaskFailedCount', count: chartDataObj.numberOfTaskFailedCount ? chartDataObj.numberOfTaskFailedCount : 0, fillColor: '#FF0000' });
    }
    const renderCustomBarLabel = ({
      x,
      y,
      width,
      value
    }) => {
      const dataVal = value;
      return <text x={x + width / 2} y={y} fill="#666" textAnchor="middle" dy={-6}>{`${dataVal}`}</text>;
    };
    dataBlock = (
      <Col xs={12} md={12} style={{ width: '100%', height: '100%' }}>
        <ResponsiveContainer width="99%" height="80%">
          <BarChart
            data={chartData}
            margin={{
              top: 25,
              bottom: 5
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="count" fill="#8884d8" minPointSize={5} barSize={30} label={renderCustomBarLabel}>
              {chartData.map((entry, index) => (
                <Cell fill={chartData[index].fillColor} />
              ))}
              {chartData.map((entry, indexCount) => (
                <LabelList dataKey="" content={chartData[indexCount].count} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Col>
    );
    if (chartData.length === 0) {
      dataBlock = '<Col>  </Col>';
    }
    return dataBlock;
  }

  getNextLevelChart(currentChartLevel, currentPriorityLevel, optionalData) {
    if (optionalData === undefined) {
      optionalData = this.state.currentSource;
    }
    if (currentPriorityLevel === 'HIGH') {
      currentPriorityLevel = 1;
    }
    if (currentPriorityLevel === 'MEDIUM') {
      currentPriorityLevel = 2;
    }
    if (currentPriorityLevel === 'NORMAL') {
      currentPriorityLevel = 3;
    }
    if (currentChartLevel === 2) {
      const dataObj = this.props.clientReducer.data ? this.props.clientReducer.data : [];
      const clientData = [];
      const resultClient = Object.values(dataObj);
      if (resultClient.length > 0) {
        resultClient.forEach((data) => {
          if (data.clientId && data.queueId === currentPriorityLevel) {
            clientData.push({
              value: data.clientId,
              id: data.clientId,
              text: data.clientName,
              level: data.queueId
            });
          }
        });
      }
      const selectedClient = (this.state.currentPriorityLevel !== currentPriorityLevel && clientData[0]) ? clientData[0].id : this.state.currentClient;
      const dataObjSource = this.props.sourceReducer.data ? this.props.sourceReducer.data : [];
      if ((this.state.currentPriorityLevel !== currentPriorityLevel) && (dataObjSource && Object.values(dataObjSource).length === 0) && selectedClient) {
        this.props.loadSource(selectedClient).then(() => {
        });
      }
      const postData = {
        clientId: this.state.currentClient,
        priorityId: currentPriorityLevel
      };
      this.props.loadClientPriority(postData).then(() => {
        this.getCustomBreadCrum(2, currentPriorityLevel);
        let sourceName = '';
        let sourceId = '';
        if (this.props.priorityReducer && this.props.priorityReducer.clientData) {
          if (dataObjSource && Object.values(dataObjSource).length > 0) {
            const result = Object.values(dataObjSource);
            if (result.length > 0) {
              sourceName = result[0].id === 100000 ? 'All Source' : result[0].name;
              sourceId = result[0].id;
            }
          }
          this.setState({
            priorityClientData: this.props.priorityReducer.clientData ? this.props.priorityReducer.clientData : [],
            currentChartLevel: currentChartLevel,
            currentPriorityLevel: currentPriorityLevel,
            currentSource: sourceId,
            currentSourceName: sourceName,
            currentClient: selectedClient
          }, () => {
            this.getPriorityTableData();
          });
        }
      });
    }
    if (currentChartLevel === 3) {
      const postData = {
        clientId: this.state.currentClient,
        priorityId: currentPriorityLevel,
        sourceId: this.state.currentSource
      };
      this.props.loadSourcePriority(postData).then(() => {
        this.getCustomBreadCrum(3, currentPriorityLevel);
        if (this.props.priorityReducer && this.props.priorityReducer.sourceData) {
          this.setState({
            prioritySourceData: this.props.priorityReducer.sourceData ? this.props.priorityReducer.sourceData : [],
            currentChartLevel: currentChartLevel,
            currentPriorityLevel: currentPriorityLevel,
            currentSource: optionalData,
          });
        }
      });
    }
  }

  getNextLevelChartOld(currentChartLevel, currentPriorityLevel, optionalData) {
    if (optionalData === undefined) {
      optionalData = this.state.currentSource;
    }
    if (currentPriorityLevel === 'HIGH') {
      currentPriorityLevel = 1;
    }
    if (currentPriorityLevel === 'MEDIUM') {
      currentPriorityLevel = 2;
    }
    if (currentPriorityLevel === 'NORMAL') {
      currentPriorityLevel = 3;
    }
    if (currentChartLevel && currentPriorityLevel) {
      const dataObjSet = this.props.sourceReducer.data ? this.props.sourceReducer.data : [];
      if (dataObjSet && Object.values(dataObjSet).length === 0) {
        this.props.loadSource(this.state.currentClient).then(() => {
        });
      }
      if (dataObjSet && Object.values(dataObjSet).length > 0) {
        const result = Object.values(dataObjSet);
        let sourceName = '';
        let sourceId = '';
        if (result.length > 0) {
          result.forEach((data) => {
            if (data.id === optionalData) {
              sourceName = data.id === 100000 ? 'All Source' : data.name;
              sourceId = data.id;
            }
          });
          this.setState({
            currentSource: sourceId,
            currentSourceName: sourceName
          });
        }
      }
      if (currentChartLevel === 2) {
        const postData = {
          clientId: this.state.currentClient,
          priorityId: currentPriorityLevel
        };
        this.props.loadClientPriority(postData).then(() => {
          const response = this.props.priorityReducer && this.props.priorityReducer.clientData ? this.props.priorityReducer.clientData : [];
          if (response) {
            this.setState({
              priorityClientData: response,
              currentChartLevel: currentChartLevel,
              currentPriorityLevel: currentPriorityLevel,
            });
            this.getCustomBreadCrum(2, currentPriorityLevel);
            this.getClientPriorityCharts();
          }
        });
      }
      if (currentChartLevel === 3) {
        const postData = {
          clientId: this.state.currentClient,
          priorityId: currentPriorityLevel,
          source: optionalData
        };
        this.props.loadSourcePriority(postData).then(() => {
          if (this.props.priorityReducer && this.props.priorityReducer.clientData) {
            const dataObj = this.props.sourceReducer.data ? this.props.sourceReducer.data : [];
            const result = Object.values(dataObj);
            let title = '';
            if (result.length > 0) {
              result.forEach((data) => {
                if (data.id === optionalData) {
                  title = data.id === 100000 ? 'All Source' : data.name;
                }
              });
            }
            this.setState({
              prioritySourceData: this.props.priorityReducer ? this.props.priorityReducer.sourceData : [],
              currentChartLevel: currentChartLevel,
              currentPriorityLevel: currentPriorityLevel,
              currentSource: optionalData,
              currentSourceName: title
            });
            this.getCustomBreadCrum(3, currentPriorityLevel);
          }
        });
      }
    }
  }

  getClients() {
    const dataObj = this.props.clientReducer.data ? this.props.clientReducer.data : [];
    const clientData = [];
    const result = Object.values(dataObj);
    if (result.length > 0) {
      result.forEach((data) => {
        if (data.clientId && data.queueId === this.state.currentPriorityLevel) {
          clientData.push({ value: data.clientId, id: data.clientId, text: data.clientName });
        }
      });
    }
    let selectValue = this.state.currentClient;
    let defaultValue = this.state.currentClient;
    if (this.state.currentClient) {
      defaultValue = this.state.currentClient;
    } else if (clientData.length > 0 && clientData[0]) {
      defaultValue = clientData[0].id;
      selectValue = clientData[0].id;
    }
    return (
      <div style={{ display: 'flex', width: '100%' }}>
        <div className="content-queue-text" style={{ paddingTop: '10px' }}>Select Client</div>
        <MozartSelectField
          data={clientData}
          onChange={(data) => { this.handleClient(data); }}
          width="250px"
          key={this.state.currentClient}
          value={selectValue}
          defaultValue={defaultValue}
        />
      </div>
    );
  }

  getSource() {
    const dataObj = this.props.sourceReducer.data ? this.props.sourceReducer.data : [];
    const result = Object.values(dataObj);
    const sourceData = [];
    if (result.length > 0) {
      result.forEach((data) => {
        let sourceName = data.name;
        if (data.id === 100000) {
          sourceName = 'All Source';
        }
        sourceData.push({ value: data.id, id: data.id, text: sourceName });
      });
    }
    return (
      <div style={{ display: 'flex', width: '100%' }}>
        <div className="content-queue-text" style={{ paddingTop: '10px' }}>Select Source</div>
        <MozartSelectField
          data={sourceData}
          onChange={(data) => { this.handleSource(data); }}
          width="250px"
          key={this.state.currentSource}
          value={sourceData && sourceData[0] ? sourceData[0].id : null}
          defaultValue={this.state.currentSource ? this.state.currentSource : null}
        />
      </div>
    );
  }

  getPriorityCharts() {
    return (
      <Row className="priority-chart-block">
        <Col xs={4} md={4} onClick={() => { this.getNextLevelChart(2, 1); }}>
          <div className="priority-chart">
            <div className="chart-header"><span className="title">High Priority</span></div>
            {this.getBarChart('HIGH')}
          </div>
        </Col>
        <Col xs={4} md={4} onClick={() => { this.getNextLevelChart(2, 2); }}>
          <div className="priority-chart">
            <div className="chart-header"><span className="title">Medium Priority</span></div>
            {this.getBarChart('MEDIUM')}
          </div>
        </Col>
        <Col xs={4} md={4} onClick={() => { this.getNextLevelChart(2, 3); }}>
          <div className="priority-chart">
            <div className="chart-header"><span className="title">NORMAL Priority</span></div>
            {this.getBarChart('NORMAL')}
          </div>
        </Col>
      </Row>
    );
  }

  getTitle(priorityLevel) {
    let priorityLevelTitle = 'HIGH';
    if (priorityLevel === 1) {
      priorityLevelTitle = 'HIGH';
    }
    if (priorityLevel === 2) {
      priorityLevelTitle = 'MEDIUM';
    }
    if (priorityLevel === 3) {
      priorityLevelTitle = 'NORMAL';
    }
    return priorityLevelTitle;
  }

  getClientPriorityCharts() {
    let title = this.getTitle(this.state.currentPriorityLevel) + ' Priority';
    if (this.state.currentClient) {
      title = this.state.currentClient;
    }
    return (
      <Row className="priority-chart-block priority-based-on-client">
        <Col xs={12} md={12} onClick={() => { this.getNextLevelChart(3, this.state.currentPriorityLevel); }}>
          <div className="priority-chart">
            <div className="chart-header">
              <span className="title">
                {title}
              </span>
            </div>
            {this.getBarChart(this.state.currentPriorityLevel)}
          </div>
        </Col>
      </Row>
    );
  }

  getSourcePriorityCharts() {
    let title = this.getTitle(this.state.currentPriorityLevel) + ' Priority';
    if (this.state.currentSourceName) {
      title = this.state.currentSourceName;
    }
    return (
      <Row className="priority-chart-block priority-based-on-source">
        <Col xs={12} md={12}>
          <div className="priority-chart">
            <div className="chart-header">
              <span className="title">
                {title}
              </span>
            </div>
            {this.getBarChart(this.state.currentPriorityLevel)}
          </div>
        </Col>
      </Row>
    );
  }

  getHeaderItems() {
    const { breadcrumsDashboard } = this.props.breadcrumReducer;
    let title = this.state.currentHeaderTitle;
    const breadcrumsDashboardObj = this.props.breadcrumReducer.breadcrumsDashboard;
    if (breadcrumsDashboardObj && breadcrumsDashboardObj.length) {
      const lastItesm = breadcrumsDashboardObj[breadcrumsDashboardObj.length - 1];
      title = lastItesm.name;
    }
    return (
      <Row className="menu-header-block">
        <Col xs="6" md="6" className="menu-header-title-block">
          <div>{title}</div>
          <div className="breadcrumbs" style={{ width: '100%' }}>
            <MozartBreadCrum data={breadcrumsDashboard} onClick={(data) => { this.onBreadcrumpOnClick(data); }} />
          </div>
        </Col>
        <Col xs="6" md="6" className="menu-header-button-block">
          <div className="action-btn-queue-config">
            <MozartButton
              background
              data="Queue Configuration"
              onClick={() => this.goToConfigPage()}
            />
          </div>
          <div className="action-btn-queue-assignment">
            <MozartButton
              background
              data="Queue Assignment"
              onClick={() => this.goToPage()}
            />
          </div>
        </Col>
      </Row>
    );
  }

  getAllCharts() {
    return (
      <div>
        {this.state.currentChartLevel === 1 && this.getPriorityCharts()}
        {this.state.currentChartLevel === 2 && this.getClientPriorityCharts()}
        {this.state.currentChartLevel === 3 && this.getSourcePriorityCharts()}
      </div>
    );
  }

  getPriorityTable() {
    return (
      <PriorityTable handlePriorityTableFromChild={(postData) => { this.handlePriorityTableFromChild(postData); }} currentChartLevel={this.state.currentChartLevel} currentPriorityLevel={this.state.currentPriorityLevel} currentClient={this.state.currentClient} currentSource={this.state.currentSource} />
    );
  }

  getChartDashboard() {
    return (
      <div key={Math.random()}>
        <Row>{this.getHeaderItems()}</Row>
        <div style={{ display: 'flex' }}>
          <div style={{
            width: '45%',
            paddingTop: '10px',
            paddingLeft: '20px',
            display: this.state.currentChartLevel === 2 || this.state.currentChartLevel === 3 ? 'block' : 'none'
          }}
          >
            {this.state.currentChartLevel === 2 && this.getClients()}
            {this.state.currentChartLevel === 3 && this.getSource()}
          </div>
          <PriorityChartLegend />
        </div>
        {this.getAllCharts()}
        {this.getPriorityTable()}
      </div>
    );
  }

  handleClient(data) {
    this.props.loadSource(data).then(() => {
      const dataObj = this.props.sourceReducer.data ? this.props.sourceReducer.data : [];
      const result = Object.values(dataObj);
      this.setState({
        currentClient: data,
        currentSource: result[0] ? result[0].id : null,
        currentSourceName: result[0].name
      }, () => {
        this.getNextLevelChart(this.state.currentChartLevel, this.state.currentPriorityLevel, result[0].id);
        this.getPriorityTableData();
      });
    });
  }

  handleSource(data) {
    const dataObj = this.props.sourceReducer.data ? this.props.sourceReducer.data : [];
    const result = Object.values(dataObj);
    if (result.length > 0) {
      result.forEach((dataItem) => {
        if (dataItem.id === data) {
          this.setState({
            currentSource: dataItem.id,
            currentSourceName: dataItem.id === 100000 ? 'All Source' : dataItem.name
          }, () => {
            this.getNextLevelChart(this.state.currentChartLevel, this.state.currentPriorityLevel);
            this.getPriorityTableData();
          });
        }
      });
    }
  }

  handlePriorityTableFromChild(postData) {
    let currentClient = this.state.currentClient ? this.state.currentClient : null;
    if (this.props.clientReducer.data) {
      const result = Object.values(this.props.clientReducer.data);
      const clientDataDefault = [];
      if (result.length > 0) {
        result.forEach((data) => {
          if (data.clientId && data.queueId === postData.priorityId) {
            clientDataDefault.push({ value: data.clientId, id: data.clientId, text: data.clientName });
          }
        });
      }
      currentClient = clientDataDefault.length > 0 ? clientDataDefault[0].id : this.state.currentClient;
    }
    if (postData.priorityId > 0 && postData.priorityId !== this.state.currentPriorityLevel) {
      let currentPriorityLevel = postData.priorityId;
      if (postData.priorityId === 'HIGH') {
        currentPriorityLevel = 1;
      }
      if (postData.priorityId === 'MEDIUM') {
        currentPriorityLevel = 2;
      }
      if (postData.priorityId === 'NORMAL') {
        currentPriorityLevel = 3;
      }
      this.setState({
        currentPriorityLevel: currentPriorityLevel,
        currentChartLevel: 2,
        currentClient: currentClient
      }, () => {
        // this.getNextLevelChart(this.state.currentChartLevel, this.state.currentPriorityLevel);
        this.getPriorityTableData();
        this.getCustomBreadCrum(this.state.currentChartLevel, currentPriorityLevel);
        postData.priorityId = this.state.currentPriorityLevel;
      });
    }
    this.props.loadPriorityTable(postData);
  }

  goToPage() {
    window.location.href = '/#/me/queueAssignment';
  }

  goToConfigPage() {
    window.location.href = '/#/me/queueConfigure';
  }

  render() {
    return (
      <div>
        {this.getChartDashboard()}
      </div>
    );
  }
}

PriorityChartDashboard.propTypes = {
  priorityReducer: PropTypes.object,
  clientReducer: PropTypes.object,
  sourceReducer: PropTypes.object,
  meRolesReducer: PropTypes.object,
  loadBreadCrumsDashboard: PropTypes.func,
  loadPriorityTable: PropTypes.func,
  loadClientPriority: PropTypes.func,
  loadSourcePriority: PropTypes.func,
  loadSource: PropTypes.func,
  loadPriority: PropTypes.func,
  loadRoles: PropTypes.func,
  breadcrumReducer: PropTypes.object
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    loadPriority,
    loadClientPriority,
    loadSourcePriority,
    loadBreadCrumsDashboard,
    loadPriorityTable,
    loadSource,
    loadRoles
  }, dispatch)
);

const mapStateToProps = state => ({
  priorityReducer: state.priorityReducer,
  breadcrumReducer: state.breadcrumReducer,
  clientReducer: state.clientReducer,
  sourceReducer: state.sourceReducer,
  meRolesReducer: state.meRolesReducer
});

export default connect(mapStateToProps, mapDispatchToProps)(PriorityChartDashboard);
