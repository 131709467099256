import {
  LOAD_APPROVAL,
  LOAD_APPROVAL_SUCCESS,
  LOAD_APPROVAL_FAIL,
  LOAD_NEXT,
  LOAD_NEXT_SUCCESS,
  LOAD_NEXT_FAIL
} from 'redux/actions/approvalAction';

const initialState = {
  loadedApproval: false,
  loadingApproval: false,
  approval: [],
  approveAndNext: [],
};

export default function approvalReducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_APPROVAL:
      return {
        ...state,
        loadingApproval: true,
        loadedApproval: false
      };
    case LOAD_APPROVAL_SUCCESS:
      const currApproval = state.approval ? { ...state.approval, ...action.payload.data } : action.payload.data;
      return {
        ...state,
        loadingApproval: false,
        loadedApproval: true,
        approval: currApproval
      };
    case LOAD_APPROVAL_FAIL:
      return {
        ...state,
        loadingApproval: false,
        loadedApproval: false,
        error: action.error
      };
    default:
      return state;
  }
}

export function approveAndNextReducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_NEXT:
      return {
        ...state,
        loadingApproval: true,
        loadedApproval: false
      };
    case LOAD_NEXT_SUCCESS:
      const currApprovalN = state.approval ? { ...state.approval, ...action.payload.data } : action.payload.data;
      return {
        ...state,
        loadingApproval: false,
        loadedApproval: true,
        approveAndNext: currApprovalN
      };
    case LOAD_NEXT_FAIL:
      return {
        ...state,
        loadingApproval: false,
        loadedApproval: false,
        error: action.error
      };
    default:
      return state;
  }
}
