import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';
import { Header, MonitorPage } from 'components';
import { loadBreadCrums } from 'redux/actions/breadcrumAction';
import './ReportMonitor.scss';

function ReportMonitor(props) {
  const { match } = props;

  useEffect(() => {
    props.loadBreadCrums(getBreadCrums());
  }, [match.params]);

  const getBreadCrums = () => {
    const items = [];
    items.push({
      id: 1,
      name: 'Home',
      href: process.env.REACT_APP_TOOLS_SOCIAL_URL,
      absolutePath: true
    });
    if (match.params && match.params.page) {
      items.push({ id: 2, name: 'Report Monitor', href: 'report/watch' });
      let currentPage;
      pages().forEach(element => {
        if (element.id === match.params.page) {
          currentPage = element;
        }
      });
      if (currentPage) items.push({ id: 3, name: currentPage.title, href: 'report/watch/' + currentPage.id });
    } else {
      items.push({ id: 2, name: 'Report Monitor', href: 'report/watch' });
    }
    return items;
  };

  const pages = () => [
    {
      id: 'status',
      title: 'Status',
      icon: 'icon-Check'
    },
    {
      id: 'analysis',
      title: 'Analysis',
      icon: 'icon-bha'
    },
    {
      id: 'action',
      title: 'Action',
      icon: 'icon-Email'
    }
  ];
  const getPages = () => {
    const response = [];
    pages().forEach(element => {
      response.push(
        <Col key={Math.random()} xs="4" md="4">
          <div className="ReportMonitoringItems" onClick={() => { window.location.href = '/#/report/watch/' + element.id; }}>
            <span className={element.icon + ' PageIcon'} />
            <div className="title">{element.title}</div>
          </div>
        </Col>
      );
    });
    return <Row>{response}</Row>;
  };
  return (
    <div>
      <Helmet><title>Report Monitor | Binary Fountain</title></Helmet>
      <Header hasBreadcrum />
      <Container fluid>
        {!match.params.page && <div className="ReportMonitorContainer">{getPages()}</div>}
        {match.params.page && <div className="ReportMonitorPage">{<MonitorPage page={match.params.page} />}</div>}
      </Container>
    </div>
  );
}

ReportMonitor.propTypes = {
  loadBreadCrums: PropTypes.func,
  match: PropTypes.object
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    loadBreadCrums
  }, dispatch)
);

const mapStateToProps = state => ({
  user: state.authReducer.user
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportMonitor);
