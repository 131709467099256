export const LOAD = 'binary/report/LOAD_STATUS';
export const LOAD_STATUS_SUCCESS = 'binary/report/LOAD_STATUS_SUCCESS';
export const LOAD_STATISTICS_SUCCESS = 'binary/report/LOAD_STATISTICS_SUCCESS';
export const REPUSH_HISTORY_SUCCESS = 'binary/report/REPUSH_HISTORY_SUCCESS';
export const DELETE_HISTORY_SUCCESS = 'binary/report/DELETE_HISTORY_SUCCESS';
export const LOAD_FAIL = 'binary/report/LOAD_STATISTICS_FAIL';

export function loadStatus(filter) {
  const data = {};
  data.offset = (filter && filter.offset) ? filter.offset : 0;
  data.limit = (filter && filter.limit) ? filter.limit : 1000;
  if (filter && filter.status && filter.status.length > 0) data.status = filter.status;
  if (filter && filter.clients && filter.clients.length > 0) data.clientIds = filter.clients;
  if (filter && filter.users && filter.users.length > 0) data.userIds = filter.users;
  if (filter && filter.reports && filter.reports.length > 0) data.reportIds = filter.reports;
  if (filter && filter.dateRange) data.dateRange = filter.dateRange;
  return {
    types: [LOAD, LOAD_STATUS_SUCCESS, LOAD_FAIL],
    payload: {
      client: 'reportsapi',
      request: {
        method: 'post',
        url: 'api/schedule-monitor/status',
        data: data
      }
    }
  };
}

export function loadStatistics(filter) {
  const data = { offset: 0, limit: 1000 };
  if (filter && filter.clients && filter.clients.length > 0) data.clientIds = filter.clients;
  if (filter && filter.reports && filter.reports.length > 0) data.reportIds = filter.reports;
  if (filter && filter.dateRange) data.dateRange = filter.dateRange;
  return {
    types: [LOAD, LOAD_STATISTICS_SUCCESS, LOAD_FAIL],
    payload: {
      client: 'reportsapi',
      request: {
        method: 'post',
        url: 'api/schedule-monitor/statistics',
        data: data
      }
    }
  };
}

export function repushHistory(historyIds) {
  return {
    types: [LOAD, REPUSH_HISTORY_SUCCESS, LOAD_FAIL],
    payload: {
      client: 'reportsapi',
      request: {
        method: 'post',
        url: 'api/schedule-monitor/history/repush',
        data: {
          historyIds: historyIds
        }
      }
    }
  };
}

export function deleteHistory(historyIds) {
  return {
    types: [LOAD, DELETE_HISTORY_SUCCESS, LOAD_FAIL],
    payload: {
      client: 'reportsapi',
      request: {
        method: 'delete',
        url: 'api/schedule-monitor/history/deactivate',
        data: {
          historyIds: historyIds
        }
      }
    }
  };
}
