export const LOAD = 'binary/priority/LOAD';
export const LOAD_SUCCESS = 'binary/priority/LOAD_SUCCESS';
export const LOAD_FAIL = 'binary/priority/LOAD_FAIL';
export const LOAD_CLIENT_PRIORITY = 'binary/priority/LOAD_CLIENT_PRIORITY';
export const LOAD_CLIENT_PRIORITY_SUCCESS = 'binary/priority/LOAD_CLIENT_PRIORITY_SUCCESS';
export const LOAD_CLIENT_PRIORITY_FAIL = 'binary/priority/LOAD_CLIENT_PRIORITY_FAIL';
export const LOAD_SOURCE_PRIORITY = 'binary/priority/LOAD_SOURCE_PRIORITY';
export const LOAD_SOURCE_PRIORITY_SUCCESS = 'binary/priority/LOAD_SOURCE_PRIORITY_SUCCESS';
export const LOAD_SOURCE_PRIORITY_FAIL = 'binary/priority/LOAD_SOURCE_PRIORITY_FAIL';

export function loadPriority() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],

    payload: {
      client: 'managementengagement',
      request: {
        method: 'post',
        data: '',
        url: '/api/engagements/queue/taskstatus',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    }
  };
}

export function loadClientPriority(postData) {
  return {
    types: [LOAD_CLIENT_PRIORITY, LOAD_CLIENT_PRIORITY_SUCCESS, LOAD_CLIENT_PRIORITY_FAIL],

    payload: {
      client: 'managementengagement',
      request: {
        method: 'post',
        data: postData,
        url: '/api/engagements/queue/taskstatus',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    }
  };
}

export function loadSourcePriority(postData) {
  return {
    types: [LOAD_SOURCE_PRIORITY, LOAD_SOURCE_PRIORITY_SUCCESS, LOAD_SOURCE_PRIORITY_FAIL],

    payload: {
      client: 'managementengagement',
      request: {
        method: 'post',
        data: postData,
        url: '/api/engagements/queue/taskstatus',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    }
  };
}
