export const LOAD = 'binary/auth/LOAD';
export const LOAD_SUCCESS = 'binary/auth/LOAD_SUCCESS';
export const LOAD_FAIL = 'binary/auth/LOAD_FAIL';
export const LOAD_PERMISSION = 'binary/auth/LOAD_PERMISSION';
export const LOAD_PERMISSION_SUCCESS = 'binary/auth/LOAD_PERMISSION_SUCCESS';
export const LOAD_PERMISSION_FAIL = 'binary/auth/LOAD_PERMISSION_FAIL';

export function load() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    payload: {
      request: {
        method: 'get',
        url: '/api/accounts/me?includeUserdetails=true'
      }
    }
  };
}

export function loadPermission(email) {
  return {
    types: [LOAD_PERMISSION, LOAD_PERMISSION_SUCCESS, LOAD_PERMISSION_FAIL],
    payload: {
      client: 'nextgen',
      request: {
        method: 'get',
        url: '/api/users/' + email + '/access'
      }
    }
  };
}
