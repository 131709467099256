import React, { useState } from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { deleteSentiment } from 'redux/actions/SentimentAction';
import {
  MozartModal, MozartCheckBox
} from '@pgforsta/mozart_ui';

function DeleteSentimentModal(props) {
  const { currentInsightId, documentReducer, openDeleteSentimentModal } = props;
  let [deleteSentimentReasonList, setDeleteSentimentReasonList] = [];
  [deleteSentimentReasonList, setDeleteSentimentReasonList] = useState([]);
  const content = [];
  const deleteSentimentReasonCheck = [
    {
      id: 'feature',
      name: 'Feature',
      value: 'feature',
      checked: false
    },
    {
      id: 'opinion',
      name: 'Opinion',
      value: 'opinion',
      checked: false
    },
    {
      id: 'category',
      name: 'Category',
      value: 'category',
      checked: false
    },
    {
      id: 'subcategory',
      name: 'Subcategory',
      value: 'subcategory',
      checked: false
    },
    {
      id: 'sentiment',
      name: 'Sentiment',
      value: 'sentiment',
      checked: false
    },
    {
      id: 'duplicate',
      name: 'Duplicate',
      value: 'duplicate',
      checked: false
    },
    {
      id: 'onClientRequest',
      name: 'Delete on client request',
      value: 'onClientRequest',
      checked: false
    }
  ];
  deleteSentimentReasonCheck.forEach((refer) => {
    let selectedVal = false;
    deleteSentimentReasonList.forEach((selectedItem) => {
      if (selectedItem.value === refer.value) {
        selectedVal = true;
      }
    });
    content.push({
      name: refer.name,
      value: refer.id,
      checked: deleteSentimentReasonList && selectedVal ? selectedVal : false,
      disabled: false
    });
  });
  let enableClass = 'add-button-disable';
  if (deleteSentimentReasonList && deleteSentimentReasonList.length > 0) {
    enableClass = 'add-button-enable';
  }

  const showHideDeleteSentimentModal = () => {
    props.showHideDeleteSentimentModal();
  };

  const handleDeleteSentimentById = () => {
    const insightId = currentInsightId;
    if (insightId && insightId > 0) {
      const reasonList = [];
      if (deleteSentimentReasonList) {
        deleteSentimentReasonList.forEach((reason) => {
          if (reason.checked) {
            reasonList.push(reason.value);
          }
        });
      }
      const reasonDataObj = {
        reason: reasonList
      };
      if (!documentReducer.loading) {
        props.deleteSentiment(insightId, reasonDataObj).then((response) => {
          if (response && response.payload.status && response.payload.status === 204) {
            deleteSentimentReasonList = [];
            setDeleteSentimentReasonList(deleteSentimentReasonList);
            props.handleDeleteSentimentById();
          }
        });
      }
    }
  };

  const handleDeleteSentimentReason = (reasonList) => {
    if (reasonList) {
      deleteSentimentReasonList = [];
      reasonList.forEach((itemSel) => {
        deleteSentimentReasonList.push({
          value: itemSel.value,
          checked: true
        });
      });
      setDeleteSentimentReasonList(deleteSentimentReasonList);
    }
  };

  return (
    <div>
      <MozartModal
        show={openDeleteSentimentModal}
        onClose={() => { showHideDeleteSentimentModal(); }}
        title="Select Reason"
        width="975px"
        contentPadding="20px"
        className="review-sentence-modal"
        id={openDeleteSentimentModal}
        key={openDeleteSentimentModal}
        submitLabel="Submit"
        handleSubmit={() => { handleDeleteSentimentById(); }}
        submitButtonClassName={enableClass}
        enableCancel
      >
        <p style={{ color: '#403788' }}>Which parts of insight are wrong?</p>
        <MozartCheckBox
          onChange={(data) => { handleDeleteSentimentReason(data); }}
          id="deleteCheckbox"
          content={content}
          key={Math.random()}
        />
      </MozartModal>
    </div>
  );
}

DeleteSentimentModal.propTypes = {
  openDeleteSentimentModal: propTypes.bool,
  currentInsightId: propTypes.number,
  documentReducer: propTypes.object,
  deleteSentiment: propTypes.func,
  handleDeleteSentimentById: propTypes.func,
  showHideDeleteSentimentModal: propTypes.func
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    deleteSentiment
  }, dispatch)
);

const mapStateToProps = state => ({
  documentReducer: state.documentReducer
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteSentimentModal);
