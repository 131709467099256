export const LOAD_RESPONDER = 'binary/responder/LOAD_RESPONDER';
export const LOAD_RESPONDER_SUCCESS = 'binary/responder/LOAD_RESPONDER_SUCCESS';
export const LOAD_RESPONDER_FAIL = 'binary/responder/LOAD_RESPONDER_FAIL';

export const LOAD_SEND = 'binary/responder/LOAD_SEND';
export const LOAD_SEND_SUCCESS = 'binary/responder/LOAD_SEND_SUCCESS';
export const LOAD_SEND_FAIL = 'binary/responder/LOAD_SEND_FAIL';

export const LOAD_FORWARD = 'binary/responder/LOAD_FORWARD';
export const LOAD_FORWARD_SUCCESS = 'binary/responder/LOAD_FORWARD_SUCCESS';
export const LOAD_FORWARD_FAIL = 'binary/responder/LOAD_FORWARD_FAIL';

export const LOAD_TEMPLATE = 'binary/responder/LOAD_TEMPLATE';
export const LOAD_TEMPLATE_SUCCESS = 'binary/responder/LOAD_TEMPLATE_SUCCESS';
export const LOAD_TEMPLATE_FAIL = 'binary/responder/LOAD_TEMPLATE_FAIL';

export const LOAD_ENABLED = 'binary/responder/LOAD_ENABLED';
export const LOAD_ENABLED_SUCCESS = 'binary/responder/LOAD_ENABLED_SUCCESS';
export const LOAD_ENABLED_FAIL = 'binary/responder/LOAD_ENABLED_FAIL';


export const LOAD_RESPONSE = 'binary/responder/LOAD_RESPONSE';
export const LOAD_RESPONSE_SUCCESS = 'binary/responder/LOAD_RESPONSE_SUCCESS';
export const LOAD_RESPONSE_FAIL = 'binary/responder/LOAD_RESPONSE_FAIL';

export function loadResponder(id, role) {
  return {
    types: [LOAD_RESPONDER, LOAD_RESPONDER_SUCCESS, LOAD_RESPONDER_FAIL],
    payload: {
      client: 'managementengagement',
      request: {
        method: 'get',
        url: '/api/tasks/' + id + '?userRole=' + role
      }
    }
  };
}

export function sendToSupport(id, data) {
  return {
    types: [LOAD_SEND, LOAD_SEND_SUCCESS, LOAD_SEND_FAIL],
    payload: {
      client: 'managementengagement',
      request: {
        method: 'post',
        url: '/api/tasks/' + id + '/support',
        data: data
      }
    }
  };
}

export function forwardAndNext(id, data) {
  return {
    types: [LOAD_FORWARD, LOAD_FORWARD_SUCCESS, LOAD_FORWARD_FAIL],
    payload: {
      client: 'managementengagement',
      request: {
        method: 'post',
        url: '/api/tasks/' + id + '/submit',
        data: data
      }
    }
  };
}

export function loadTemplate(id) {
  return {
    types: [LOAD_TEMPLATE, LOAD_TEMPLATE_SUCCESS, LOAD_TEMPLATE_FAIL],
    payload: {
      client: 'managementengagement',
      request: {
        method: 'get',
        url: '/api/tasks/' + id + '/templates'
      }
    }
  };
}

export function loadClientConfiguration(id) {
  return {
    types: [LOAD_ENABLED, LOAD_ENABLED_SUCCESS, LOAD_ENABLED_FAIL],
    payload: {
      client: 'nextgen',
      request: {
        method: 'get',
        url: '/api/clients/' + id
      }
    }
  };
}

export function loadAiResponse(id, reviewId) {
  return {
    types: [LOAD_RESPONSE, LOAD_RESPONSE_SUCCESS, LOAD_RESPONSE_FAIL],
    payload: {
      client: 'ctlapi',
      request: {
        method: 'get',
        url: '/api/answer-assist/' + id + '/content/'+ reviewId
      }
    }
  };
}