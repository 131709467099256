import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { loadPriority, loadClientPriority, loadSourcePriority } from 'redux/actions/priorityAction';
import { loadBreadCrumsDashboard } from 'redux/actions/breadcrumAction';
import { loadSource } from 'redux/actions/sourceAction';
import { loadPriorityTable } from 'redux/actions/priorityTableAction';
import { loadRoles } from 'redux/actions/meRolesAction';
import { loadClients } from 'redux/actions/clientAction';
import PropTypes from 'prop-types';
import './ManagerDashboard.scss';
import 'react-dates/initialize';
import moment from 'moment';
import {
  PriorityChartLegend,
} from 'components';
import { MozartBreadCrum, MozartButton, MozartSelectField } from '@pgforsta/mozart_ui';
import {
  BarChart, Bar, YAxis, CartesianGrid, Tooltip, LabelList, Cell, ResponsiveContainer
} from 'recharts';
import PriorityTable from 'components/PriorityTable/PriorityTable';

class ManagerDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentChartLevel: 1,
      currentPriorityLevel: 0,
      priorityData: [],
      currentSource: null,
      currentClient: null,
      currentHeaderTitle: 'Queue Status Dashboard',
      levelReset: false
    };
    this.startDate = moment().subtract(30, 'days').format('YYYYMMDD');
    this.endDate = moment(new Date()).tz('America/Chicago').format('YYYYMMDD');
  }

  componentDidMount() {
    this.props.loadPriority().then(() => {
      if (this.props.priorityReducer && this.props.priorityReducer.data) {
        this.setState({
          priorityData: this.props.priorityReducer ? this.props.priorityReducer.data : [],
        });
      }
      this.getPriorityTableData();
      this.props.loadRoles();
    });
  }

  onBreadcrumpOnClick(data) {
    if (data.id) {
      this.getCustomBreadCrum(data.id, data.value);
      this.setState({
        currentChartLevel: data.id,
        currentHeaderTitle: data.name
      }, () => {
        if (data.id === 2) {
          this.chartDrillDownClientChart(data.id, this.state.currentPriorityLevel, this.state.currentClient, this.state.currentSource);
        }
        if (data.id === 3) {
          this.chartDrillDownSource(data.id, this.state.currentPriorityLevel, this.state.currentClient, this.state.currentSource);
        }
        if (data.id === 1) {
          this.props.loadPriority();
          this.getPriorityTableData('levelReset');
        }
      });
    }
  }

  getPriorityTableData(resetType) {
    const postData = {
      roleIds: [this.props.meRolesReducer && this.props.meRolesReducer.data && this.props.meRolesReducer.data[2] ? this.props.meRolesReducer.data[2].roleId : 471],
      fromDate: this.state.currentFromDate ? this.state.currentFromDate : this.startDate,
      toDate: this.state.currentToDate ? this.state.currentToDate : this.endDate,
      offset: 0,
      limit: 20,
      priorityId: 1,
      user: this.state.currentUser
    };
    if (this.state.currentPriorityLevel > 0) {
      postData.priorityId = this.state.currentPriorityLevel;
    }
    if (this.state.currentChartLevel > 1) {
      postData.clientId = this.state.currentClient;
    }
    if (this.state.currentChartLevel > 2) {
      if (this.state.currentSource && this.state.currentSource !== 100000) {
        postData.sourceId = this.state.currentSource;
      }
    }
    let levelResetStatus = this.state.levelReset;
    if (resetType === 'levelReset') {
      postData.priorityId = 1;
      levelResetStatus = true;
    }
    this.props.loadPriorityTable(postData).then(() => {
      if (resetType === 'levelReset') {
        this.setState({
          currentPriorityLevel: postData.priorityId,
          currentClient: null,
          currentSource: null
        });
      } else {
        this.setState({
          currentPriorityLevel: postData.priorityId,
          levelReset: levelResetStatus
        });
      }
    });
  }

  getCustomBreadCrum(currentChartLevel, currentPriorityLevel) {
    this.props.loadBreadCrumsDashboard(this.getBreadCrums(currentChartLevel, currentPriorityLevel));
  }

  getBreadCrums(currentChartLevel, currentPriorityLevel) {
    const breadCrumArray = [];
    if (currentChartLevel === 2) {
      breadCrumArray.push({ id: 1, name: 'Queue Status Dashboard', value: currentPriorityLevel });
      breadCrumArray.push({ id: 2, name: this.getTitle(currentPriorityLevel), value: currentPriorityLevel });
    }
    if (currentChartLevel === 3) {
      breadCrumArray.push({ id: 1, name: 'Queue Status Dashboard', value: currentPriorityLevel });
      breadCrumArray.push({ id: 2, name: this.getTitle(currentPriorityLevel), value: currentPriorityLevel });
      breadCrumArray.push({ id: 3, name: this.state.currentClient, value: currentPriorityLevel });
    }
    return breadCrumArray;
  }

  getHeaderItems() {
    const { breadcrumsDashboard } = this.props.breadcrumReducer;
    let title = this.state.currentHeaderTitle;
    const breadcrumsDashboardObj = this.props.breadcrumReducer.breadcrumsDashboard;
    if (breadcrumsDashboardObj && breadcrumsDashboardObj.length) {
      const lastItesm = breadcrumsDashboardObj[breadcrumsDashboardObj.length - 1];
      title = lastItesm.name;
    }
    return (
      <Row className="menu-header-block">
        <Col xs="6" md="6" className="menu-header-title-block">
          <div>{title}</div>
          <div className="breadcrumbs" style={{ width: '100%' }}>
            <MozartBreadCrum data={breadcrumsDashboard} onClick={(data) => { this.onBreadcrumpOnClick(data); }} />
          </div>
        </Col>
        <Col xs="6" md="6" className="menu-header-button-block">
          <div className="action-btn-queue-config">
            <MozartButton
              background
              data="Queue Configuration"
              onClick={() => this.goToConfigPage()}
            />
          </div>
          <div className="action-btn-queue-assignment">
            <MozartButton
              background
              data="Queue Assignment"
              onClick={() => this.goToPage()}
            />
          </div>
          <div className="action-btn-queue-assignment">
            <MozartButton
              background
              data="Support Request"
              onClick={() => this.goToSupportRequestPage()}
            />
          </div>
        </Col>
      </Row>
    );
  }

  getClientObj(queueLevel) {
    let dataObj = this.props.clientReducer ? this.props.clientReducer.data : [];
    const clientData = [];
    if (this.props.clientReducer.highLevelClients && queueLevel === 1) {
      dataObj = this.props.clientReducer ? this.props.clientReducer.highLevelClients : [];
    }
    if (this.props.clientReducer.mediumLevelClients && queueLevel === 2) {
      dataObj = this.props.clientReducer ? this.props.clientReducer.mediumLevelClients : [];
    }
    if (this.props.clientReducer.normalLevelClients && queueLevel === 3) {
      dataObj = this.props.clientReducer ? this.props.clientReducer.normalLevelClients : [];
    }
    const result = Object.values(dataObj);
    if (result.length > 0) {
      result.forEach((data) => {
        if (data.clientId) {
          clientData.push({
            value: data.clientId,
            id: data.clientId,
            text: data.clientName,
            level: data.queueId
          });
        }
      });
    }
    return clientData;
  }

  getClientFilter() {
    let dataObj = this.props.clientReducer ? this.props.clientReducer.data : [];
    const clientData = [];
    if (this.props.clientReducer.highLevelClients && this.state.currentPriorityLevel === 1) {
      dataObj = this.props.clientReducer ? this.props.clientReducer.highLevelClients : [];
    }
    if (this.props.clientReducer.mediumLevelClients && this.state.currentPriorityLevel === 2) {
      dataObj = this.props.clientReducer ? this.props.clientReducer.mediumLevelClients : [];
    }
    if (this.props.clientReducer.normalLevelClients && this.state.currentPriorityLevel === 3) {
      dataObj = this.props.clientReducer ? this.props.clientReducer.normalLevelClients : [];
    }
    const result = Object.values(dataObj);
    if (result.length > 0) {
      result.forEach((data) => {
        if (data.clientId) {
          clientData.push({
            value: data.clientId,
            id: data.clientId,
            text: data.clientName,
            level: data.queueId
          });
        }
      });
    }
    return (
      <div style={{ display: 'flex', width: '100%' }}>
        <div className="content-queue-text" style={{ paddingTop: '10px' }}>Select Client</div>
        <MozartSelectField
          data={clientData}
          onChange={(data) => { this.handleClient(data); }}
          width="175px"
          defaultValue={this.state.currentClient}
          value={result && result[0] ? result[0].clientId : null}
          // defaultValue={result[0].id}
          hintText="Select Client"
        />
      </div>
    );
  }

  getSourceFilter() {
    const dataObj = this.props.sourceReducer.data ? this.props.sourceReducer.data : [];
    const result = Object.values(dataObj);
    const sourceData = [];
    if (result.length > 0) {
      result.forEach((data) => {
        let sourceName = data.name;
        if (data.id === 100000) {
          sourceName = 'All Source';
        }
        sourceData.push({ value: data.id, id: data.id, text: sourceName });
      });
    }
    return (
      <div style={{ display: 'flex', width: '100%' }}>
        <div className="content-queue-text" style={{ paddingTop: '10px' }}>Select Source</div>
        <MozartSelectField
          data={sourceData}
          onChange={(data) => { this.handleSource(data); }}
          width="175px"
          defaultValue={this.state.currentSource}
          value={sourceData && sourceData[0] ? sourceData[0].id : null}
        />
      </div>
    );
  }

  getBarCustomTooltip({ payload, label, active }) {
    if (active) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${label} : ${payload[0].value}`}</p>
          <p className="desc">Anything you want can be displayed here.</p>
        </div>
      );
    }
    return null;
  }

  getBarChart(priorityType) {
    const chartData = [];
    let renderCustomBarLabel = '';
    let getBarCustomTooltip = '';
    let priorutyDataObj = this.state.priorityData;
    if (this.state.currentChartLevel === 2) {
      priorutyDataObj = this.state.priorityClientData;
    }
    if (this.state.currentChartLevel === 3) {
      priorutyDataObj = this.state.prioritySourceData;
    }
    if (priorityType === 1) {
      priorityType = 'HIGH';
    }
    if (priorityType === 2) {
      priorityType = 'MEDIUM';
    }
    if (priorityType === 3) {
      priorityType = 'NORMAL';
    }
    if (priorutyDataObj) {
      let chartDataObj = '';
      Object.keys(priorutyDataObj).forEach(key => {
        if (priorutyDataObj[key].queueName === priorityType) {
          chartDataObj = priorutyDataObj[key];
        }
      });
      chartData.push({ name: 'queueSize', count: chartDataObj.queueSize > 0 ? chartDataObj.queueSize : 0, fillColor: '#403788' });
      chartData.push({ name: 'numberOfTasksInProgressCount', count: chartDataObj.numberOfTasksInProgressCount ? chartDataObj.numberOfTasksInProgressCount : 0, fillColor: '#715AFF' });
      chartData.push({ name: 'numberOfTasksPendingApprovalCount', count: chartDataObj.numberOfTasksPendingApprovalCount ? chartDataObj.numberOfTasksPendingApprovalCount : 0, fillColor: '#D3CFF8' });
      chartData.push({ name: 'numberOfTasksClosedCount', count: chartDataObj.numberOfTasksClosedCount ? chartDataObj.numberOfTasksClosedCount : 0, fillColor: '#47B300' });
      chartData.push({ name: 'numberOfTaskSendForSupportCount', count: chartDataObj.numberOfTaskSendForSupportCount ? chartDataObj.numberOfTaskSendForSupportCount : 0, fillColor: '#F76E27' });
      chartData.push({ name: 'numberOfTaskFailedCount', count: chartDataObj.numberOfTaskFailedCount ? chartDataObj.numberOfTaskFailedCount : 0, fillColor: '#FF0000' });
      renderCustomBarLabel = ({
        x,
        y,
        width,
        value
      }) => {
        const dataVal = value;
        return <text x={x + width / 2} y={y} fill="#666" textAnchor="middle" dy={-6}>{`${dataVal}`}</text>;
      };
    }

    getBarCustomTooltip = ({
      value
    }) => {
      const dataVal = value;
      return (
        <div className="custom-tooltip">
          <p className="label">{`${dataVal}`}</p>
        </div>
      );
    };

    return (
      <Col xs={12} md={12} style={{ width: '100%', height: '100%' }}>
        <ResponsiveContainer width="99%" height="80%">
          <BarChart
            data={chartData}
            margin={{
              top: 25,
              bottom: 5
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <YAxis />
            {false && <Tooltip content={getBarCustomTooltip} />}
            <Bar isAnimationActive={false} dataKey="count" fill="#8884d8" minPointSize={5} barSize={30} label={renderCustomBarLabel}>
              {chartData.map((entry, index) => (
                <Cell fill={chartData[index].fillColor} />
              ))}
              {chartData.map((entry, indexCount) => (
                <LabelList dataKey="" content={chartData[indexCount].count} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Col>
    );
  }

  getPriorityCharts() {
    return (
      <Row className="priority-chart-block">
        <Col xs={4} md={4} onClick={() => { this.chartDrillDownClientChart(2, 1); }}>
          <div className="priority-chart">
            <div className="chart-header"><span className="title">High Priority</span></div>
            {this.getBarChart('HIGH')}
          </div>
        </Col>
        <Col xs={4} md={4} onClick={() => { this.chartDrillDownClientChart(2, 2); }}>
          <div className="priority-chart">
            <div className="chart-header"><span className="title">Medium Priority</span></div>
            {this.getBarChart('MEDIUM')}
          </div>
        </Col>
        <Col xs={4} md={4} onClick={() => { this.chartDrillDownClientChart(2, 3); }}>
          <div className="priority-chart">
            <div className="chart-header"><span className="title">NORMAL Priority</span></div>
            {this.getBarChart('NORMAL')}
          </div>
        </Col>
      </Row>
    );
  }

  getTitle(priorityLevel) {
    let priorityLevelTitle = 'HIGH';
    if (priorityLevel === 1) {
      priorityLevelTitle = 'HIGH';
    }
    if (priorityLevel === 2) {
      priorityLevelTitle = 'MEDIUM';
    }
    if (priorityLevel === 3) {
      priorityLevelTitle = 'NORMAL';
    }
    return priorityLevelTitle;
  }

  getClientPriorityCharts() {
    let title = this.getTitle(this.state.currentPriorityLevel) + ' Priority';
    if (this.state.currentClient) {
      title = this.state.currentClient;
    }
    return (
      <Row className="priority-chart-block priority-based-on-client">
        <Col xs={12} md={12} onClick={() => { this.chartDrillDownSource(3, this.state.currentPriorityLevel, this.state.currentClient); }}>
          <div className="priority-chart">
            <div className="chart-header">
              <span className="title">
                {title}
              </span>
            </div>
            {this.getBarChart(this.state.currentPriorityLevel)}
          </div>
        </Col>
      </Row>
    );
  }

  getSourcePriorityCharts() {
    let title = this.getTitle(this.state.currentPriorityLevel) + ' Priority';
    if (this.state.currentSourceName) {
      title = this.state.currentSourceName;
    }
    return (
      <Row className="priority-chart-block priority-based-on-source">
        <Col xs={12} md={12}>
          <div className="priority-chart">
            <div className="chart-header">
              <span className="title">
                {title}
              </span>
            </div>
            {this.getBarChart(this.state.currentPriorityLevel)}
          </div>
        </Col>
      </Row>
    );
  }

  getchartBoxHeader() {
    return (
      <div key={Math.random()}>
        <Row>{this.getHeaderItems()}</Row>
        <div style={{ display: 'flex' }}>
          <div style={{
            minWidth: '25%',
            paddingTop: '10px',
            paddingLeft: '20px',
            display: this.state.currentChartLevel === 2 || this.state.currentChartLevel === 3 ? 'block' : 'none'
          }}
          >
            {this.state.currentChartLevel === 2 && this.getClientFilter()}
            {this.state.currentChartLevel === 3 && this.getSourceFilter()}
          </div>
          <PriorityChartLegend />
        </div>
      </div>
    );
  }

  getChartDashboard() {
    let chartBlock = this.getPriorityCharts();
    if (this.state.currentChartLevel === 0) {
      chartBlock = this.getPriorityCharts();
    }
    if (this.state.currentChartLevel === 2) {
      chartBlock = this.getClientPriorityCharts();
    }
    if (this.state.currentChartLevel === 3) {
      chartBlock = this.getSourcePriorityCharts();
    }
    return chartBlock;
  }

  getTableDashboard() {
    return (<PriorityTable key={Math.random()} levelReset={this.state.levelReset} handlePriorityTableFromChild={(postData) => { this.handlePriorityTableFromChild(postData); }} currentChartLevel={this.state.currentChartLevel} currentPriorityLevel={this.state.currentPriorityLevel} currentClient={this.state.currentClient} currentSource={this.state.currentSource} />);
  }

  chartDrillDownClientChart(currentChartLevel, currentPriorityLevel, currentClient) {
    // const dataObj = this.props.clientReducer ? this.props.clientReducer.data : [];
    // const result = Object.values(dataObj);
    let clientData = [];
    this.props.loadClients().then(() => {
      clientData = this.getClientObj(currentPriorityLevel);
      if (currentChartLevel === 2) {
        let selectedClient = this.state.currentClient;
        if (currentClient !== undefined) {
          selectedClient = currentClient;
        } else {
          selectedClient = clientData && clientData[0] ? clientData[0].id : null;
        }
        this.setState(previousState => ({
          ...previousState,
          currentClient: selectedClient,
          currentChartLevel: currentChartLevel,
          currentPriorityLevel: currentPriorityLevel,
          queueClientList: clientData,
        }), () => {
          const postData = {
            clientId: this.state.currentClient,
            priorityId: currentPriorityLevel
          };
          this.props.loadClientPriority(postData).then(() => {
            const response = this.props.priorityReducer && this.props.priorityReducer.clientData ? this.props.priorityReducer.clientData : [];
            if (response) {
              this.setState({
                priorityClientData: response,
                currentChartLevel: currentChartLevel,
                currentPriorityLevel: currentPriorityLevel,
                currentSource: null
              });
              this.getCustomBreadCrum(2, currentPriorityLevel);
              this.getClientPriorityCharts();
              this.getPriorityTableData();
            }
            // this.getPriorityTableData();
          });
        });
      }
    });
  }

  chartDrillDownSource(currentChartLevel, currentPriorityLevel, currentClient, currentSource) {
    const clientData = [];
    const sourceData = [];
    let selectedSource = this.state.currentSource;
    let selectedSourceName = this.state.currentSourceName;
    if (currentClient) {
      const dataObjSource = this.props.sourceReducer ? this.props.sourceReducer.data : [];
      let resultSource = Object.values(dataObjSource);
      this.props.loadSource(currentClient).then(() => {
        resultSource = Object.values(this.props.sourceReducer.data);
        resultSource.forEach((data) => {
          let sourceName = data.name;
          if (data.id === 100000) {
            sourceName = 'All Source';
          }
          if (currentSource === data.id) {
            selectedSourceName = data.name;
            if (data.id === 100000) {
              selectedSourceName = 'All Source';
            }
          }
          sourceData.push({ value: data.id, id: data.id, text: sourceName });
        });
        if (sourceData.length > 0) {
          selectedSource = this.state.currentSource;
          if (currentSource) {
            selectedSource = currentSource;
          } else if (sourceData && sourceData[0]) {
            selectedSource = sourceData[0].id;
            selectedSourceName = sourceData[0].text;
          }
          const postData = {
            clientId: this.state.currentClient,
            priorityId: currentPriorityLevel,
          };
          if (selectedSource !== 100000) {
            postData.sourceId = selectedSource;
          }
          this.props.loadSourcePriority(postData).then(() => {
            const response = this.props.priorityReducer && this.props.priorityReducer.sourceData ? this.props.priorityReducer.sourceData : [];
            if (response) {
              this.setState(previousState => ({
                ...previousState,
                currentClient: currentClient,
                currentChartLevel: currentChartLevel,
                currentPriorityLevel: currentPriorityLevel,
                queueClientList: clientData,
                prioritySourceData: response,
                currentSource: selectedSource,
                currentSourceName: selectedSourceName
              }), () => {
                this.getCustomBreadCrum(3, currentPriorityLevel);
                this.getSourcePriorityCharts();
                this.getPriorityTableData();
              });
            }
          });
        }
      });
    }
  }

  handlePriorityTableFromChild(postData) {
    let chartLevel = this.state.currentChartLevel;
    if (this.state.currentChartLevel === 1) {
      chartLevel = 2;
    }
    if (chartLevel === 2) {
      this.chartDrillDownClientChart(chartLevel, postData.priorityId);
    } else if (chartLevel === 3) {
      this.chartDrillDownSource(chartLevel, postData.priorityId, this.state.currentClient);
    } else {
      this.props.loadPriorityTable(postData);
    }
  }

  handleClient(data) {
    this.props.loadSource(data).then(() => {
      const dataObj = this.props.sourceReducer.data ? this.props.sourceReducer.data : [];
      const result = Object.values(dataObj);
      this.setState({
        currentClient: data,
        currentSource: result[0] ? result[0].id : null,
        currentSourceName: result[0].name
      }, () => {
        this.chartDrillDownClientChart(this.state.currentChartLevel, this.state.currentPriorityLevel, data);
        // this.getPriorityTableData();
      });
    });
  }

  handleSource(data) {
    this.setState({
      currentSource: data
    }, () => {
      this.chartDrillDownSource(this.state.currentChartLevel, this.state.currentPriorityLevel, this.state.currentClient, data);
      // this.getPriorityTableData();
    });
  }

  goToPage() {
    window.location.href = '/#/me/queueAssignment';
  }

  goToSupport() {
    window.location.href = '/#/me/sendToSupport';
  }

  goToConfigPage() {
    window.location.href = '/#/me/queueConfigure';
  }

  goToSupportRequestPage() {
    window.location.href = '/#/me/supportRequest';
  }

  render() {
    return (
      <div key={Math.random()}>
        {this.getchartBoxHeader()}
        {this.getChartDashboard()}
        {this.getTableDashboard()}
      </div>
    );
  }
}

ManagerDashboard.propTypes = {
  loadBreadCrumsDashboard: PropTypes.func,
  breadcrumReducer: PropTypes.object,
  clientReducer: PropTypes.object,
  priorityReducer: PropTypes.object,
  sourceReducer: PropTypes.object,
  meRolesReducer: PropTypes.object,
  loadSource: PropTypes.func,
  loadClients: PropTypes.func,
  loadPriority: PropTypes.func,
  loadClientPriority: PropTypes.func,
  loadSourcePriority: PropTypes.func,
  loadPriorityTable: PropTypes.func,
  loadRoles: PropTypes.func
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    loadPriority,
    loadClientPriority,
    loadSourcePriority,
    loadBreadCrumsDashboard,
    loadClients,
    loadPriorityTable,
    loadSource,
    loadRoles
  }, dispatch)
);

const mapStateToProps = state => ({
  priorityReducer: state.priorityReducer,
  breadcrumReducer: state.breadcrumReducer,
  clientReducer: state.clientReducer,
  sourceReducer: state.sourceReducer,
  meRolesReducer: state.meRolesReducer,
  configClientReducer: state.configClientReducer
});

export default connect(mapStateToProps, mapDispatchToProps)(ManagerDashboard);
