import { LOAD, LOAD_SUCCESS, LOAD_FAIL } from 'redux/actions/categoryAction';

const initialState = {
  loaded: false,
  loading: false,
  category: [],
  categoryList: [],
  subCategory: []
};

function categoryReducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case LOAD_SUCCESS:
      const categoryListMain = state.category ? { ...state.category, ...action.payload.data[1] } : action.payload.data[1];
      const categoryListObj = [];
      const subCategoryObj = [];
      action.payload.data.map((category) => {
        if (category.children) {
          categoryListObj.push(category.children);
          category.children.map((sub) => {
            if (sub.children) {
              subCategoryObj.push(sub.children);
            }
            return true;
          });
        }
        return true;
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        category: categoryListMain,
        categoryList: categoryListObj,
        subCategory: subCategoryObj
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    default:
      return state;
  }
}

export default categoryReducer;
