export const LOAD = 'binary/client/LOAD';
export const LOAD_SUCCESS = 'binary/client/LOAD_SUCCESS';
export const LOAD_FAIL = 'binary/client/LOAD_FAIL';
export const LOAD_NX_CLIENT = 'binary/client/LOAD_NX_CLIENT';
export const LOAD_FLAT_CLIENT_SUCCESS = 'binary/client/LOAD_FLAT_CLIENT_SUCCESS';
export const LOAD_FLAT_CLIENT_FAIL = 'binary/client/LOAD_FLAT_CLIENT_FAIL';

export function loadClients() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    payload: {
      client: 'managementengagement',
      request: {
        method: 'get',
        url: '/api/clients?sort=name'
      }
    }
  };
}

export function loadFlatClientList() {
  return {
    types: [LOAD, LOAD_FLAT_CLIENT_SUCCESS, LOAD_FLAT_CLIENT_FAIL],
    payload: {
      client: 'nextgen',
      request: {
        method: 'get',
        url: '/api/clients/list?activeOnly=true&type=C'
      }
    }
  };
}
