import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './PriorityChartLegend.scss';

class PriorityChartLegend extends Component {
  getLegends() {
    return (
      <div className="priority-chart-legend-block">
        <div className="legend-queue-size legend-item">
          <div className="legend-queue-size-bullet" />
          <div className="legend-queue-size-label">Queue Size</div>
        </div>
        <div xs="2" md="1" className="legend-in-progress legend-item">
          <div className="legend-in-progress-bullet" />
          <div className="legend-in-progress-label">In-Progress</div>
        </div>
        <div xs="2" md="2" className="legend-pending-approval legend-item" style={{ maxWidth: '12%' }}>
          <div className="legend-pending-approval-bullet" />
          <div className="legend-pending-approval-label">Pending Approval</div>
        </div>
        <div xs="2" md="1" className="legend-success legend-item">
          <div className="legend-success-bullet" />
          <div className="legend-success-label">Success</div>
        </div>
        <div xs="2" md="1" className="legend-in-support legend-item">
          <div className="legend-in-support-bullet" />
          <div className="legend-in-support-label">In-Support</div>
        </div>
        <div xs="2" md="1" className="legend-failed legend-item">
          <div className="legend-failed-bullet" />
          <div className="legend-failed-label">Failed</div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div style={{ width: '100%' }}>
        {this.getLegends()}
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => (
  bindActionCreators({}, dispatch)
);

const mapStateToProps = '';

export default connect(mapStateToProps, mapDispatchToProps)(PriorityChartLegend);
