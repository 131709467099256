export const LOAD = 'binary/users/LOAD';
export const LOAD_SUCCESS = 'binary/users/LOAD_SUCCESS';
export const LOAD_FAIL = 'binary/users/LOAD_FAIL';

export function loadUsers() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    payload: {
      client: 'reportsapi',
      request: {
        method: 'get',
        url: '/api/reports/users'
      },
    },
  };
}
