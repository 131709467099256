import { MozartSelectField } from '@pgforsta/mozart_ui';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

class Client extends Component {
  constructor(props) {
    super(props);
    this.inputSelect = 'UserBucket';
    this.select = React.createRef();
  }

  getClients() {
    const dataObj = this.props.clientReducer.data;
    const clientData = [];
    const result = Object.values(dataObj.clients);
    if (result.length > 0) {
      result.forEach((data) => {
        clientData.push({ value: data.id, id: data.id, text: data.name });
      });
    }
    return (
      <div style={{ float: 'right', display: 'flex' }}>
        <div className="content-queue-text" style={{ paddingTop: '10px' }}>Current Queue</div>
        <MozartSelectField
          data={clientData}
          onChange={(data) => { this.handleClient(data); }}
          width="250px"
          defaultValue={result[0] ? result[0].id : null}
        />
      </div>
    );
  }

  handleClient(data) {
    this.props.handleClient(data);
  }

  render() {
    return (
      <div>
        {this.props.enableClientFilter && this.props.clientReducer.data && this.getClients()}
      </div>
    );
  }
}

Client.propTypes = {
  clientReducer: PropTypes.object,
  handleClient: PropTypes.func,
  enableClientFilter: PropTypes.bool
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({ }, dispatch)
);

const mapStateToProps = state => ({
  clientReducer: state.clientReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(Client);
