import { LOAD, LOAD_SUCCESS, LOAD_FAIL } from 'redux/actions/responderListAction';

const initialState = {
  loaded: false,
  loading: false,
  data: [],
};

function responderListReducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
        loaded: false,
        data: []
      };
    case LOAD_SUCCESS:
      const responderList = state.data ? { ...state.data, ...action.payload.data } : action.payload.data;
      return {
        ...state,
        loading: false,
        loaded: true,
        data: responderList,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export default responderListReducer;
