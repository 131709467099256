import { MozartImage } from '@pgforsta/mozart_ui';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import { Profile } from 'components';

class Logo extends Component {
  render() {
    const style = {
      maxHeight: '32px',
      width: 'auto',
      maxWidth: '100%'
    };
    const { logo } = this.props;

    return (
      <div>
        <MozartImage
          style={style}
          src={logo}
          responsive
        />
      </div>
    );
  }
}

Logo.propTypes = {
  logo: PropTypes.string
};

export default connect()(Logo);
