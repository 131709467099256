import { MozartSelectField } from '@pgforsta/mozart_ui';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { load as loadQueue } from 'redux/actions/queueAction';
import { reset as resetContentId } from 'redux/actions/documentAutoGenerateAction';
import './UserBucket.scss';
import PropTypes from 'prop-types';

class UserBucket extends Component {
  constructor(props) {
    super(props);
    this.inputSelect = 'UserBucket';
    this.select = React.createRef();
    this.loadQueue();
  }

  getUserBuckets() {
    const dataObj = this.props.queueReducer.userBuckets;
    const userBucketData = [];
    const result = Object.values(dataObj);
    if (result.length > 0) {
      result.forEach((data) => {
        userBucketData.push({ value: data.id, id: data.id, text: data.name });
      });
    }
    return (
      <div style={{ float: 'right', display: 'flex' }}>
        <div className="content-queue-text" style={{ paddingTop: '10px' }}>Current Queue</div>
        <MozartSelectField
          data={userBucketData}
          onChange={(data) => { this.handleUserBucket(data); }}
          width="250px"
          defaultValue={result[0] ? result[0].id : null}
          value={result[0] ? result[0].id : null}
          // defaultValue={result[0].id}
          // defaultValue={[4]}
          key="userBucket"
        />
      </div>
    );
  }

  loadQueue() {
    if (this.props.authReducer.permissions && this.props.authReducer.permissions.length > 0) {
      if (this.props.hasBucket) {
        this.props.loadQueue().then((res) => {
          if (res && res.payload && res.payload.data && res.payload.data[0]) {
            this.props.resetContentId();
            this.props.onChange(res.payload.data[0].id);
          }
        });
      }
    }
  }

  handleUserBucket(data) {
    this.props.resetContentId();
    this.props.onChange(data);
  }

  render() {
    let permissionRole = '';
    if (this.props.authReducer.permissions && this.props.authReducer.permissions.length > 0) {
      if (this.props.authReducer.permissions.includes(578) && this.props.authReducer.permissions.includes(580)) {
        permissionRole = 'CONTENT_ADMINISTRATOR';
      }
      if (this.props.authReducer.permissions.includes(578) && this.props.authReducer.permissions.includes(581)) {
        permissionRole = 'CONTENT_QC';
      }
      if (this.props.authReducer.permissions.includes(578) && !this.props.authReducer.permissions.includes(580) && !this.props.authReducer.permissions.includes(581)) {
        permissionRole = 'CONTENT_EDITOR';
      }
      if (this.props.authReducer.permissions.includes(580)) {
        permissionRole = 'CONTENT_ADMINISTRATOR';
      }
    }
    console.log('Logged user perm', permissionRole);
    const dataQueueObj = this.props.queueReducer.userBuckets;
    const result = Object.values(dataQueueObj);
    return (
      <div>
        {(this.props.queueReducer.loaded && result.length > 0) && this.getUserBuckets()}
      </div>
    );
  }
}

UserBucket.propTypes = {
  hasBucket: PropTypes.bool,
  authReducer: PropTypes.object,
  queueReducer: PropTypes.object,
  onChange: PropTypes.func,
  loadQueue: PropTypes.func,
  resetContentId: PropTypes.func
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({ loadQueue, resetContentId }, dispatch)
);

const mapStateToProps = state => ({
  queueReducer: state.queueReducer,
  authReducer: state.authReducer
});

export default connect(mapStateToProps, mapDispatchToProps)(UserBucket);
