import React from 'react';
import reactCookie from 'react-cookie';
import { MozartImage } from '@pgforsta/mozart_ui';
import moment from 'moment';
import _ from 'lodash';

export const logout = (sourceUrl = '') => {
  const cookies = document.cookie.split(';');
  cookies.map((cookie) => {
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=' + process.env.REACT_APP_DOMAIN + '; path=/';
    return '';
  });
  const cookiePath = '/';
  const cookieDomain = process.env.REACT_APP_DOMAIN;
  const action = process.env.REACT_APP_LOGIN_HOST + '/app/logout?source=' + (sourceUrl !== '' ? sourceUrl : process.env.REACT_APP_TOOLS_HOST);

  reactCookie.remove(process.env.REACT_APP_EXPIRY_TIME, { path: cookiePath, domain: cookieDomain });
  reactCookie.remove(process.env.REACT_APP_ACCESS_TOKEN, { path: cookiePath, domain: cookieDomain });
  reactCookie.remove('access_token_expiry_time', { path: cookiePath, domain: cookieDomain });
  reactCookie.remove('access_token_expiry_length', { path: cookiePath, domain: cookieDomain });
  reactCookie.remove('survey_disclaimer_accepted', { path: cookiePath, domain: cookieDomain });

  const logoutForm = document.createElement('FORM');
  const button = document.createElement('input');
  button.setAttribute('type', 'submit');
  logoutForm.name = 'logout';
  logoutForm.method = 'POST';
  logoutForm.action = action;
  logoutForm.appendChild(button);
  document.body.appendChild(logoutForm);
  logoutForm.submit();
};

export function getSourceLogoUrl(sourceLink = '', sourceName = '', sourceLogoUrl = '', type = '', width = '20px', height = '20px') {
  const noSourceLogoUrl = typeof sourceLogoUrl === 'undefined' || sourceLogoUrl === null || !sourceLogoUrl;

  const isTestimonial = type === 'testimonial';
  const isSocial = type === 'social';
  const isSurvey = type === 'survey';

  const path = window.env !== 'prod' ? '//denmfovn3e0c3.cloudfront.net/dev/bha-source/80h/' : '//denmfovn3e0c3.cloudfront.net/prod/bha-source/80h/';

  const image = (source, src) => (
    <MozartImage onClick={() => window.open(sourceLink, '_blank')} style={{ width: width, height: height, display: 'inline' }} src={path + src.replace('https://s3.amazonaws.com/local-source-pic-store/nextgenbha-api/', '').replace('.png', '').trim() + '@2X.png'} alt={sourceName} responsive />
  );
  if (noSourceLogoUrl && isSocial) return image(sourceName, 'GenericSocial');
  if (noSourceLogoUrl && isTestimonial) return image(sourceName, ' GenericTestimonial');
  if (noSourceLogoUrl && isSurvey) return image(sourceName, ' GenericSurvey');

  if (!noSourceLogoUrl) return image(sourceName, sourceLogoUrl);
  return '';
}

export const getCookie = (name) => {
  const value = '; ' + document.cookie;
  const parts = value.split('; ' + name + '=');
  if (parts.length === 2) return parts.pop().split(';').shift();
  return undefined;
};

export function getAppConfig() {
  return {
    app: {
      title: 'binary fountain',
      description: 'binary fountain',
      supportEmail: 'support@binaryfountain.com',
      supportPhone: '1-844-225-8155',
      head: {
        titleTemplate: 'Binary Fountain: %s',
        meta: [
          { name: 'description', content: 'binary' },
          { charset: 'utf-8' },
          { property: 'og:site_name', content: 'binary' },
          { property: 'og:image', content: 'binary' },
          { property: 'og:locale', content: 'en_US' },
          { property: 'og:title', content: 'binary' },
          { property: 'og:description', content: 'binary' },
          { property: 'og:card', content: 'binary' },
          { property: 'og:site', content: 'binary' },
          { property: 'og:creator', content: 'binary' },
          { property: 'og:image:width', content: '200' },
          { property: 'og:image:height', content: '200' }
        ]
      }
    },
    rioapp: {
      title: 'Rio SEO',
      description: 'rio seo',
      supportEmail: 'support@rioseo.com',
      supportPhone: '888-511-7363',
      head: {
        titleTemplate: 'Local Manager: %s',
        meta: [
          { name: 'description', content: 'rio' },
          { charset: 'utf-8' },
          { property: 'og:site_name', content: 'rio' },
          { property: 'og:image', content: 'rio' },
          { property: 'og:locale', content: 'en_US' },
          { property: 'og:title', content: 'rio' },
          { property: 'og:description', content: 'rio' },
          { property: 'og:card', content: 'rio' },
          { property: 'og:site', content: 'rio' },
          { property: 'og:creator', content: 'rio' },
          { property: 'og:image:width', content: '200' },
          { property: 'og:image:height', content: '200' }
        ]
      }
    },
    pes: {
      title: 'PES',
      head: {
        titleTemplate: 'Fountain Analytics: %s'
      }
    }
  };
}

export function convertUnicode(input) {
  return input.replace(/\\u(\w{4,4})/g, (a, b) => {
    const charcode = parseInt(b, 16);
    return String.fromCharCode(charcode);
  });
}

export function flattenChildren(node, checked, expanded, left) {
  const data = { [node.id]: node };
  const arr = [node];

  while (arr.length > 0) {
    const current = arr[0];
    arr.shift();
    if (typeof current.children !== 'undefined' && current.children !== null) {
      Object.keys(current.children).forEach((key) => {
        if (typeof data[key] === 'undefined') {
          data[key] = current.children[key];
          data[key].expanded = typeof expanded !== 'undefined' ? expanded : data[key].expanded;
          data[key].left = typeof left !== 'undefined' ? left : data[key].left;
          data[key].checked = typeof checked !== 'undefined' ? checked : data[key].checked;
          data[key].parent = current;
          arr.push(current.children[key]);
        }
      });
    }
  }
  return data;
}

export function convertUTCToCSTTime(time, format) {
  let currentTime = time;
  let cstTime = '';
  currentTime = moment.utc(time);
  currentTime.tz('America/Chicago');
  if (format) {
    cstTime = currentTime.format(format);
  } else {
    cstTime = currentTime.format('MM/DD/YYYY h:mm A [CST]');
  }
  return cstTime;
}

export function meUserRole(permissionArray, page) {
  const managerArray = [702, 703, 704, 705];
  const responderArray = [707];
  const approvalArray = [708];
  const managerRole = _.some(permissionArray, item => managerArray.includes(item));
  const responderRole = _.some(permissionArray, item => responderArray.includes(item));
  const approvalRole = _.some(permissionArray, item => approvalArray.includes(item));
  let nlpQcToolRole = '';
  if (permissionArray.includes(578) && permissionArray.includes(580)) {
    nlpQcToolRole = 'CONTENT';
  }
  if (permissionArray.includes(578) && permissionArray.includes(581)) {
    nlpQcToolRole = 'CONTENT';
  }
  if (permissionArray.includes(578) && !permissionArray.includes(580) && !permissionArray.includes(581)) {
    nlpQcToolRole = 'CONTENT';
  }
  let Role = '';
  if (managerRole) {
    Role = 'MANAGER';
  } else if (responderRole) {
    Role = 'RESPONDER';
  } else if (approvalRole) {
    Role = 'APPROVER';
  } else if (nlpQcToolRole) {
    Role = nlpQcToolRole;
  } else if (permissionArray.length === 0) {
    Role = 'unknown';
  }
  if (page !== Role) {
    window.location.href = '/#';
  }
}

export function goToHomePage() {
  window.location.href = '/#';
}

export function triggerFetch(absoluteUrl, onRequest, successCallBack, errorCallBack, method, data, retryCall = false) {
  const cookieName = process.env.REACT_APP_ENV === 'prod' ? 'anti-csrf-token' : process.env.REACT_APP_ENV + '_anti-csrf-token';
  onRequest();
  fetch(absoluteUrl, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'anti-csrf-token': reactCookie.load(cookieName)
    },
    method: method,
    credentials: 'include',
    redirect: 'follow',
    body: data
  })
    .then(handleErrors)
    .then(res => (retryCall ? res.json() : res))
    .then((response) => {
      successCallBack(response);
    })
    .catch((error) => {
      errorCallBack(error);
    });
}

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export function isEmptyOrSpaces(str) {
  return str === null || str.trim().length > 0;
}

// get query from url
export function getFilterQueryFromUrl(name) {
  const query = {};

  if (window) {
    const subUrl = window.location.hash.split('?');
    if (subUrl[1]) {
      subUrl[1].split('&').forEach(item => {
        const key = item.split('=')[0];
        const value = item.split('=')[1];
        query[key] = query[key] ? query[key] : [];
        query[key].push(value);
      });
    }
  }
  return query[name] ? query[name] : [];
}
