export const LOAD_TASK = 'binary/taskuer/LOAD_TASK';
export const LOAD_TASK_SUCCESS = 'binary/taskuer/LOAD_TASK_SUCCESS';
export const LOAD_TASK_FAIL = 'binary/taskuer/LOAD_TASK_FAIL';

export function loadTaskUser(role) {
  return {
    types: [LOAD_TASK, LOAD_TASK_SUCCESS, LOAD_TASK_FAIL],
    payload: {
      client: 'managementengagement',
      request: {
        method: 'post',
        url: '/api/engagements/lock',
        data: { userRole: role }
      }
    }
  };
}
