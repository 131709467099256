import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { bindActionCreators } from 'redux';
import { load as loadAuth, loadPermission } from 'redux/actions/authAction';
import {
  Home, ManagerView, ResponderView, ApproverView, QueueAssignmentView,
  QueueConfigureView, Content, SupportRequest, ReportMonitor
} from 'containers';
import ContentAdmin from 'containers/ContentAdmin/ContentAdmin';

function NotFound() {
  return (
    <div>
      <Helmet><title>Binary Fountain BHA Tool: Not Found</title></Helmet>
      <h3>Not Found</h3>
    </div>
  );
}

class Authenticated extends Component {
  componentDidMount() {
    if (!this.props.authReducer.user) {
      this.getUserInfo();
    }
  }

  getUserInfo() {
    this.props.loadAuth().then(() => {
      if (this.props.authReducer.email) {
        this.props.loadPermission(this.props.authReducer.email);
      }
    });
  }

  render() {
    const { loadedPermission } = this.props;
    return (
      <div>
        {loadedPermission && (
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/home" component={Home} />
            <Route exact path="/me/manager" component={ManagerView} />
            <Route exact path="/me/responder" component={ResponderView} />
            <Route exact path="/me/approver" component={ApproverView} />
            <Route exact path="/me/queueAssignment" component={QueueAssignmentView} />
            <Route exact path="/me/queueConfigure" component={QueueConfigureView} />
            <Route exact path="/me/supportRequest" component={SupportRequest} />
            <Route exact path="/report/watch" component={ReportMonitor} />
            <Route exact path="/report/watch/:page" component={ReportMonitor} />
            <Route exact path="/content" component={Content} />
            <Route exact path="/content/admin" component={ContentAdmin} />
            <Route exact path="/me/supportRequest" component={SupportRequest} />
            <Route path="*" component={NotFound} status={404} />
          </Switch>
        )}
      </div>
    );
  }
}

Authenticated.propTypes = {
  loadedPermission: PropTypes.bool,
  authReducer: PropTypes.object,
  loadAuth: PropTypes.func,
  loadPermission: PropTypes.func
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({ loadAuth, loadPermission }, dispatch)
);

const mapStateToProps = state => ({
  authReducer: state.authReducer,
  permissions: state.authReducer.permissions,
  loadedPermission: state.authReducer.loadedPermission
});

export default connect(mapStateToProps, mapDispatchToProps)(Authenticated);
