import { MozartSelectField } from '@pgforsta/mozart_ui';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { loadClients } from 'redux/actions/clientAction';

class Client extends Component {
  constructor(props) {
    super(props);
    this.inputSelect = 'UserBucket';
    this.select = React.createRef();
    if (this.props.clientReducer.data.length === 0) {
      this.props.loadClients();
    }
  }

  getClients() {
    const dataObj = this.props.clientReducer.data;
    const clientData = [];
    const result = Object.values(dataObj);
    if (result.length > 0) {
      clientData.push({ value: 0, id: 0, text: 'All Clients' });
      result.forEach((data) => {
        clientData.push({ value: data.clientId, id: data.clientId, text: data.clientName });
      });
    }
    return (
      <div style={{ float: 'right', display: 'flex' }}>
        <MozartSelectField
          data={clientData}
          onChange={(data) => { this.handleClient(data); }}
          width="180px"
          defaultValue={result[0] ? result[0].id : null}
          hintText="Select Client"
        />
      </div>
    );
  }

  handleClient(data) {
    this.props.handleOnChange(data, 'clientId');
  }

  render() {
    return (
      <div>
        {this.getClients()}
      </div>
    );
  }
}

Client.propTypes = {
  clientReducer: PropTypes.object,
  loadClients: PropTypes.func,
  handleOnChange: PropTypes.func,
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({ loadClients }, dispatch)
);

const mapStateToProps = state => ({
  clientReducer: state.clientReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(Client);
