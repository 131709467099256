import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-bootstrap';
import { loadFlatClientList } from 'redux/actions/clientAction';
import { loadFlatReports } from 'redux/actions/reportAction';
import {
  load as loadDate,
  update as updateDate,
} from 'redux/actions/dateAction';
import { MozartSelectField, MozartDateRange, MozartButton } from '@pgforsta/mozart_ui';
import moment from 'moment';
import './MonitorFilter.scss';

function AnalysisFilter(props) {
  const {
    clients, reports, preFilter
  } = props;
  const [filter, setFilter] = useState({
    status: preFilter && preFilter.status ? preFilter.status : [],
    reportNames: preFilter && preFilter.reportNames ? preFilter.reportNames : [],
    reports: preFilter && preFilter.reports ? preFilter.reports : [],
    clientNames: preFilter && preFilter.clientNames ? preFilter.clientNames : [],
    clients: preFilter && preFilter.clients ? preFilter.clients : [],
    dateRange: preFilter && preFilter.dateRange ? preFilter.dateRange : '',
  });
  const [goBack, setGoBack] = useState(false);

  useEffect(() => {
    props.loadFlatClientList();
    props.loadFlatReports();
    props.loadDate();
  }, []);

  useEffect(() => {
    if (goBack) {
      props.handleFilter(filter);
    }
  }, [goBack]);

  const getDateRange = () => {
    const { date } = props;
    const dateRange = (
      <div className="moniterFilterDateRange">
        <div className="reportStatusHeader">Date Range</div>
        <MozartDateRange
          id="lastRunDate"
          key="lastRunDate"
          renderAllTime
          prevFilterDataStart={date.start_date}
          prevFilterDataEnd={date.end_date}
          specialcase={date.specialcase}
          handleChange={(name, value) => {
            props.updateDate({ name: name, value: value });
          }}
          timezone="UTC"
        />
      </div>
    );
    return dateRange;
  };

  const getClientList = () => {
    const clientList = [];
    if (clients) {
      clients.map((client) => clientList.push({ id: client.id, value: client.id, text: client.name }));
    }
    return (
      <div className="userContainer">
        <div className="userHeader">Clients</div>
        <MozartSelectField
          data={clientList}
          id="clients"
          name="clients"
          hintText="Search"
          multiselect
          onChange={(data) => handleClientsMultiple(data)}
          defaultValue={filter.clients}
          style={{ border: 'none' }}
        />
      </div>
    );
  };

  const handleClientsMultiple = (obj) => {
    const clientNamesSelected = [];
    const clientsSelected = [];
    const objArray = obj && obj.length > 0 ? obj : [];
    objArray.forEach((item) => {
      if (item.value !== '') {
        clientNamesSelected.push(item.label);
        clientsSelected.push(item.value);
      }
    });
    setFilter({ ...filter, clientNames: clientNamesSelected, clients: clientsSelected });
  };

  const getReportList = () => {
    const reportList = [];
    if (reports) {
      reports.map((user) => reportList.push({ id: user.id, value: user.id, text: user.name }));
    }
    return (
      <div className="userContainer">
        <div className="userHeader">Reports</div>
        <MozartSelectField
          data={reportList}
          id="users"
          name="users"
          hintText="Search"
          border={false}
          multiselect
          onChange={(data) => handleReportsMultiple(data)}
          defaultValue={filter.reports}
        />
      </div>
    );
  };

  const handleReportsMultiple = (obj) => {
    const reportNamesSelected = [];
    const reportsSelected = [];
    const objArray = obj && obj.length > 0 ? obj : [];
    objArray.forEach((item) => {
      if (item.value !== '') {
        reportNamesSelected.push(item.label);
        reportsSelected.push(item.value);
      }
    });
    setFilter({ ...filter, reportNames: reportNamesSelected, reports: reportsSelected });
  };

  const applyFilter = () => {
    const { date } = props;
    const dateRange = moment(date.start_date).format('YYYY-MM-DD') + '_' + moment(date.end_date).format('YYYY-MM-DD');
    setFilter({ ...filter, dateRange: dateRange });
    setGoBack(true);
  };

  return (
    <div>
      <Row className="filterForm">
        <Col xs={6} md={6}>
          {getClientList()}
        </Col>
        <Col xs={6} md={6}>
          {getReportList()}
        </Col>
        <Col xs={12} md={12}>
          {getDateRange()}
        </Col>
        <Col xs={12} md={12}>
          <MozartButton
            id="sbumitFilter"
            data="Apply"
            background
            style={{ width: '100px', margin: '0' }}
            onClick={() => applyFilter()}
          />
        </Col>
      </Row>
    </div>
  );
}

AnalysisFilter.propTypes = {
  preFilter: PropTypes.object,
  loadFlatClientList: PropTypes.func,
  loadFlatReports: PropTypes.func,
  clients: PropTypes.object,
  reports: PropTypes.object,
  handleFilter: PropTypes.func,
  date: PropTypes.object,
  loadDate: PropTypes.func,
  updateDate: PropTypes.func,
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    loadFlatClientList, loadFlatReports, loadDate, updateDate
  }, dispatch)
);

const mapStateToProps = (state) => ({
  users: state.usersReducer.users,
  clients: state.clientReducer.clients,
  reports: state.reportReducer.reports,
  date: state.dateReducer && state.dateReducer.data,
});

export default connect(mapStateToProps, mapDispatchToProps)(AnalysisFilter);
