import {
  // MozartModal,
  // MozartButton,
  MozartRadio,
} from '@pgforsta/mozart_ui';
import React, { Component } from 'react';
// import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import { Profile } from 'components';
import './ReferContent.scss';
import Col from 'react-bootstrap/Col';
// import Row from 'react-bootstrap/Row';

class ReferContent extends Component {
  constructor(props) {
    super(props);
    this.inputSelect = 'UserBucket';
    this.show = true;
  }

  getReviewSentiments() {
    return (
      <div className="reviewsSentimentsBlock">
        <Col xs={12} md={12} className="review-sentiment-content-section-refer-content">
          <MozartRadio
            onChange={(data) => { this.handleOption(data); }}
            groupname="refer_content_radio"
            content={[
              {
                name: 'Wrong provider name',
                value: 'Wrong_provider_name',
                id: 'Wrong_provider_name',
                checked: true
              },
              {
                name: 'Wrong location name',
                value: 'Wrong_location_name',
                id: 'Wrong_location_name',
                checked: false
              },
              {
                name: 'Translation / Language issue',
                value: 'Translation_Language_issue',
                id: 'Translation_Language_issue',
                checked: false
              },
              {
                name: 'Wrong industry',
                value: 'Wrong_industry',
                id: 'Wrong_industry',
                checked: false
              }
            ]}
            key={Math.random()}
          />
        </Col>
      </div>
    );
  }

  handleOption(data) {
    this.props.handleReferContentState(data);
  }

  render() {
    return (
      <div style={{ width: '935px' }}>
        {this.getReviewSentiments()}
      </div>
    );
  }
}

ReferContent.propTypes = {
  handleReferContentState: PropTypes.func
};

export default ReferContent;
