import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadStatistics } from 'redux/actions/reportMonitorAction';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import { NotAvailable } from 'components';
import { MozartButton, MozartTable, MozartLoading } from '@pgforsta/mozart_ui';

function Analysis(props) {
  const {
    data, filter, loading, openFilter
  } = props;

  useEffect(() => {
    props.loadStatistics(filter);
  }, [filter]);

  const getMonitorInfo = () => {
    const headerBlock = (
      <div>
        <Row className="monitorHeader">
          <Col xs="3" md="3" className="infoBlock">
            <div className="infoBlockTitle">CLIENT</div>
            <div className="infoBlockTitleValue">{getFilterInfo('clients')}</div>
          </Col>
          <Col xs="3" md="3" className="infoBlock">
            <div className="infoBlockTitle">REPORT</div>
            <div className="infoBlockTitleValue">{getFilterInfo('reports')}</div>
          </Col>
          <Col xs="3" md="3" className="infoBlock">
            <div className="infoBlockTitle">DATE RANGE</div>
            {/* <div className="infoBlockTitleValue">04/12/2012 - 04/12/2012</div> */}
            <div className="infoBlockTitleValue">{getFilterInfo('dateRange')}</div>
          </Col>
          <Col xs="3" md="3" className="infoBlock">
            <div className="infoBlockTitle">
              <MozartButton
                id="filter"
                data={(
                  <span>
                    Filter
                    <i className="icon-Configuration filterpaddingIcon" />
                  </span>
                )}
                onClick={() => openFilter()}
                background
                style={{
                  width: '1em',
                  marginTop: '5px'
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
    return headerBlock;
  };

  const getFilterInfo = (type) => {
    const info = [];
    switch (type) {
      case 'clients':
        if (filter && filter.clientNames && filter.clientNames.length > 0) {
          filter.clientNames.forEach(client => info.push(client));
        }
        break;
      case 'users':
        if (filter && filter.userNames && filter.userNames.length > 0) {
          filter.userNames.forEach(user => info.push(user));
        }
        break;
      case 'reports':
        if (filter && filter.reportNames && filter.reportNames.length > 0) {
          filter.reportNames.forEach(report => info.push(report));
        }
        break;
      case 'dateRange':
        if (filter && filter.dateRange && filter.dateRange.split('_').length > 0) {
          info.push(
            moment(filter.dateRange.split('_')[0]).format('MM/DD/YYYY') +
            ' - ' +
            moment(filter.dateRange.split('_')[1]).format('MM/DD/YYYY')
          );
        }
        break;
      default:
        break;
    }
    return info.length ? info.join(', ') : '--';
  };

  const getTitleData = () => {
    let DATA = [];
    DATA = [
      <div style={{ paddingLeft: '15px' }}>CLIENT</div>,
      <div>REPORT</div>,
      <div>BEST</div>,
      <div>WORST</div>,
      <div>AVERAGE</div>
    ];
    return DATA;
  };

  const getRowData = () => {
    const DATA = [];
    if (!loading && data) {
      data.forEach(row => {
        const listingRender = [];
        listingRender.push(
          getClient(row),
          getReport(row),
          getRunningTime(row, 'bestRunningTime'),
          getRunningTime(row, 'worstRunningTime'),
          getRunningTime(row, 'averageRunningTime')
        );
        DATA.push(listingRender);
      });
    }
    return DATA;
  };

  const getClient = (row) => {
    const cell = (<div className="cellview">{row.clientId ? row.clientId : '--'}</div>);
    return cell;
  };

  const getReport = (row) => {
    const cell = (<div className="cellview">{row.reportId ? row.reportId : '--'}</div>);
    return cell;
  };

  const getRunningTime = (row, type) => {
    const cell = (<div className="cellview">{row[type] ? row[type] : '--'}</div>);
    return cell;
  };

  return (
    <div className="PageBlock">
      {loading && <MozartLoading />}
      {getMonitorInfo()}
      {data && (
        <MozartTable
          id="action"
          headers={getTitleData()}
          rows={getRowData()}
          bodyStyle={{ fontSize: '0.8em', color: '#666', borderTop: '19px solid #FFFFFF' }}
          colStyle={{ height: '40px', textAlign: 'center' }}
          bgRowColors={['#F5F4FF', '#FFFFFF']}
          rowStyle={{ borderBottom: '5px' }}
          headStyle={{
            textAlign: 'left',
            paddingLeft: '27px',
            height: '13px',
            width: 'auto',
            color: '#FFFFFF',
            fontFamily: 'Roboto',
            fontSize: '13px',
            fontWeight: '500',
            letterSpacing: '1.1px',
            lineHeight: '13px'
          }}
        />
      )}
      {!data && <NotAvailable loadingMessage="No records available!" />}
    </div>
  );
}

Analysis.propTypes = {
  data: PropTypes.array,
  filter: PropTypes.object,
  loading: PropTypes.bool,
  loadStatistics: PropTypes.func,
  openFilter: PropTypes.func
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    loadStatistics
  }, dispatch)
);

const mapStateToProps = (state) => ({
  data: state.reportMonitorReducer.statisticsdata,
  loading: state.reportMonitorReducer.loading
});

export default connect(mapStateToProps, mapDispatchToProps)(Analysis);
