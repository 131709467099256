import {
  LOAD,
  LOAD_SUCCESS,
  LOAD_FAIL,
  LOAD_PERMISSION,
  LOAD_PERMISSION_SUCCESS,
  LOAD_PERMISSION_FAIL
} from 'redux/actions/authAction';

const initialState = {
  user: false,
  loaded: false,
  loading: false,
  loadedPermission: false,
  loadingPermission: false,
  permissions: false,
  email: false
};

function getAllPermissions(internalProducts) {
  let permissions = [];
  const productIds = Object.keys(internalProducts);
  if (productIds.length > 0) {
    productIds.forEach((productId) => {
      permissions = permissions.concat(internalProducts[productId].permissions);
    });
  }
  return permissions;
}

function authReducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case LOAD_SUCCESS:
      document.cookie = 'user=' + action.payload.data.email + '; domain=' + process.env.REACT_APP_DOMAIN + '; path=/';
      const currUser = state.user ? { ...state.user, ...action.payload.data } : action.payload.data;
      return {
        ...state,
        loading: false,
        loaded: true,
        user: currUser,
        email: action.payload.data.email
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    case LOAD_PERMISSION:
      return {
        ...state,
        loadingPermission: true,
        loadedPermission: false
      };
    case LOAD_PERMISSION_SUCCESS:
      const currUserpermissions = state.permissions ? { ...state.permissions, ...action.payload.data } : action.payload.data;
      return {
        ...state,
        loadingPermission: false,
        loadedPermission: true,
        permissions: currUserpermissions && currUserpermissions.internalProducts && currUserpermissions.internalProducts ? getAllPermissions(currUserpermissions.internalProducts) : [0]
      };
    case LOAD_PERMISSION_FAIL:
      return {
        ...state,
        loadingPermission: false,
        loadedPermission: false,
        error: action.error
      };
    default:
      return state;
  }
}
export default authReducer;
