import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { MozartTextBox, MozartSelectField } from '@pgforsta/mozart_ui';
import './Share.scss';

class Share extends Component {
  constructor(props) {
    super(props);
    this.show = true;
    this.state = {
      email: []
    };
  }

  getShareForm() {
    const formDiv = [];
    const prefilData = [];
    if (this.props.shareReviewEmail && this.props.shareReviewEmail.length > 0) {
      this.props.shareReviewEmail.forEach((item) => {
        prefilData.push({ id: item, value: item, text: item });
      });
    }

    formDiv.push(<MozartSelectField
      id="recipients"
      name="recipients"
      hintText="Enter the emails"
      emailValidation
      customClassName="custom_email_select"
      onChange={(event) => { this.handleShareFormChangeByFieldEmail(event, this); }}
      selected={this.props.shareReviewEmail}
      defaultValue={this.props.shareReviewEmail}
      data={prefilData}
      border={false}
      allowCreate
      multiselect
    />);
    if ((this.state.email && this.state.email.length === 0 && prefilData.length === 0)) {
      formDiv.push(<p className="email-validaion-error">Please enter valid email(s)</p>);
    }
    formDiv.push(<MozartTextBox
      key="subject"
      id="subject"
      className="subject"
      name="subject"
      hintText="Enter Subject"
      multiLine={false}
      value={this.props.shareReviewSubject}
      onChange={(event) => { this.handleShareFormChangeByField('subject', event); }}
    />);
    formDiv.push(<MozartTextBox
      key="message"
      id="message"
      className="message"
      name="message"
      hintText="Add Message"
      value={this.props.shareReviewMessage}
      multiLine
      onChange={(event) => { this.handleShareFormChangeByField('message', event); }}
    />);
    return (formDiv);
  }

  handleShareFormChangeByFieldEmail(values) {
    const { handleShareFormChanges } = this.props;
    const emailItems = [];
    if (values) {
      Object.entries(values).forEach((item, key) => {
        emailItems.push(values[key].value);
      });
    }
    this.setState({
      email: emailItems
    }, () => {
      handleShareFormChanges('email', this.state.email);
    });
  }

  handleShareFormChangeByField(type, val) {
    const { handleShareFormChanges } = this.props;
    if (type) {
      handleShareFormChanges(type, val);
    }
  }

  render() {
    return (
      <div className="share-form">
        {this.getShareForm()}
      </div>
    );
  }
}

Share.propTypes = {
  handleShareFormChanges: PropTypes.func,
  shareReviewEmail: PropTypes.array,
  shareReviewSubject: PropTypes.string,
  shareReviewMessage: PropTypes.string
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({}, dispatch)
);

const mapStateToProps = state => ({
  documentReducer: state.documentReducer,
  categoryReducer: state.categoryReducer.category,
  contentId: state.documentAutoGenerateReducer.contentId
});

export default connect(mapStateToProps, mapDispatchToProps)(Share);
