import { MozartSelectField } from '@pgforsta/mozart_ui';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

class Source extends Component {
  constructor(props) {
    super(props);
    this.inputSelect = 'UserBucket';
    this.select = React.createRef();
  }

  getSources() {
    const dataObj = this.props.sources;
    const sourceData = [];
    const result = Object.values(dataObj);
    if (result.length > 0) {
      result.forEach((data) => {
        let sourceName = data.name;
        if (data.id === 100000) {
          sourceName = 'All Source';
        }
        sourceData.push({ value: data.id, id: data.id, text: sourceName });
      });
    }
    return (
      <div style={{ float: 'right', display: 'flex' }}>
        <MozartSelectField
          data={sourceData}
          onChange={(data) => { this.handleSource(data); }}
          width="200px"
          // defaultValue={result[0] ? result[0].id : null}
          hintText="Select Source"
        />
      </div>
    );
  }

  handleSource(data) {
    this.props.handleOnChange(data, 'sourceId');
  }

  render() {
    return (
      <div>
        {this.getSources()}
      </div>
    );
  }
}

Source.propTypes = {
  sources: PropTypes.array,
  handleOnChange: PropTypes.func,
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({ }, dispatch)
);

const mapStateToProps = state => ({
  clientReducer: state.clientReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(Source);
