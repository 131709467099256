import {
  LOAD,
  LOAD_SUCCESS,
  LOAD_FAIL,
  LOAD_CLIENT_PRIORITY,
  LOAD_CLIENT_PRIORITY_SUCCESS,
  LOAD_CLIENT_PRIORITY_FAIL,
  LOAD_SOURCE_PRIORITY_FAIL,
  LOAD_SOURCE_PRIORITY,
  LOAD_SOURCE_PRIORITY_SUCCESS,
} from 'redux/actions/priorityAction';

const initialState = {
  loaded: false,
  loading: false,
  loadingClientPriority: false,
  loadedClientPriority: false,
  data: [],
  clientData: [],
  sourceData: []
};

function priorityReducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case LOAD_SUCCESS:
      const priorityData = state.data ? { ...state.data, ...action.payload.data } : action.payload.data;
      return {
        ...state,
        loading: false,
        loaded: true,
        data: priorityData,
        clientData: [],
        sourceData: []
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
        selectedQueue: 0
      };
    case LOAD_CLIENT_PRIORITY:
      return {
        ...state,
        loadingClientPriority: true,
        loadedClientPriority: false
      };
    case LOAD_CLIENT_PRIORITY_SUCCESS:
      const clientPriorityData = action.payload.data;
      return {
        ...state,
        loadingClientPriority: false,
        loadedClientPriority: true,
        clientData: clientPriorityData
      };
    case LOAD_CLIENT_PRIORITY_FAIL:
      return {
        ...state,
        loadingClientPriority: false,
        loadedClientPriority: false,
        error: action.error,
      };
    case LOAD_SOURCE_PRIORITY:
      return {
        ...state,
        loadingSourcePriority: true,
        loadedSourcePriority: false
      };
    case LOAD_SOURCE_PRIORITY_SUCCESS:
      const sourceObj = action.payload.data;
      return {
        ...state,
        loadingSourcePriority: false,
        loadedSourcePriority: true,
        sourceData: sourceObj
      };
    case LOAD_SOURCE_PRIORITY_FAIL:
      return {
        ...state,
        loadingSourcePriority: false,
        loadedSourcePriority: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export default priorityReducer;
