import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadStatus } from 'redux/actions/reportMonitorAction';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import { NotAvailable } from 'components';
import { MozartButton, MozartTable, MozartLoading } from '@pgforsta/mozart_ui';

function Status(props) {
  const {
    data, filter, loading, openFilter
  } = props;

  useEffect(() => {
    props.loadStatus(filter);
  }, [filter]);

  const getMonitorInfo = () => {
    const headerBlock = (
      <div>
        <Row className="monitorHeader">
          <Col xs="2" md="2" className="infoBlock">
            <div className="infoBlockTitle">STATUS</div>
            <div className="infoBlockTitleValue">{getFilterInfo('status')}</div>
          </Col>
          <Col xs="2" md="2" className="infoBlock">
            <div className="infoBlockTitle">CLIENT</div>
            <div className="infoBlockTitleValue">{getFilterInfo('clients')}</div>
          </Col>
          <Col xs="2" md="2" className="infoBlock">
            <div className="infoBlockTitle">USER</div>
            <div className="infoBlockTitleValue">{getFilterInfo('users')}</div>
          </Col>
          <Col xs="2" md="2" className="infoBlock">
            <div className="infoBlockTitle">REPORT</div>
            <div className="infoBlockTitleValue">{getFilterInfo('reports')}</div>
          </Col>
          <Col xs="2" md="2" className="infoBlock">
            <div className="infoBlockTitle">DATE RANGE</div>
            {/* <div className="infoBlockTitleValue">04/12/2012 - 04/12/2012</div> */}
            <div className="infoBlockTitleValue">{getFilterInfo('dateRange')}</div>
          </Col>
          <Col xs="2" md="2" className="infoBlock">
            <div className="infoBlockTitle">
              <MozartButton
                id="filter"
                data={(
                  <span>
                    Filter
                    <i className="icon-Configuration filterpaddingIcon" />
                  </span>
                )}
                onClick={() => openFilter()}
                background
                style={{
                  width: '1em',
                  marginTop: '5px'
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
    return headerBlock;
  };

  const getFilterInfo = (type) => {
    const info = [];
    switch (type) {
      case 'status':
        if (filter && filter.status && filter.status.length > 0) {
          filter.status.forEach(status => {
            if (status === 'pending') info.push('Pending');
            if (status === 'inprogress') info.push('In Progress');
            if (status === 'completed') info.push('Completed');
            if (status === 'error') info.push('Error');
          });
        }
        break;
      case 'clients':
        if (filter && filter.clientNames && filter.clientNames.length > 0) {
          filter.clientNames.forEach(client => info.push(client));
        }
        break;
      case 'users':
        if (filter && filter.userNames && filter.userNames.length > 0) {
          filter.userNames.forEach(user => info.push(user));
        }
        break;
      case 'reports':
        if (filter && filter.reportNames && filter.reportNames.length > 0) {
          filter.reportNames.forEach(report => info.push(report));
        }
        break;
      case 'dateRange':
        if (filter && filter.dateRange && filter.dateRange.split('_').length > 0) {
          info.push(
            moment(filter.dateRange.split('_')[0]).format('MM/DD/YYYY') +
            ' - ' +
            moment(filter.dateRange.split('_')[1]).format('MM/DD/YYYY')
          );
        }
        break;
      default:
        break;
    }
    return info.length ? info.join(', ') : '--';
  };

  const getTitleData = () => {
    let DATA = [];
    DATA = [
      <div style={{ paddingLeft: '15px' }}>DATE</div>,
      <div>CLIENT</div>,
      <div>REPORT</div>,
      <div>USER</div>,
      <div>STATUS</div>,
      <div>RUNNING TIME</div>,
      <div>NEXT RUN DATE</div>
    ];
    return DATA;
  };

  const getRowData = () => {
    const DATA = [];
    if (!loading && data) {
      data.forEach(row => {
        const listingRender = [];
        listingRender.push(
          getDate(row),
          getClient(row),
          getReport(row),
          getUser(row),
          getStatus(row),
          getRunningTime(row),
          getNextRunDate(row)
        );
        DATA.push(listingRender);
      });
    }
    return DATA;
  };

  const getDate = (row) => {
    const cell = (<div className="cellview">{moment(row.pushedTime).format('M/D/YY')}</div>);
    return cell;
  };

  const getClient = (row) => {
    const cell = (
      <div className="cellview">
        {row.clientId + ' '}
        <span
          title={row.historyId}
          className="icon-InformationCircle"
          onClick={() => enableLogs(row.historyId)}
          style={{ color: 'blue', cursor: 'pointer' }}
        />
      </div>
    );
    return cell;
  };

  const enableLogs = (historyId) => {
    window.open(
      process.env.REACT_APP_REPORTS_API_HOST + '/api/report-history/' + historyId + '/logs',
      '_blank'
    );
  };

  const getReport = (row) => {
    const cell = (<div className="cellview">{row.reportName}</div>);
    return cell;
  };

  const getUser = (row) => {
    const cell = (<div className="cellview">{row.userId}</div>);
    return cell;
  };

  const getStatus = (row) => {
    const cell = (<div className="cellview">{row.status}</div>);
    return cell;
  };

  const getRunningTime = (row) => {
    const cell = (<div className="cellview">{row.runningTime ? row.runningTime : '--'}</div>);
    return cell;
  };

  const getNextRunDate = (row) => {
    const cell = (<div className="cellview">{row.nextRunTime ? moment(row.nextRunTime).format('M/D/YY,hh:mm a') : '--'}</div>);
    return cell;
  };

  return (
    <div className="PageBlock">
      {loading && <MozartLoading />}
      {getMonitorInfo()}
      {data && (
        <MozartTable
          id="action"
          headers={getTitleData()}
          rows={getRowData()}
          bodyStyle={{ fontSize: '0.8em', color: '#666', borderTop: '19px solid #FFFFFF' }}
          colStyle={{ height: '40px', textAlign: 'center' }}
          bgRowColors={['#F5F4FF', '#FFFFFF']}
          rowStyle={{ borderBottom: '5px' }}
          headStyle={{
            textAlign: 'left',
            paddingLeft: '27px',
            height: '13px',
            width: 'auto',
            color: '#FFFFFF',
            fontFamily: 'Roboto',
            fontSize: '13px',
            fontWeight: '500',
            letterSpacing: '1.1px',
            lineHeight: '13px'
          }}
        />
      )}
      {!data && <NotAvailable loadingMessage="No records available!" />}
    </div>
  );
}

Status.propTypes = {
  data: PropTypes.array,
  filter: PropTypes.object,
  loading: PropTypes.bool,
  loadStatus: PropTypes.func,
  openFilter: PropTypes.func
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    loadStatus
  }, dispatch)
);

const mapStateToProps = (state) => ({
  data: state.reportMonitorReducer.data,
  loading: state.reportMonitorReducer.loading
});

export default connect(mapStateToProps, mapDispatchToProps)(Status);
