import React, { useState } from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { saveSentimentDocument, sentimentUpdateContent } from 'redux/actions/SentimentAction';
import {
  Insight, AddNewInsight, OpinionFeature
} from 'components';
import {
  MozartIcon, MozartPopover, MozartModal
} from '@pgforsta/mozart_ui';
import './Sentence.scss';

function Sentence(props) {
  const {
    sentenceIndex, sentence, category, fullReview,
    subCategory, contentId, documentReducer,
    getNewSentenceSentimentObj, subCategories
  } = props;
  const {
    providerLocation, surveyQuestion
  } = documentReducer.documentData;
  const newSentimentItems = [];
  let sentenceCustomText = '';

  let [currentSentence, setCurrentSentence] = '';

  const sentenceItem = JSON.parse(JSON.stringify(sentence));
  sentenceItem.insights.forEach(async (insight, index) => {
    sentenceItem.insights[index].subCategory = subCategories;
    sentenceItem.insights[index].previousSentiment = sentenceItem.insights[index].sentiment;
    sentenceItem.insights[index].previousCategory = sentenceItem.insights[index].categoryId;
    sentenceItem.insights[index].previousCategoryName = sentenceItem.insights[index].category;
    sentenceItem.insights[index].previousSubCategory = sentenceItem.insights[index].subCategory;
    sentenceItem.insights[index].previousTheme = sentenceItem.insights[index].theme;
    sentenceItem.insights[index].previousThemeId = sentenceItem.insights[index].themeId;
  });

  let [currentSentenceInsight, setCurrentSentenceInsight] = '';
  [currentSentenceInsight, setCurrentSentenceInsight] = useState('');
  const [opinionModal, setOpinionModal] = useState(false);
  const [pickedOpinion, setPickedOpinion] = useState([]);
  const [pickedFeature, setPickedFeature] = useState([]);
  const [newSentiment, setNewSentiment] = useState([]);
  const [currentInsightIndex, setCurrentInsightIndex] = useState(null);
  [currentSentence, setCurrentSentence] = useState(sentenceItem);
  const [sentenceKey, setSentenceKey] = useState(Math.random());
  const [openEditor, setOpenEditor] = useState(false);
  const opinion = '';
  const feature = '';
  const entity = '';
  let enableClass = 'add-button-disable';
  let passDataStatus = false;
  const warningMessage = '*Max 250 characters';
  if (newSentiment && newSentiment.insight && ((newSentiment.insight.opinion && newSentiment.insight.opinion.length > 250) || (newSentiment.insight.feature && newSentiment.insight.feature.length > 250) || (newSentiment.insight.featureAltKeyword && newSentiment.insight.featureAltKeyword.length > 250))) {
    enableClass = 'add-button-disable';
  } else if (newSentiment && newSentiment.insight && newSentiment.insight.opinion && (newSentiment.insight.feature || newSentiment.insight.featureAltKeyword)) {
    enableClass = 'add-button-enable';
    passDataStatus = true;
  }

  const sentenceInfoBlock = (
    <MozartPopover
      icon={<a style={{ cursor: 'pointer', color: '#17A589', fontSize: '12px' }} href>view more</a>}
      style={{
        minWidth: '600px', padding: '20px 10px', maxHeight: 'auto', zIndex: 100000, position: 'absolute', border: '1px solid #D3CFF8', boxShadow: 'unset'
      }}
      node={(
        <div>
          {currentSentence.text ? currentSentence.text.replace(/[[\]{}(//)*+@\\^$|#<>]/ig, '').replace(/([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF])/g, '') : ''}
        </div>
      )}
    />
  );
  sentenceCustomText = currentSentence.text && currentSentence.text.length >= 250 ?
    (
      <div>
        <div>
          {currentSentence.text.substring(0, 250).replace(/[[\]{}(//)*+@\\^$|#<>]/ig, '').replace(/([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF])/g, '') + '...'}
          <div>{sentenceInfoBlock}</div>
        </div>
      </div>
    ) : currentSentence.text.replace(/[[\]{}(//)*+@\\^$|#<>]/ig, '').replace(/([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF])/g, '');

  const getInsights = (insights) => {
    const insightsDiv = [];
    if (insights && insights.length > 0) {
      insights.forEach((insight, index) => {
        insightsDiv.push(
          <Insight
            category={category}
            index={index}
            insight={insight}
            sentencesId={currentSentence.id}
            openEditor={openEditor}
            handleCategoryChange={handleCategoryChange}
            handleCategoryThemeChange={handleCategoryThemeChange}
            handleSentiment={handleSentiment}
            handleSaveSentiment={handleSaveSentiment}
            handleResetSentiment={handleResetSentiment}
            handleDeleteSentimentModal={handleDeleteSentimentModal}
            handleDeleteSentimentById={handleDeleteSentimentById}
            handleOpinionModal={handleOpinionModal}
          />
        );
      });
    }
    return (
      <div>
        <div>{insightsDiv}</div>
        <div>{newSentimentItems}</div>
      </div>
    );
  };

  const handleSentiment = (insightIndexId, sentenceId, value) => {
    currentSentence.insights[insightIndexId].sentiment = value;
    setCurrentSentence(currentSentence);
  };

  const handleCategoryChange = (data, insightIndexId) => {
    currentSentence.insights[insightIndexId].categoryId = data;
    category.children.forEach((categoryItem) => {
      if (categoryItem && categoryItem.id === data) {
        currentSentence.insights[insightIndexId].subCategory = categoryItem.children && categoryItem.children.length > 0 ? categoryItem.children : [categoryItem];
        currentSentence.insights[insightIndexId].category = categoryItem.name;
        currentSentence.insights[insightIndexId].categoryName = categoryItem.name;
        currentSentence.insights[insightIndexId].theme = null;
        currentSentence.insights[insightIndexId].themeName = null;
        currentSentence.insights[insightIndexId].themeId = null;
      }
    });
    setCurrentSentence(currentSentence);
  };

  const handleCategoryThemeChange = (data, insightIndexId) => {
    currentSentence.insights[insightIndexId].themeId = data;
    subCategory.forEach((categoryItem) => {
      if (categoryItem && categoryItem.id === data) {
        currentSentence.insights[insightIndexId].themeName = categoryItem.name;
        currentSentence.insights[insightIndexId].theme = categoryItem.name;
      }
    });
    setCurrentSentence(currentSentence);
  };

  const handleSaveSentiment = (insightIndexId, sentencesId, insightId) => {
    const updateStatus = (insightId && insightId > 0) ? insightId : 0;
    if (sentencesId && updateStatus === 0) {
      const insightData = currentSentence.insights[insightIndexId];
      const subCategoryObj = currentSentence.insights[insightIndexId].subCategory;
      if (!documentReducer.loading) {
        props.saveSentimentDocument(contentId, sentencesId, insightData).then((response) => {
          if (response && response.payload.data && response.payload.data.id) {
            currentSentence.insights[insightIndexId] = response.payload.data;
            if (response.payload.data && response.payload.data.id > 0) {
              currentSentence.numberOfInsightsByManual = currentSentence.numberOfInsightsByManual >= 0 ? currentSentence.numberOfInsightsByManual + 1 : 0;
              currentSentence.insights[insightIndexId].previousCategory = response.payload.data.categoryId;
              currentSentence.insights[insightIndexId].previousCategoryName = response.payload.data.category;
              currentSentence.insights[insightIndexId].previousTheme = response.payload.data.theme;
              currentSentence.insights[insightIndexId].previousThemeId = response.payload.data.themeId;
              currentSentence.insights[insightIndexId].previousSentiment = response.payload.data.sentiment;
            }
            currentSentence.insights[insightIndexId].subCategory = subCategoryObj;
            setSentenceKey(Math.random());
            setCurrentSentence(currentSentence);
            props.handleSentimentCountChange('add');
          }
        });
      }
    } else {
      const insightData = currentSentence.insights[insightIndexId];
      const previousSentimentType = currentSentence.insights[insightIndexId].sentimentByNlp;
      props.sentimentUpdateContent(insightId, insightData).then((response) => {
        if (response && response.payload.data && response.payload.data.id) {
          const subCategoryObj = currentSentence.insights[insightIndexId].subCategory;
          currentSentence.insights[insightIndexId] = response.payload.data;
          currentSentence.insights[insightIndexId].subCategory = subCategoryObj;
          if (response.payload.data && response.payload.data.id > 0) {
            if (previousSentimentType) {
              currentSentence.numberOfInsightsByNLP = currentSentence.numberOfInsightsByNLP > 0 ? currentSentence.numberOfInsightsByNLP - 1 : 0;
              currentSentence.insights[insightIndexId].sentimentByNlp = false;
              currentSentence.numberOfInsightsByManual = currentSentence.numberOfInsightsByManual >= 0 ? currentSentence.numberOfInsightsByManual + 1 : 0;
            }
            currentSentence.insights[insightIndexId].previousCategory = response.payload.data.categoryId;
            currentSentence.insights[insightIndexId].previousCategoryName = response.payload.data.category;
            currentSentence.insights[insightIndexId].previousTheme = response.payload.data.theme;
            currentSentence.insights[insightIndexId].previousThemeId = response.payload.data.themeId;
            currentSentence.insights[insightIndexId].previousSentiment = response.payload.data.sentiment;
          }
          if (response.payload.data && response.payload.data.score) {
            let sentimentNew = 'neutral';
            if (response.payload.data.score > 1) {
              sentimentNew = 'positive';
            } else if (response.payload.data.score < 0) {
              sentimentNew = 'negative';
            }
            currentSentence.insights[insightIndexId].sentiment = sentimentNew;
          }
          setSentenceKey(Math.random());
          setCurrentSentence(currentSentence);
        }
      });
    }
  };

  const handleDeleteSentimentModal = (index, sentencesId, insightId) => {
    if (!(insightId && insightId > 0)) {
      delete currentSentence.insights[index];
      setSentenceKey(Math.random());
    }
  };

  const handleDeleteSentimentById = (sentenceId, index) => {
    if (currentSentence.insights[index].sentimentByNlp) {
      currentSentence.numberOfInsightsByNLP = currentSentence.numberOfInsightsByNLP > 0 ? currentSentence.numberOfInsightsByNLP - 1 : 0;
    } else {
      currentSentence.numberOfInsightsByManual = currentSentence.numberOfInsightsByManual > 0 ? currentSentence.numberOfInsightsByManual - 1 : 0;
    }
    delete currentSentence.insights[index];
    props.handleSentimentCountChange('delete');
    setSentenceKey(Math.random());
  };

  const handleResetSentiment = (insightIndexId, sentencesId, insightId) => {
    if (insightId > 0) {
      currentSentence.insights[insightIndexId].sentiment = currentSentence.insights[insightIndexId].previousSentiment;
      currentSentence.insights[insightIndexId].categoryId = currentSentence.insights[insightIndexId].previousCategory;
      currentSentence.insights[insightIndexId].category = currentSentence.insights[insightIndexId].previousCategoryName;
      currentSentence.insights[insightIndexId].categoryName = currentSentence.insights[insightIndexId].previousCategoryName;
      if (currentSentence.insights[insightIndexId].previousThemeId) {
        currentSentence.insights[insightIndexId].theme = currentSentence.insights[insightIndexId].previousTheme;
        currentSentence.insights[insightIndexId].themeId = currentSentence.insights[insightIndexId].previousThemeId;
        currentSentence.insights[insightIndexId].themeName = currentSentence.insights[insightIndexId].previousTheme;
      }
      category.children.forEach((categoryItem) => {
        if (categoryItem.id === currentSentence.insights[insightIndexId].previousCategory) {
          currentSentence.insights[insightIndexId].subCategory = categoryItem.children && categoryItem.children.length > 0 ? categoryItem.children : [categoryItem];
        }
      });
    } else {
      currentSentence.insights[insightIndexId].sentiment = null;
      currentSentence.insights[insightIndexId].categoryId = null;
      currentSentence.insights[insightIndexId].category = null;
      currentSentence.insights[insightIndexId].categoryName = null;
      currentSentence.insights[insightIndexId].theme = null;
      currentSentence.insights[insightIndexId].themeId = null;
      category.children.forEach((categoryItem) => {
        if (categoryItem.id === currentSentence.insights[insightIndexId].previousCategory) {
          currentSentence.insights[insightIndexId].subCategory = categoryItem.children && categoryItem.children.length > 0 ? categoryItem.children : [categoryItem];
        }
      });
    }
    setSentenceKey(Math.random());
    setCurrentSentence(currentSentence);
  };

  const handleNewSentiment = () => {
    const insightLength = currentSentence.insights && currentSentence.insights !== null ? currentSentence.insights.length : 0;
    if (insightLength === 0) {
      currentSentence.insights = [];
    }
    currentSentence.insights = currentSentence.insights.concat(getNewSentenceSentimentObj());
    setCurrentSentence(currentSentence);
  };

  const handleOpinionModal = (insightLength) => {
    const pickedOpinionItems = [];
    const pickedFeatureItems = [];
    if (currentSentence.insights && currentSentence.insights.length > 0) {
      currentSentence.insights.forEach((insight) => {
        if (insight && insight.id !== null) {
          pickedOpinionItems.push(insight.opinion);
          pickedFeatureItems.push(insight.feature);
        }
      });
    }
    if (!currentSentence.insights[insightLength].newSentimentStatus) {
      currentSentence.insights[insightLength].newSentimentStatus = false;
    }
    currentSentenceInsight = currentSentence.insights[insightLength];
    setOpinionModal(true);
    setCurrentSentenceInsight(currentSentenceInsight);
    setCurrentInsightIndex(insightLength);
    setPickedOpinion(pickedOpinionItems);
    setPickedFeature(pickedFeatureItems);
    setOpenEditor(true);
  };

  const handleOpinionFeature = () => {
    if (newSentiment && newSentiment.sentenceId > 0 && currentSentence.id === newSentiment.sentenceId && passDataStatus) {
      currentSentence.insights[currentInsightIndex] = newSentiment.insight;
      if (newSentiment.insight.featureAltKeyword) {
        currentSentence.insights[currentInsightIndex].featureAltKeyword = newSentiment.insight.featureAltKeyword ? newSentiment.insight.featureAltKeyword : null;
      }
      currentSentence.insights[currentInsightIndex].newSentimentStatus = true;
      currentSentence.insights[currentInsightIndex].requiredField = true;
      setSentenceKey(Math.random());
      setCurrentSentence(currentSentence);
      setOpinionModal(false);
      setOpenEditor(false);
    }
  };

  const updateInsightsValues = (insightsObj, sentenceIdPresent, index) => {
    const newObj = [];
    newObj.insight = insightsObj;
    if (insightsObj && insightsObj.featureAltKeyword !== null) {
      newObj.insight.featureKeyword = null;
      newObj.insight.featureAltKeyword = insightsObj.featureAltKeyword;
    }
    newObj.sentenceId = sentenceIdPresent;
    newObj.index = index;
    newObj.status = insightsObj.newSentimentStatus;
    setNewSentiment(newObj);
  };

  const showHideOpinionModal = () => {
    setOpenEditor(false);
    setOpinionModal(false);
    if (currentSentence.id && currentInsightIndex && !currentSentence.insights[currentInsightIndex].requiredField) {
      currentSentence.insights[currentInsightIndex].opinion = null;
      currentSentence.insights[currentInsightIndex].feature = null;
      currentSentence.insights[currentInsightIndex].featureAltKeyword = null;
      currentSentence.insights[currentInsightIndex].featureKeyword = null;
      setCurrentSentence(currentSentence);
    }
  };

  return (
    <div style={{ marginBottom: '10px' }} key={sentenceKey}>
      <div className="sentence-card" style={{ height: '90px' }}>
        <div xs={1} md={1} className="sentence-card-left no-padding-sentence" style={{ height: '90px', paddingTop: '15px', paddingLeft: '20px' }}>
          <div className="number-circle-icon" style={{ paddingLeft: '0' }}>
            <MozartIcon width="32px" height="32px" data={sentenceIndex + 1} />
          </div>
        </div>
        <div xs={10} md={10} className="sentence-card-middle no-padding-sentence" style={{ height: '90px' }}>
          <div className="sentence-line">
            <div className="sentiment-sentence-title">
              <span className="sentence-id-text"> Sentence ID :</span>
              <span className="sentence-id">{currentSentence.id}</span>
            </div>
            <div className="sentiment-sentence">{sentenceCustomText}</div>
          </div>
        </div>
        <div xs={1} md={1} className="sentence-card-right no-padding-sentence" style={{ height: '90px' }}>
          <div className="sentiment-count" style={{ paddingTop: '14px' }}>
            <div className="nlp-sentiment-count">
              <span className="nlp-count">{currentSentence.numberOfInsightsByNLP}</span>
              <span className="nlp-text"> NLP</span>
            </div>
            <div className="nlp-sentiment-count">
              <span className="nlp-count">{currentSentence.numberOfInsightsByManual}</span>
              <span className="nlp-text"> Manual</span>
            </div>
          </div>
          <AddNewInsight
            currentSentence={currentSentence}
            handleNewSentiment={handleNewSentiment}
            handleOpinionModal={handleOpinionModal}
          />
        </div>
      </div>
      {getInsights(currentSentence.insights)}
      {opinionModal &&
        [
          <MozartModal
            show={opinionModal}
            onClose={showHideOpinionModal}
            title="Select Opinion and feature"
            width="975px"
            contentPadding="20px"
            className="review-modal"
            key={opinionModal}
            submitLabel="Add"
            handleSubmit={() => { handleOpinionFeature(); }}
            id={opinionModal}
            submitButtonClassName={enableClass}
            enableCancel
          >
            <OpinionFeature
              testProp=""
              entity={entity}
              opinion={opinion}
              feature={feature}
              id="opinionModal"
              fullReview={fullReview}
              pickedOpinion={pickedOpinion}
              pickedFeature={pickedFeature}
              surveyQuestion={surveyQuestion}
              warningMessage={warningMessage}
              currentSentence={currentSentence}
              providerLocation={providerLocation}
              currentSentenceId={currentSentence.id}
              currentInsightIndex={currentInsightIndex}
              onChangeInsightsValues={updateInsightsValues}
              currentSentenceInsight={currentSentenceInsight}
            />
          </MozartModal>
        ]}
    </div>
  );
}

Sentence.propTypes = {
  contentId: propTypes.number,
  sentenceIndex: propTypes.number,
  fullReview: propTypes.string,
  category: propTypes.object,
  sentence: propTypes.object,
  documentReducer: propTypes.object,
  getNewSentenceSentimentObj: propTypes.object,
  subCategory: propTypes.array,
  subCategories: propTypes.array,
  saveSentimentDocument: propTypes.func,
  sentimentUpdateContent: propTypes.func,
  handleSentimentCountChange: propTypes.func,
};

const mapStateToProps = state => ({
  documentReducer: state.documentReducer,
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    saveSentimentDocument, sentimentUpdateContent
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(Sentence);
