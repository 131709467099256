import { MozartIcon } from '@pgforsta/mozart_ui';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './ReviewPopUp.scss';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

class ReviewPopUp extends Component {
  constructor(props) {
    super(props);
    this.inputSelect = 'UserBucket';
    this.show = true;
  }

  getReviewSentiments() {
    return (
      <div className="reviewsSentimentsBlock">
        <Col xs={12} md={12} className="review-sentiment-content-section">
          <div>{this.props.fullReview}</div>
        </Col>
        <div className="sentiments-block-list">{this.getSentenceItems()}</div>
      </div>
    );
  }

  getSentenceItems() {
    const dataDiv = [];
    if (this.props.sentences && this.props.sentences.length > 0) {
      let sentenceCount = 0;
      this.props.sentences.map((sentence) => {
        const indexKey = dataDiv.length;
        dataDiv.push(
          <div className="sentiments">
            <Row>
              <Col xs={12} md={12}>
                <div className="sentence-card" style={{ height: 'auto' }}>
                  <div xs={1} md={1} className="sentence-card-left no-padding-sentence" style={{ height: 'auto' }}>
                    <div className="number-circle-icon">
                      <MozartIcon width="40px" height="40px" data={indexKey + 1} />
                    </div>
                  </div>
                  <div xs={10} md={10} className="sentence-card-middle no-padding-sentence" style={{ height: 'auto' }}>
                    <div className="sentence-line">
                      <div className="sentiment-sentence-title">
                        <span className="sentence-id-text"> Sentence ID :</span>
                        <span className="sentence-id">{sentence.id}</span>
                      </div>
                      <div className="sentiment-sentence" style={{ height: 'auto' }}>{sentence.text}</div>
                    </div>
                  </div>
                  <div xs={1} md={1} className="sentence-card-right no-padding-sentence" style={{ height: 'auto' }}>
                    <div className="sentiment-count">
                      <div className="nlp-sentiment-count">
                        <span className="nlp-count">{sentence.numberOfInsightsByNLP}</span>
                        <span className="nlp-text"> NLP</span>
                      </div>
                      <div className="nlp-sentiment-count">
                        <span className="nlp-count">{sentence.numberOfInsightsByManual}</span>
                        <span className="nlp-text"> Manual</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        );
        sentenceCount += sentenceCount + 1;
        return true;
      });
    }
    return (<div>{dataDiv}</div>);
  }

  render() {
    return (
      <div style={{ width: '935px' }}>
        {this.getReviewSentiments()}
      </div>
    );
  }
}

ReviewPopUp.propTypes = {
  sentences: PropTypes.array,
  fullReview: PropTypes.string
};

export default ReviewPopUp;
