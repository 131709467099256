import {
  QCLEVEL,
  QCLEVEL_SUCCESS,
  QCLEVEL_FAIL,
  REFER,
  REFER_SUCCESS,
  REFER_FAIL,
  TOGGLE,
  TOGGLE_SUCCESS,
  TOGGLE_FAIL,
  CONTENT_DELETE,
  CONTENT_DELETE_SUCCESS,
  CONTENT_DELETE_FAIL
} from 'redux/actions/contentAction';

const initialState = {
  loaded: false,
  loading: false,
  deleteContentStatus: ''
};

function contentReducer(state = initialState, action = {}) {
  switch (action.type) {
    case QCLEVEL:
    case REFER:
    case TOGGLE:
    case CONTENT_DELETE:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case QCLEVEL_FAIL:
    case REFER_FAIL:
    case TOGGLE_FAIL:
    case CONTENT_DELETE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    case QCLEVEL_SUCCESS:
    case REFER_SUCCESS:
    case TOGGLE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true
      };
    case CONTENT_DELETE_SUCCESS:
      const deleteContentStatusCode = action.payload.status ? action.payload.status : '';
      return {
        ...state,
        loading: false,
        loaded: true,
        deleteContentStatus: deleteContentStatusCode
      };
    default:
      return state;
  }
}

export default contentReducer;
