import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Action,
  Status,
  Analysis,
  ActionFilter,
  AnalysisFilter,
  StatusFilter
} from 'components';
import { MozartModal } from '@pgforsta/mozart_ui';
import './Pages/MonitorPage.scss';

function MonitorPage(props) {
  const { page } = props;
  const [filterStatus, setFilterStatus] = useState(false);
  const [filter, setFilter] = useState(null);
  const closeFilter = () => {
    setFilterStatus(false);
  };

  const openFilter = () => {
    setFilterStatus(true);
  };

  const handleFilter = (filters) => {
    setFilter(filters);
    closeFilter();
  };

  return (
    <>
      <MozartModal
        show={filterStatus}
        onClose={() => closeFilter()}
        title={page + ' Filter'}
        className="filterModal"
        width="1300px"
        height="150px"
      >
        <div>
          {page === 'status' && <StatusFilter preFilter={filter} handleFilter={handleFilter} />}
          {page === 'analysis' && <AnalysisFilter preFilter={filter} handleFilter={handleFilter} />}
          {page === 'action' && <ActionFilter preFilter={filter} handleFilter={handleFilter} />}
        </div>
      </MozartModal>
      {page === 'status' && <Status filter={filter} openFilter={openFilter} />}
      {page === 'analysis' && <Analysis filter={filter} openFilter={openFilter} />}
      {page === 'action' && <Action filter={filter} openFilter={openFilter} />}
    </>
  );
}

MonitorPage.propTypes = {
  page: PropTypes.string.isRequired
};

const mapDispatchToProps = (dispatch) => {
  bindActionCreators({}, dispatch);
};

const mapStateToProps = (state) => ({
  user: state.authReducer.user
});

export default connect(mapStateToProps, mapDispatchToProps)(MonitorPage);
