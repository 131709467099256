import { MozartIcon, MozartImage, MozartNavigationItem } from '@pgforsta/mozart_ui';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import reactCookie from 'react-cookie';
import { logout } from 'utils/util-element';

class Profile extends Component {
  getImage() {
    let src = '';
    if (this.props.propfileImageUrl) {
      src = this.props.propfileImageUrl;
    }
    const iconStyle = {
      fontSize: '1.8em',
      paddingTop: '6px',
      marginLeft: '8px'
    };
    return src ? <MozartImage style={{ marginLeft: '5px', width: '30px', height: '30px' }} src={src} variant="overlay-circle" /> : <MozartIcon style={iconStyle} data={<span className="icon-Avatar" />} />;
  }

  getNavigationItems() {
    const data = [];
    data.push({
      itemData: 'Logout',
      onClick: this.handleGoTologout.bind(this)
    });

    return data;
  }

  handleGoTologout() {
    logout();
  }

  handleGoTo(src, refresh, newTab = false) {
    if (newTab) {
      window.open(src);
    } else {
      window.location.href = src;
    }
    this.handleClose();
    if (refresh) {
      window.location.reload();
    }
  }

  handleClose() {
    if (document.getElementById('binary_profile_button') !== null) {
      document.getElementById('binary_profile_button').click();
    }
  }

  handleLogout() {
    // process.env.REACT_APP_LOGIN_HOST
    const cookieName = process.env.REACT_APP_ENV === 'prod' ? 'expiry_time' : process.env.REACT_APP_ENV + '_expiry_time';
    const cookiePath = '/';
    const cookieDomain = process.env.REACT_APP_ENV.cookieDomain ? process.env.REACT_APP_ENV.cookieDomain : '';

    reactCookie.remove(cookieName, { path: cookiePath, domain: cookieDomain });
    reactCookie.remove('access_token_expiry_time', { path: cookiePath, domain: cookieDomain });
    reactCookie.remove('access_token_expiry_length', { path: cookiePath, domain: cookieDomain });
    reactCookie.remove('survey_disclaimer_accepted', { path: cookiePath, domain: cookieDomain });
    const sourceUrl = encodeURIComponent(window.location.href.replace('login', '').replace('error', ''));
    const action = process.env.REACT_APP_LOGIN_HOST + '/app/logout?source=' + (sourceUrl !== '' ? sourceUrl : process.env.REACT_APP_REPORTS_HOST);
    const logoutForm = document.createElement('FORM');
    const button = document.createElement('input');
    button.setAttribute('type', 'submit');
    logoutForm.name = 'logout';
    logoutForm.method = 'POST';
    logoutForm.action = action;
    logoutForm.appendChild(button);
    document.body.appendChild(logoutForm);
    logoutForm.submit();
  }

  render() {
    const items = this.getNavigationItems();
    return (
      <div id="binary_profile">
        <MozartNavigationItem
          data={this.getImage()}
          contentStyle={{ right: '40px' }}
          icon
          items={items}
        />
      </div>
    );
  }
}

Profile.propTypes = {
  // loaded: PropTypes.bool,
  propfileImageUrl: PropTypes.string
};
export default connect()(Profile);
