import React, { Component } from "react";
import { Container, Col } from "react-bootstrap";
import { Header, ReviewEdit, ReviewStatus } from "components";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { loadBreadCrums } from "redux/actions/breadcrumAction";
import { loadResponder } from "redux/actions/responderAction";
import { loadTaskUser } from "redux/actions/taskUserAction";
import { load as loadAuth } from "redux/actions/authAction";
import { loadTemplate } from "redux/actions/templateAction";
import { MozartLoading, MozartWidgetLayOut } from "@pgforsta/mozart_ui";
import "./ResponderView.scss";
import { meUserRole } from "utils/util-element";

class ResponderView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewContainer: "RESPONDER",
      data: [],
      load: false,
    };
    meUserRole(this.props.permissions, "RESPONDER");
  }

  componentDidMount() {
    this.props.loadAuth();
    this.props.loadBreadCrums(this.getBreadCrums());
    const role = "RESPONDER";
    this.props.loadTaskUser(role).then(() => {
      if (this.props.task) {
        const { taskId } = this.props.task;
        this.props.loadResponder(taskId, role).then(() => {
          this.setState({ data: this.props.responder });
          this.props.loadTemplate(taskId);
        });
      }
    });
  }

  getBreadCrums() {
    return [{ id: 1, name: "Responder", href: "responder" }];
  }

  forwardData(role) {
    this.props.loadTaskUser(role).then(() => {
      if (this.props.task) {
        const { taskId } = this.props.task;
        if (taskId !== 0) {
          this.props.loadResponder(taskId, role).then(() => {
            this.setState({ load: true }, () => {
              setTimeout(() => {
                this.setState({ load: false }, () => {
                  setTimeout(() => {
                    this.setState({ data: this.props.responder });
                  }, 500);
                });
              }, 3000);
            });
            this.props.loadTemplate(taskId);
          });
        } else {
          window.location.href = "/#/";
        }
      }
    });
  }

  supportData(role) {
    this.props.loadTaskUser(role).then(() => {
      if (this.props.task) {
        const { taskId } = this.props.task;
        if (taskId !== 0) {
          this.props.loadResponder(taskId, role).then(() => {
            this.setState({ load: true }, () => {
              setTimeout(() => {
                this.setState({ load: false }, () => {
                  setTimeout(() => {
                    this.setState({ data: this.props.responder });
                  }, 500);
                });
              }, 3000);
            });
            this.props.loadTemplate(taskId);
          });
        } else {
          window.location.href = "/#/";
        }
      }
    });
  }

  render() {
    const { loadingTask, loadingResponder } = this.props;
    return [
      <Header />,
      <Container fluid className="Responser">
        {(loadingTask || loadingResponder || this.state.load) && (
          <MozartLoading />
        )}
        <Col xs={12} md={12} className="lableHeader">
          Engagement
        </Col>
        {(Object.keys(this.state.data).length === 0 ||
          this.state.data.taskId === 0) && (
          <MozartWidgetLayOut contentStyle={{ height: "100px" }}>
            <div style={{ padding: "1em" }}>
              There is no Task available to engage in any queues
            </div>
          </MozartWidgetLayOut>
        )}
        {this.state.data !== undefined && this.state.data.taskId > 0 && (
          <ReviewStatus
            statusData={this.state.data}
            viewContainer={this.state.viewContainer}
          />
        )}
        {this.state.data !== undefined && this.state.data.taskId > 0 && (
          <ReviewEdit
            key={this.state.data.taskId}
            statusData={this.state.data}
            userInfo={this.props.authReducer.user}
            forwardData={(e) => this.forwardData(e)}
            supportData={(e) => this.supportData(e)}
            tempateArrayD={this.props.template}
            viewContainer={this.state.viewContainer}
          />
        )}
      </Container>,
    ];
  }
}

ResponderView.propTypes = {
  permissions: PropTypes.object,
  loadBreadCrums: PropTypes.func,
  loadResponder: PropTypes.func,
  loadTaskUser: PropTypes.func,
  responder: PropTypes.object,
  task: PropTypes.object,
  loadingResponder: PropTypes.bool,
  loadingTask: PropTypes.bool,
  loadTemplate: PropTypes.func,
  template: PropTypes.object,
  loadAuth: PropTypes.func,
  authReducer: PropTypes.func,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadResponder,
      loadBreadCrums,
      loadTaskUser,
      loadTemplate,
      loadAuth,
    },
    dispatch
  );

function mapStateToProps(state) {
  return {
    responder: state.responderReducer.responder,
    permissions: state.authReducer.permissions,
    task: state.taskUserReducer.task,
    loadingTask: state.taskUserReducer.loadingTask,
    loadingResponder:
      state.responderReducer.loadingResponder ||
      state.approvalReducer.loadingResponder ||
      state.responderReducer.loadingTemplate,
    template: state.templateReducer.template,
    authReducer: state.authReducer,
    // approveAndNext: state.approvalReducer.loadingResponder,
    // getToSupport: state.responderReducer.loadingResponder,
    // loadingResponder: state.responderReducer
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResponderView);
