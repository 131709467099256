import {
  LOAD,
  UPDATE,
  UPDATE_ORIGINAL_DATA_FROM_DATA,
  UPDATE_DATA_FROM_ORIGINAL_DATA,
  RESET,
  LOAD_SAVED_FILTER,
} from 'redux/actions/dateAction';
import { merge } from 'lodash';
import moment from 'moment';
import reactCookie from 'react-cookie';
import { getFilterQueryFromUrl } from 'utils/util-element';

const getDefaultData = () => {
  let startDate = reactCookie.load('start_date')
    ? new Date(reactCookie.load('start_date').replace(/\"/g, ''))
    : moment().subtract(30, 'days').toDate();
  let endDate = reactCookie.load('end_date')
    ? new Date(reactCookie.load('end_date').replace(/\"/g, ''))
    : moment().toDate();
  const specialcase =
    reactCookie.load('last_specialcase') &&
    reactCookie.load('last_specialcase') !== 'null'
      ? parseInt(reactCookie.load('last_specialcase'), 10)
      : null;
  // const specialcase = 365; // initial load date range will be one year
  const totalDays = new Date().getFullYear() % 4 === 0 ? 366 : 365;

  if (parseInt(specialcase, 10) === 1) {
    startDate = moment().toDate();
    endDate = moment().toDate();
    reactCookie.save('start_date', startDate, { path: '/' });
    reactCookie.save('end_date', endDate, { path: '/' });
    reactCookie.save('end_date_min', startDate, { path: '/' });
    reactCookie.save('start_date_max', moment().toDate(), { path: '/' });
  }
  if (parseInt(specialcase, 10) === 7) {
    startDate = moment().subtract(6, 'days').toDate();
    endDate = moment().toDate();
    reactCookie.save('start_date', startDate, { path: '/' });
    reactCookie.save('end_date', endDate, { path: '/' });
    reactCookie.save('end_date_min', startDate, { path: '/' });
    reactCookie.save('start_date_max', moment().toDate(), { path: '/' });
  }
  if (parseInt(specialcase, 10) === 30) {
    startDate = moment().subtract(29, 'days').toDate();
    endDate = moment().toDate();
    reactCookie.save('start_date', startDate, { path: '/' });
    reactCookie.save('end_date', endDate, { path: '/' });
    reactCookie.save('end_date_min', startDate, { path: '/' });
    reactCookie.save('start_date_max', moment().toDate(), { path: '/' });
  }
  if (parseInt(specialcase, 10) === 90) {
    startDate = moment().subtract(89, 'days').toDate();
    endDate = moment().toDate();
    reactCookie.save('start_date', startDate, { path: '/' });
    reactCookie.save('end_date', endDate, { path: '/' });
    reactCookie.save('end_date_min', startDate, { path: '/' });
    reactCookie.save('start_date_max', moment().toDate(), { path: '/' });
  }
  if (parseInt(specialcase, 10) === 365) {
    startDate = moment()
      .subtract(totalDays - 1, 'days')
      .toDate();
    endDate = moment().toDate();
    reactCookie.save('start_date', startDate, { path: '/' });
    reactCookie.save('end_date', endDate, { path: '/' });
    reactCookie.save('end_date_min', startDate, { path: '/' });
    reactCookie.save('start_date_max', moment().toDate(), { path: '/' });
  }
  return {
    start_date: startDate,
    end_date: endDate,
    specialcase: specialcase,
  };
};

const initialState = {
  loaded: false,
  loading: false,
  data: getDefaultData(),
  originalData: getDefaultData(),
  error: false,
};

function dateReducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      const query = getFilterQueryFromUrl('lastRunDate');

      if (query && query[0]) {
        const startDate = moment(query[0].split('_')[0]).toDate();
        const endDate = moment(query[0].split('_')[1]).toDate();
        let specialcase = moment(query[0].split('_')[1])
          .add(1, 'days')
          .diff(moment(query[0].split('_')[0]), 'days');
        const dateOne = moment(endDate).format('YYYY-MM-DD');
        const dateTwo = moment().format('YYYY-MM-DD');
        specialcase =
          specialcase && moment(dateOne).isSame(moment(dateTwo))
            ? specialcase
            : null;
        reactCookie.save('start_date', startDate, { path: '/' });
        reactCookie.save('end_date', endDate, { path: '/' });
        reactCookie.save('end_date_min', startDate, { path: '/' });
        reactCookie.save('start_date_max', moment().toDate(), { path: '/' });
        reactCookie.save('last_specialcase', specialcase, { path: '/' });
      }

      return {
        ...state,
        data: getDefaultData(),
        originalData: getDefaultData(),
      };
    case UPDATE:
      state.data[action.result.name] = action.result.value;
      reactCookie.save('start_date', state.data.start_date, { path: '/' });
      reactCookie.save('end_date', state.data.end_date, { path: '/' });
      reactCookie.save('end_date_min', state.data.start_date, { path: '/' });
      reactCookie.save('start_date_max', moment().toDate(), { path: '/' });
      reactCookie.save('last_specialcase', state.data.specialcase, { path: '/' });
      return {
        ...state,
        data: merge({}, state.data),
      };
    case RESET:
      const resetDate = getDefaultData();
      return {
        ...state,
        data: merge({}, resetDate),
        originalData: merge({}, resetDate),
      };
    case LOAD_SAVED_FILTER:
      const savedDate = action.result;
      if (savedDate) {
        const startDate = savedDate.start_date;
        const endDate = savedDate.end_date;
        let specialcase = moment(endDate)
          .add(1, 'days')
          .diff(moment(startDate), 'days');
        const dateOne = moment(endDate).format('YYYY-MM-DD');
        const dateTwo = moment().format('YYYY-MM-DD');
        specialcase =
          specialcase && moment(dateOne).isSame(moment(dateTwo))
            ? specialcase
            : null;
        reactCookie.save('start_date', startDate, { path: '/' });
        reactCookie.save('end_date', endDate, { path: '/' });
        reactCookie.save('end_date_min', startDate, { path: '/' });
        reactCookie.save('start_date_max', moment().toDate(), { path: '/' });
        reactCookie.save('last_specialcase', specialcase, { path: '/' });
      }
      return {
        ...state,
        data: merge({}, getDefaultData()),
        originalData: merge({}, getDefaultData()),
      };
    case UPDATE_ORIGINAL_DATA_FROM_DATA:
      return {
        ...state,
        originalData: merge({}, state.data),
      };
    case UPDATE_DATA_FROM_ORIGINAL_DATA:
      const data = merge({}, state.originalData);
      return {
        ...state,
        data: data,
      };
    default:
      return state;
  }
}

export default dateReducer;
