import { LOAD, LOAD_SUCCESS, LOAD_FAIL } from 'redux/actions/unlockAllAction';

const initialState = {
  loaded: false,
  loading: false,
  unlockAll: false
};

function unlockAllReducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case LOAD_SUCCESS:
      const unlockAllStatus = true;
      return {
        ...state,
        loading: false,
        loaded: true,
        unlockAll: unlockAllStatus
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    default:
      return state;
  }
}

export default unlockAllReducer;
