import { MozartTextBox } from '@pgforsta/mozart_ui';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './OpinionFeature.scss';
import Col from 'react-bootstrap/Col';
import { isEmptyOrSpaces } from 'utils/util-element';

class OpinionFeature extends Component {
  constructor(props) {
    super(props);
    const { currentSentence } = props;
    this.inputSelect = 'UserBucket';
    this.show = true;
    // this.dummyText = '*This way actually /plits //up Emoji\'s into all the modifiers!, but it <3 is way simpler kp@gmmmm #value [manu] (nikhil  (sha) to handle. kiran + reema whent to office?. &check apoinment. we are $people.And as Swift now counts Emoji\'s with modifiers (e.g.: 👨‍👩‍👧‍👦, 👨🏻‍💻, 🏴) as 1 you can do all kind of stuff.';
    this.dummyText = '';
    this.state = {
      opinion: '',
      featureAltKeyword: null,
      featureKeyword: '',
      feature: '',
      opinionSentence: (currentSentence && currentSentence.text) ? (currentSentence.text + this.dummyText).replace(/[[\]{}(//)*+@\\^$|#<>]/ig, '').replace(/([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF])/g, '') : '',
      featureSentence: (currentSentence && currentSentence.text) ? (currentSentence.text + this.dummyText).replace(/[[\]{}(//)*+@\\^$|#<>]/ig, '').replace(/([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF])/g, '') : '',
      resolvePronounSentence: props.fullReview ? props.fullReview : '',
      currentSentenceInsight: props.currentSentenceInsight,
      surveyQuestion: props.surveyQuestion ? props.surveyQuestion : '',
      surveyQuestionSentence: props.surveyQuestion ? props.surveyQuestion.replace(/[[\]{}(//)*+@\\^$|#<>]/ig, '').replace(/([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF])/g, '') : '',
      surveyQuestionOpinionSentence: props.surveyQuestion ? props.surveyQuestion.replace(/[[\]{}(//)*+@\\^$|#<>]/ig, '').replace(/([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF])/g, '') : '',
      surveyQuestionFeatureSentence: props.surveyQuestion ? props.surveyQuestion.replace(/[[\]{}(//)*+@\\^$|#<>]/ig, '').replace(/([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF])/g, '') : '',
      featureSurveySentence: '',
      opinionSurveySentence: '',
      lastPickedType: '',
      stringByparts: false
    };
    this.handleOnClickWord = this.handleOnClickWord.bind(this);
  }

  getOpinionBlock() {
    return (
      <div className="opinionBlock">
        <div xs={12} md={12} className="opinion-text">
          <p>Select Opinion</p>
        </div>
        <div className="option-mask">
          <Col xs={12} md={12} className="opinion-sentence-block">
            <Col
              md={7}
              xs={7}
              className="opinion-sentence"
              id="opinionSentence"
              ket="opinion_sel"
              onMouseUp={
                () => { this.handleMouseUp('opinion'); }
              }
            >
              <Col
                md={12}
                xs={12}
                className="opinion-sentence surveyQuestionItems"
                id="opinionSentence"
                ket="opinion_sel"
                style={{ border: '0px', padding: '0px 0px 8px' }}
                onMouseUp={
                  () => { this.handleMouseUp('surveyOpinionQuestion'); }
                }
              >
                <div
                  className="questionLabel disable-select"
                  style={{
                    color: '#4A4A4A',
                    fontFamily: 'Roboto',
                    fontWeight: 'bold',
                    fontSize: '12px'
                  }}
                >
                  {this.props.surveyQuestion && 'Survey Question'}
                </div>
                {this.props.surveyQuestion && this.getOpinionSurveyQuestion('opinionSurveySentence')}
              </Col>
              {this.props.surveyQuestion && <Col md={12} xs={12} style={{ borderBottom: '1px solid #D3CFF8', marginBottom: '8px' }} />}
              {/* {this.props.opinion} */}

              {this.getOpinionSentence()}
            </Col>
            <Col md={5} xs={5} className="opinion-sentence-input">
              <div className="sentiment-opinion-value2">
                <MozartTextBox
                  id="opinion"
                  className="opinion"
                  name="opinion"
                  multiLine={false}
                  validationBool={false}
                  width="300px"
                  key={this.state.currentSentenceInsight.opinion}
                  defaultValue={this.state.currentSentenceInsight.opinion}
                  disabled
                />
                <div style={{
                  color: 'red',
                  fontSize: '13px',
                  textAlign: 'right'
                }}
                >
                  <p>
                    * Required Field
                    {this.state.currentSentenceInsight.opinion && this.state.currentSentenceInsight.opinion.length > 250 && ', ' + this.props.warningMessage}
                  </p>
                </div>
              </div>
            </Col>
          </Col>
        </div>
      </div>
    );
  }

  getFeatureSentence1() {
    const pickedFeature = this.props.feature;
    const opar = '<p>' + this.state.featureSentence + '</p>';
    let search = pickedFeature;
    search = search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const re = new RegExp(search, 'g');
    const replaceData = opar.replace(re, '<span class="picked-items"><b><mark>$&</mark></b>');
    return (
      <div dangerouslySetInnerHTML={{ __html: replaceData }} />
    );
  }

  getFeatureSentence() {
    const pickedFeature = this.props.feature;
    let opar = '<p>' + this.state.featureSentence + '</p>';
    opar = this.getSentenceByparts(pickedFeature, 'feature');
    return (
      <div>{opar}</div>
    );
  }

  getOpinionSentence() {
    const pickedOpinion = this.props.opinion;
    let opar = '<p>' + this.state.opinionSentence + '</p>';
    opar = this.getSentenceByparts(pickedOpinion, 'opinion');
    return (
      <div>{opar}</div>
    );
  }

  getOpinionSurveyQuestion(type) {
    const pickedOpinion = this.props.surveyQuestion;
    let opar = '<p>' + this.state.surveyQuestionSentence + '</p>';
    opar = this.getSentenceByparts(pickedOpinion, type);
    return (
      <div>{opar}</div>
    );
  }

  getFeatureSurveyQuestion(type) {
    const pickedOpinion = this.props.surveyQuestion;
    let opar = '<p>' + this.state.surveyQuestionFeatureSentence + '</p>';
    opar = this.getSentenceByparts(pickedOpinion, type);
    return (
      <div>{opar}</div>
    );
  }

  getResolvePronounSentence() {
    let opar = '<p>' + this.props.fullReview ? this.props.fullReview + '</p>' : '';
    opar = this.getSentenceByparts('resolve-pronoun', 'featureAltKeyword');
    return (
      <div style={{ height: '150px', overflowY: 'auto' }}>{opar}</div>
    );
  }

  getSentenceByparts(pickedOpinion, type) {
    let str = this.state.opinionSentence;
    let searchItems = this.props.pickedOpinion;
    if (type === 'feature') {
      str = this.state.featureSentence;
      searchItems = this.props.pickedFeature;
    }
    if (type === 'featureAltKeyword') {
      str = this.state.resolvePronounSentence ? this.state.resolvePronounSentence : '';
      searchItems = [];
    }
    if (type === 'opinion') {
      str = this.state.opinionSentence;
    }
    if (type === 'featureSurveySentence') {
      searchItems = this.props.pickedFeature;
      str = this.state.surveyQuestionFeatureSentence;
    }
    if (type === 'opinionSurveySentence') {
      str = this.state.surveyQuestionOpinionSentence;
    }
    str = str.replace(/\\u00a0/gi, ' ');
    if (str.indexOf('\u00a0') > -1) {
      str = str.split('\u00a0').join(' ');
    }
    if (str.indexOf('&nbsp;') > -1) {
      str = str.split('&nbsp;').join(' ');
    }
    const selectedItemsString = str;
    let replaceDataString = '';
    const search = searchItems;
    const replaceSearchArray = [];
    if (search && search.length > 0) {
      search.map((item) => {
        if (item) {
          replaceSearchArray.push(item.replace(/[[\]{}(//)*+@\\^$|#<>]/g, ''));
        }
        return true;
      });
      // console.log(type, replaceSearchArray.join('|'));
      // const re = new RegExp(replaceSearchArray.join('|'), 'gmi');
      // console.log('======', re);
      // selectedItemsString = selectedItemsString.replace(re, (matched) => {
      //   const matchedData = matched;
      //   console.log('searched item', matchedData);
      //   return ' <b> ' + matchedData + ' </b> ';
      // });
      // selectedItemsString = '' + selectedItemsString;
      // replaceDataString = selectedItemsString;
      replaceDataString = this.getBoldWords(replaceSearchArray, str);
    } else {
      replaceDataString = selectedItemsString;
    }
    const textDataArray = [];
    const words = replaceDataString.split(' ');
    for (let indexVal = 0; indexVal < words.length; indexVal += 1) {
      const reWord = words[indexVal];
      if (reWord.indexOf('<b>') !== -1 || reWord.indexOf('</b>') !== -1) {
        const customBoldStr = [];
        for (let bIndex = indexVal; bIndex < words.length; bIndex += 1) {
          if (words[bIndex].indexOf('<b>') !== -1) {
            customBoldStr.push('<b>');
            indexVal += 1;
          } else if (words[bIndex].indexOf('</b>') !== -1) {
            customBoldStr.pop();
            indexVal += 1;
            if (customBoldStr.length === 0) {
              indexVal -= 1;
              break;
            }
          } else {
            textDataArray.push(' ');
            textDataArray.push(<span role="link" tabIndex="0" onKeyDown={this.handleOnClickWord.bind(this)} onClick={this.handleOnClickWord.bind(this)} className="picked-items single-click" sentimentWordType={type}><b>{words[bIndex].indexOf('</b>') !== -1 || words[bIndex].indexOf('<b>') !== -1 ? '' : words[bIndex]}</b></span>);
            textDataArray.push(' ');
            indexVal += 1;
          }
        }
      } else {
        textDataArray.push(' ');
        textDataArray.push(<span role="link" tabIndex="0" onKeyDown={this.handleOnClickWord.bind(this)} onClick={this.handleOnClickWord.bind(this)} className="picked-items single-click" sentimentWordType={type}>{reWord}</span>);
      }
    }
    return textDataArray;
  }

  getBoldString(str, find) {
    const re = new RegExp(find, 'g');
    return str.replace(re, '<b> ' + find + ' </b>');
  }

  getBoldWords(search, str) {
    let replaceData = str;
    if (search && search.length > 0) {
      search.map((item) => {
        if (item) {
          item = item.replace('?', '\\?');
          const searchItemsObj = `(?:^|\\W)(${item})(?:$|\\W)`;
          const re = new RegExp(searchItemsObj, 'g');
          replaceData = replaceData.replace(re, ' <b> $1 </b> ');
        }
        return true;
      });
    }
    return replaceData;
  }

  getFeatureBlock() {
    return (
      <div className="featureBlock">
        <div xs={12} md={12} className="feature-text">
          <p>Select Feature</p>
        </div>
        <div className="feature-mask">
          <Col xs={12} md={12} className="feature-sentence-block">
            <Col
              md={7}
              xs={7}
              className="feature-sentence"
              onMouseUp={
                () => { this.handleMouseUp('feature'); }
              }
            >
              <Col
                md={12}
                xs={12}
                className="feature-sentence surveyQuestionItems"
                style={{ border: '0px', padding: '0px 0px 8px', color: '#E74C3C' }}
                onMouseUp={
                  () => { this.handleMouseUp('surveyFeatureQuestion'); }
                }
              >
                <div
                  className="questionLabel disable-select"
                  style={{
                    color: '#4A4A4A',
                    fontFamily: 'Roboto',
                    fontWeight: 'bold',
                    fontSize: '12px',
                  }}
                >
                  {this.props.surveyQuestion && 'Survey Question'}
                </div>
                {this.props.surveyQuestion && this.getOpinionSurveyQuestion('featureSurveySentence')}
              </Col>
              {this.props.surveyQuestion && <Col md={12} xs={12} style={{ borderBottom: '1px solid #D3CFF8', marginBottom: '8px' }} />}
              {this.getFeatureSentence()}
            </Col>
            <Col md={5} xs={5} className="feature-sentence-input">
              <div className="sentiment-feature-value2">
                <MozartTextBox
                  id="feature"
                  className="feature"
                  name="feature"
                  multiLine={false}
                  validationBool={false}
                  width="300px"
                  key={this.state.currentSentenceInsight.feature}
                  disabled
                  defaultValue={this.state.currentSentenceInsight.feature}
                />
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '42%' }}>
                    <button
                      type="button"
                      className="no-opinion-found"
                      onClick={
                        () => { this.handleDefault('feature', 'entity'); }
                      }
                    >
                      Use Entity As Feature
                    </button>

                  </div>
                  <div style={{
                    color: 'red',
                    fontSize: '13px',
                    textAlign: 'right',
                    width: '57%'
                  }}
                  >
                    <p>{this.state.currentSentenceInsight.feature && this.state.currentSentenceInsight.feature.length > 250 && this.props.warningMessage}</p>
                  </div>
                </div>

              </div>
            </Col>
          </Col>
        </div>
      </div>
    );
  }

  getResolvePronounBlock() {
    return (
      <div className="resolvePronounBlock">
        <div xs={12} md={12} className="resolve-pronoun-text">
          <p>Resolve Pronoun</p>
        </div>
        <div className="option-mask">
          <Col xs={12} md={12} className="opinion-sentence-block">
            <Col
              md={7}
              xs={7}
              className="opinion-sentence"
              onMouseUp={
                () => { this.handleMouseUp('featureAltKeyword'); }
              }
            >
              {this.getResolvePronounSentence()}
            </Col>
            <Col md={5} xs={5} className="opinion-sentence-input">
              <div className="sentiment-opinion-value2">
                <MozartTextBox
                  id="featureAltKeyword"
                  className="resolvePronoun"
                  name="featureAltKeyword"
                  multiLine={false}
                  validationBool={false}
                  width="300px"
                  key={this.state.currentSentenceInsight.featureAltKeyword}
                  disabled
                  defaultValue={this.state.currentSentenceInsight.featureAltKeyword ? this.state.currentSentenceInsight.featureAltKeyword.replace(/&nbsp;/g, ' ').replace(/(<([^>]+)>)/ig, '') : ''}
                />
                {/* <div style={{ paddingLeft: '218px' }}><a href="jfhkg" className="no-opinion-found">No Opinion Found</a></div> */}
              </div>
              <div style={{
                color: 'red',
                fontSize: '13px',
                textAlign: 'right',
              }}
              >
                <p>{this.state.currentSentenceInsight.featureAltKeyword && this.state.currentSentenceInsight.featureAltKeyword.length > 250 && this.props.warningMessage}</p>
              </div>
            </Col>
          </Col>
        </div>
      </div>
    );
  }

  handleOnClickWord(event) {
    let selectedText = event.target.innerHTML;
    let selectedType = event.target.getAttribute('sentimentWordType');
    if (selectedType === null) {
      selectedType = event.target.closest('span').getAttribute('sentimentWordType');
    }
    const isEmptyOrSpacesCheck = isEmptyOrSpaces(selectedText);
    if (selectedText.length > 0 && isEmptyOrSpacesCheck) {
      selectedText = selectedText ? selectedText.replace('<b>', '').replace('</b>', '').replace(/<[^>]*>/gm, '').replace(/&amp;/g, '&') : '';
      const currentState = this.state;
      currentState.newSentimentStatus = false;
      currentState.lastPickedType = selectedType;
      currentState[selectedType] = selectedText.length > 0 ? selectedText : null;
      let setCustomType = selectedType;
      if (selectedType === 'featureSurveySentence') {
        setCustomType = 'feature';
      }
      if (selectedType === 'opinionSurveySentence') {
        setCustomType = 'opinion';
      }
      if ((selectedText === currentState.currentSentenceInsight[selectedType]) || (selectedText === currentState.currentSentenceInsight[setCustomType])) {
        currentState.currentSentenceInsight[selectedType] = null;
        if (selectedType === 'featureSurveySentence') {
          currentState.currentSentenceInsight[setCustomType] = null;
        }
        if (selectedType === 'opinionSurveySentence') {
          currentState.currentSentenceInsight[setCustomType] = null;
        }
      } else {
        currentState.currentSentenceInsight[selectedType] = selectedText ? selectedText.replace('<b>', '').replace('</b>', '').replace(/<[^>]*>/gm, '').replace(/&amp;/g, '&') : '';
        if (selectedType === 'resolvePronoun') {
          currentState.currentSentenceInsight.feature = selectedText.length > 0 ? selectedText : null;
          currentState.currentSentenceInsight.featureKeyword = null;
          currentState.currentSentenceInsight.featureAltKeyword = selectedText.length > 0 ? selectedText : null;
        }
        if (selectedType === 'featureSurveySentence') {
          currentState.currentSentenceInsight.feature = selectedText.length > 0 ? selectedText : null;
          currentState.currentSentenceInsight.featureSurveySentence = selectedText.length > 0 ? selectedText : null;
        }
        if (selectedType === 'opinionSurveySentence') {
          currentState.currentSentenceInsight.opinion = selectedText.length > 0 ? selectedText : null;
          currentState.currentSentenceInsight.opinionSurveySentence = selectedText.length > 0 ? selectedText : null;
        }
        if (currentState.currentSentenceInsight[selectedType] === 'featureAltKeyword') {
          currentState.currentSentenceInsight.featureKeyword = null;
          currentState.currentSentenceInsight.featureAltKeyword = selectedText.length > 0 ? selectedText : null;
        }
      }
      this.setState({ ...currentState }, () => {
        this.props.onChangeInsightsValues(this.state.currentSentenceInsight, this.props.currentSentenceId, this.props.currentInsightIndex);
      });
    }
  }

  handleMouseUp(selectedType) {
    const selectedTextObj = window.getSelection();
    let selectedText = selectedTextObj.toString();
    let selectedTextUptoEnd = '';
    const isEmptyOrSpacesCheck = isEmptyOrSpaces(selectedText);
    if (selectedText.length > 0 && isEmptyOrSpacesCheck) {
      let availableSelection = selectedText.split(' ');
      const focusNodeData = window.getSelection().focusNode.data;
      const position = selectedTextObj.anchorNode.compareDocumentPosition(selectedTextObj.focusNode);
      let backward = false;
      if (focusNodeData && availableSelection) {
        availableSelection.pop();
        availableSelection.push(focusNodeData);
        selectedTextUptoEnd = availableSelection.join(' ');
      }
      // position == 0 if nodes are the same
      if ((!position && selectedTextObj.anchorOffset > selectedTextObj.focusOffset) || (position === Node.DOCUMENT_POSITION_PRECEDING)) {
        backward = true;
        availableSelection = selectedText.split(' ');
        if (focusNodeData && availableSelection) {
          if (backward === true) {
            availableSelection.shift();
          }
          availableSelection.unshift(focusNodeData);
          selectedTextUptoEnd = availableSelection.join(' ');
        }
      }
      selectedText = selectedTextUptoEnd;
    }
    selectedText = selectedText ? selectedText.replace('<b>', '').replace('</b>', '').replace(/<[^>]*>/gm, '').replace(/&amp;/g, '&') : '';
    if (selectedTextObj && selectedTextObj.focusNode.parentElement.className && selectedTextObj.focusNode.parentElement.className === 'questionLabel') {
      selectedText = null;
    }
    if (selectedText !== null && selectedText.length > 0 && isEmptyOrSpacesCheck) {
      if (selectedType === 'opinion') {
        this.setState({
          opinion: selectedText.length > 0 ? selectedText : null
        });
      }
      if (selectedType === 'surveyOpinionQuestion') {
        this.setState({
          opinion: selectedText.length > 0 ? selectedText : null
        });
      }
      if (selectedType === 'surveyFeatureQuestion') {
        this.setState({
          feature: selectedText.length > 0 ? selectedText : null
        });
      }
      if (selectedType === 'feature') {
        this.setState({
          feature: selectedText.length > 0 ? selectedText : null
        });
      }
      if (selectedType === 'resolvePronoun') {
        this.setState({
          featureAltKeyword: selectedText.length > 0 ? selectedText : null,
          featureKeyword: null
        });
        this.setState({
          feature: selectedText
        });
      }
      if (selectedText.length > 0) {
        const currentState = this.state;
        currentState[selectedType] = selectedText.length > 0 ? selectedText : null;
        if (selectedText === currentState.currentSentenceInsight[selectedType]) {
          currentState.currentSentenceInsight[selectedType] = null;
          if (currentState.currentSentenceInsight[selectedType] === 'featureAltKeyword') {
            currentState.currentSentenceInsight.featureKeyword = null;
          }
        } else {
          currentState.currentSentenceInsight[selectedType] = selectedText.length > 0 ? selectedText : null;
          if (currentState.currentSentenceInsight[selectedType] === 'featureAltKeyword') {
            currentState.currentSentenceInsight.featureKeyword = null;
            currentState.currentSentenceInsight.featureAltKeyword = selectedText.length > 0 ? selectedText : null;
          }
        }
        this.setState({ ...currentState }, () => {
          this.props.onChangeInsightsValues(this.state.currentSentenceInsight, this.props.currentSentenceId, this.props.currentInsightIndex);
        });
      }
    }
  }

  handleDefault(selectedType, entity) {
    if (selectedType) {
      if (selectedType === 'opinion') {
        this.setState({
          opinion: null
        });
      }
      if (selectedType === 'feature') {
        let selectedText = null;
        if (entity && this.props.providerLocation) {
          selectedText = this.props.providerLocation;
        }
        if (selectedText && selectedText.length > 0) {
          const currentState = this.state;
          currentState[selectedType] = selectedText;
          if ((selectedText === currentState.currentSentenceInsight[selectedType]) || (selectedText === currentState.currentSentenceInsight.featureSurveySentence)) {
            currentState.currentSentenceInsight[selectedType] = null;
            currentState.currentSentenceInsight.featureSurveySentence = null;
          } else {
            currentState.currentSentenceInsight[selectedType] = selectedText;
            currentState.currentSentenceInsight.featureSurveySentence = selectedText;
          }
          this.setState({ ...currentState }, () => {
            this.props.onChangeInsightsValues(this.state.currentSentenceInsight, this.props.currentSentenceId, this.props.currentInsightIndex);
          });
        }
      }
    }
  }

  render() {
    return (
      <div style={{ width: '935px' }} key="opinion_feature_modal">
        {this.getOpinionBlock()}
        <div className="opinion-seperator" />
        {this.getFeatureBlock()}
        <div className="opinion-seperator" />
        {this.getResolvePronounBlock()}
      </div>
    );
  }
}

OpinionFeature.propTypes = {
  currentSentence: PropTypes.object,
  currentSentenceInsight: PropTypes.object,
  currentSentenceId: PropTypes.number,
  currentInsightIndex: PropTypes.number,
  pickedFeature: PropTypes.array,
  pickedOpinion: PropTypes.array,
  opinion: PropTypes.string,
  feature: PropTypes.string,
  fullReview: PropTypes.string,
  surveyQuestion: PropTypes.string,
  warningMessage: PropTypes.string,
  providerLocation: PropTypes.string,
  onChangeInsightsValues: PropTypes.func
};

export default OpinionFeature;
