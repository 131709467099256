import {
  LOAD_BREADCRUM,
  LOAD_BREADCRUM_DASHBOARD,
  SET_CHART_BREADCRUM_LEVEL
} from 'redux/actions/breadcrumAction';

const initialState = {
  breadcrums: [],
  breadcrumsDashboard: [],
  breadcrumChartLevel: []
};

function breadcrumReducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_BREADCRUM:
      action.path.map((item) => {
        if (!item.href) {
          item.href = undefined;
        } else if (item.href && !item.absolutePath) {
          item.href = '/#/' + item.href;
        }
        return true;
      });
      return {
        ...state,
        breadcrums: action.path
      };
    case LOAD_BREADCRUM_DASHBOARD:
      return {
        ...state,
        breadcrumsDashboard: action.path.map(item => { item.href = item.href ? '/#/' + item.href : undefined; return item; })
      };
    case SET_CHART_BREADCRUM_LEVEL:
      return {
        ...state,
        breadcrumChartLevel: action.data
      };
    default:
      return state;
  }
}
export default breadcrumReducer;
