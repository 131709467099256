import {
  MozartCheckBox
} from '@pgforsta/mozart_ui';
import React, { Component } from 'react';
// import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import { Profile } from 'components';
import './DeleteReason.scss';
import Col from 'react-bootstrap/Col';
// import Row from 'react-bootstrap/Row';

class DeleteReason extends Component {
  constructor(props) {
    super(props);
    this.inputSelect = 'UserBucket';
    this.show = true;
  }

  getReviewSentiments() {
    return (
      <div className="reviewsSentimentsBlock">
        <Col xs={12} md={12} className="review-sentiment-content-section-delete">
          <p>Which parts of insight are wrong?</p>
          <MozartCheckBox
            onChange={(data) => { this.handleDeleteSentimentReasonList(data); }}
            content={[
              { name: 'Feature', value: 'feature' },
              { name: 'Opinion', value: 'opinion' },
              { name: 'Category', value: 'category' },
              { name: 'Sub-categories', value: 'sub-categories' },
              { name: 'Sentiment', value: 'sentiment' },
              { name: 'Duplicate', value: 'duplicate' }
            ]}
          />
        </Col>
      </div>
    );
  }

  handleDeleteSentimentReasonList(data) {
    this.props.handleDeleteSentimentReason(data);
  }

  render() {
    return (
      <div>
        {this.getReviewSentiments()}
      </div>
    );
  }
}

DeleteReason.propTypes = {
  handleDeleteSentimentReason: PropTypes.func
};

export default DeleteReason;
