import { combineReducers } from 'redux';
import authReducer from 'redux/reducers/authReducer';
import queueReducer from 'redux/reducers/queueReducer';
import documentReducer from 'redux/reducers/documentReducer';
import unlockAllReducer from 'redux/reducers/unlockAllReducer';
import documentAutoGenerateReducer from 'redux/reducers/documentAutoGenerateReducer';
import categoryReducer from 'redux/reducers/categoryReducer';
import breadcrumReducer from 'redux/reducers/breadcrumReducer';
import responderReducer from 'redux/reducers/responderReducer';
import approvalReducer from 'redux/reducers/approvalReducer';
import priorityReducer from 'redux/reducers/priorityReducer';
import clientReducer from 'redux/reducers/clientReducer';
import sourceReducer from 'redux/reducers/sourceReducer';
import priorityTableReducer from 'redux/reducers/priorityTableReducer';
import queueAssignReducer from 'redux/reducers/queueAssignReducer';
import taskUserReducer from 'redux/reducers/taskUserReducer';
import templateReducer from 'redux/reducers/templateReducer';
import configClientReducer from 'redux/reducers/queueConfigReducer';
import meRolesReducer from 'redux/reducers/meRolesReducer';
import sendSupportReducer from 'redux/reducers/sendSupportReducer';
import locationReducer from 'redux/reducers/locationReducer';
import responderListReducer from 'redux/reducers/responderListReducer';
import reAssignReducer from 'redux/reducers/reAssignReducer';
import sentimentReducer from 'redux/reducers/sentimentReducer';
import contentReducer from 'redux/reducers/contentReducer';
import usersReducer from 'redux/reducers/usersReducer';
import reportReducer from 'redux/reducers/reportReducer';
import reportMonitorReducer from 'redux/reducers/reportMonitorReducer';
import dateReducer from 'redux/reducers/dateReducer';

export default combineReducers({
  authReducer,
  queueReducer,
  documentReducer,
  unlockAllReducer,
  documentAutoGenerateReducer,
  categoryReducer,
  breadcrumReducer,
  responderReducer,
  approvalReducer,
  priorityReducer,
  clientReducer,
  sourceReducer,
  priorityTableReducer,
  queueAssignReducer,
  taskUserReducer,
  templateReducer,
  configClientReducer,
  meRolesReducer,
  sendSupportReducer,
  locationReducer,
  responderListReducer,
  reAssignReducer,
  sentimentReducer,
  contentReducer,
  usersReducer,
  reportReducer,
  reportMonitorReducer,
  dateReducer
});
