export const LOAD = 'binary/source/LOAD';
export const LOAD_SUCCESS = 'binary/source/LOAD_SUCCESS';
export const LOAD_FAIL = 'binary/client/LOAD_FAIL';

export function loadSource(clientId) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],

    payload: {
      client: 'nextgen',
      request: {
        method: 'get',
        url: '/api/sources/' + clientId
      }
    }
  };
}
