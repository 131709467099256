import {
  LOAD,
  LOAD_SUCCESS,
  LOAD_FAIL,
  RESET
} from 'redux/actions/documentAutoGenerateAction';

const initialState = {
  loaded: false,
  loading: false,
  contentId: 0
};

function documentAutoGenerateReducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case LOAD_SUCCESS:
      // const conId = action.payload.data ? action.payload.data : 0;
      return {
        ...state,
        loading: false,
        loaded: true,
        contentId: action.payload.data
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    case RESET:
      return {
        ...state,
        loading: false,
        loaded: false,
        contentId: 0
      };
    default:
      return state;
  }
}

export default documentAutoGenerateReducer;
