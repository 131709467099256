import { LOAD, LOAD_SUCCESS, LOAD_FAIL } from 'redux/actions/usersAction';

const initialState = {
  loaded: false,
  users: [],
};

function usersReducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        users: action.payload.data,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export default usersReducer;
