import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Header,
  // PriorityChartDashboard
  ManagerDashboard
} from 'components';
import {
  MozartLoading,
} from '@pgforsta/mozart_ui';
import { Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { loadClients } from 'redux/actions/clientAction';
import './ManagerView.scss';
import { meUserRole } from 'utils/util-element';

class ManagerView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLevel: 0,
    };
    meUserRole(this.props.permissions, 'MANAGER');
  }

  componentDidMount() {
    console.log('here manager view ');
  }

  getChartPanel() {
    return (
      <Col xs={12} md={12} className="priority-charts-group" key={this.state.currentLevel + this.state.selectedClient}>
        {this.props.loading && <MozartLoading />}
        {<ManagerDashboard />}
      </Col>
    );
  }

  render() {
    return [
      <Header />,
      <div>
        {this.getChartPanel()}
      </div>
    ];
  }
}

ManagerView.propTypes = {
  permissions: PropTypes.object,
  loading: PropTypes.bool
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({ loadClients }, dispatch)
);

const mapStateToProps = state => ({
  loading: state.priorityReducer.loading || state.clientReducer.loading || state.sourceReducer.loading || state.priorityReducer.loadingClientPriority || state.priorityReducer.loadingSourcePriority || state.priorityTableReducer.loading || state.priorityTableReducer.loadingExport || state.priorityTableReducer.loadingMore,
  clientReducer: state.clientReducer,
  permissions: state.authReducer.permissions,
});

export default connect(mapStateToProps, mapDispatchToProps)(ManagerView);
