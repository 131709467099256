import {
  LOAD_CONFIG,
  LOAD_CONFIG_SUCCESS,
  LOAD_CONFIG_FAIL,
  LOAD_CONFIG_SAVE,
  LOAD_CONFIG_SAVE_SUCCESS,
  LOAD_CONFIG_SAVE_FAIL
} from 'redux/actions/queueConfigAction';

const initialState = {
  loadedConfig: false,
  loadingConfig: false,
  config: []
};

export default function configClientReducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_CONFIG:
      return {
        ...state,
        loadingConfig: true,
        loadedConfig: false
      };
    case LOAD_CONFIG_SUCCESS:
      const queueList = state.data ? { ...state.data, ...action.payload.data } : action.payload.data;
      return {
        ...state,
        loadingConfig: false,
        loadedConfig: true,
        config: queueList
      };
    case LOAD_CONFIG_FAIL:
      return {
        ...state,
        loadingConfig: false,
        loadedConfig: false,
        error: action.error
      };
    default:
      return state;
  }
}

export function configSaveReducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_CONFIG_SAVE:
      return {
        ...state,
        loadingConfig: true,
        loadedConfig: false
      };
    case LOAD_CONFIG_SAVE_SUCCESS:
      const queueList = state.data ? { ...state.data, ...action.payload.data } : action.payload.data;
      return {
        ...state,
        loadingConfig: false,
        loadedConfig: true,
        config: queueList
      };
    case LOAD_CONFIG_SAVE_FAIL:
      return {
        ...state,
        loadingConfig: false,
        loadedConfig: false,
        error: action.error
      };
    default:
      return state;
  }
}
