import { MozartSelectField } from '@pgforsta/mozart_ui';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

class Location extends Component {
  constructor(props) {
    super(props);
    this.inputSelect = 'UserBucket';
    this.select = React.createRef();
  }

  getLocations() {
    const dataObj = this.props.locations;
    const locationData = [];
    const result = Object.values(dataObj);
    if (result.length > 0) {
      locationData.push({ value: 0, id: 0, text: 'All Locations' });
      result.forEach((data) => {
        locationData.push({ value: data.id, id: data.id, text: data.name });
      });
    }
    return (
      <div style={{ float: 'right', display: 'flex' }}>
        <MozartSelectField
          data={locationData}
          onChange={(data) => { this.handleLocation(data); }}
          width="230px"
          // defaultValue={result[0] ? result[0].id : null}
          hintText="Select Location/Provider"
        />
      </div>
    );
  }

  handleLocation(data) {
    this.props.handleOnChange(data, 'locationId');
  }

  render() {
    return (
      <div>
        {this.getLocations()}
      </div>
    );
  }
}

Location.propTypes = {
  handleOnChange: PropTypes.func,
  locations: PropTypes.array
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({ }, dispatch)
);

const mapStateToProps = state => ({
  locationReducer: state.clientReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(Location);
