import {
  LOAD_ASSIGN,
  LOAD_ASSIGN_SUCCESS,
  LOAD_ASSIGN_FAIL,
  LOAD_SAVE,
  LOAD_SAVE_SUCCESS,
  LOAD_SAVE_FAIL
} from 'redux/actions/queueAssignAction';

const initialState = {
  loadedUser: false,
  loadingUser: false,
  user: [],
  userSave: []
};

export default function queueAssignReducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_ASSIGN:
      return {
        ...state,
        loadingUser: true,
        loadedUser: false
      };
    case LOAD_ASSIGN_SUCCESS:
      const userList = state.data ? { ...state.data, ...action.payload.data } : action.payload.data;
      return {
        ...state,
        loadingUser: false,
        loadedUser: true,
        user: userList
      };
    case LOAD_ASSIGN_FAIL:
      return {
        ...state,
        loadingUser: false,
        loadedUser: false,
        error: action.error
      };
    default:
      return state;
  }
}

export function userSaveReducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_SAVE:
      return {
        ...state,
        loadingConfig: true,
        loadedConfig: false
      };
    case LOAD_SAVE_SUCCESS:
      const queueListS = state.data ? { ...state.data, ...action.payload.data } : action.payload.data;
      return {
        ...state,
        loadingConfig: false,
        loadedConfig: true,
        userSave: queueListS
      };
    case LOAD_SAVE_FAIL:
      return {
        ...state,
        loadingConfig: false,
        loadedConfig: false,
        error: action.error
      };
    default:
      return state;
  }
}
