import { MozartSelectField } from '@pgforsta/mozart_ui';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { loadClients } from 'redux/actions/clientAction';

class Category extends Component {
  constructor(props) {
    super(props);
    this.inputSelect = 'UserBucket';
    this.select = React.createRef();
  }

  getCategory() {
    const categoryData = [];
    categoryData.push({ value: 0, id: 0, text: 'All' });
    categoryData.push({ value: 'SEND_TO_SUPPORT', id: 'SEND_TO_SUPPORT', text: 'SEND TO SUPPORT' });
    categoryData.push({ value: 'FAILED', id: 'FAILED', text: 'FAILED' });
    return (
      <div style={{ float: 'right', display: 'flex' }}>
        <MozartSelectField
          data={categoryData}
          onChange={(data) => { this.handleCategory(data); }}
          width="180px"
          hintText="Select Category"
          defaultValue={this.props.status}
        />
      </div>
    );
  }

  handleCategory(data) {
    this.props.handleOnChange(data, 'status');
  }

  render() {
    return (
      <div>
        {this.getCategory()}
      </div>
    );
  }
}

Category.propTypes = {
  handleOnChange: PropTypes.func,
  status: PropTypes.string
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({ loadClients }, dispatch)
);

const mapStateToProps = state => ({
  clientReducer: state.clientReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(Category);
