import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Col from 'react-bootstrap/Col';
import { bindActionCreators } from 'redux';
import { load as loadCategory } from 'redux/actions/categoryAction';
import { qclevelChanges } from 'redux/actions/contentAction';
import {
  ReviewPopUp, Sentence
} from 'components';
import {
  MozartButton, MozartModal
} from '@pgforsta/mozart_ui';
import './SentenceList.scss';

function SentenceList(props) {
  const {
    documentReducer, category, contentId, handleSentimentCountChange
  } = props;
  const {
    fullReview, nlpCompleted, qc1Completed, qc2Completed, sentences,
  } = documentReducer.documentData;
  const subCategory = [];
  const [reviewModal, setReviewModal] = useState(false);
  const subCategories = [];
  const sentenceList = [];
  sentences.forEach((sentence) => {
    sentenceList[sentence.id] = sentence;
  });
  if (category) {
    category.children.forEach((categoryItem) => {
      if (categoryItem.children && categoryItem.children.length > 0) {
        categoryItem.children.forEach((categorySubItem) => {
          subCategories.push(categorySubItem);
        });
      } else {
        subCategories.push(categoryItem);
      }
    });
  }

  const getSentence = () => {
    const dataDiv = [];
    if (sentenceList && sentenceList.length > 0) {
      let sentenceCount = 0;
      Object.keys(sentenceList).forEach((item) => {
        const currentSentence = sentenceList[item];
        dataDiv.push(
          <Sentence
            category={category}
            contentId={contentId}
            fullReview={fullReview}
            subCategory={subCategory}
            sentenceIndex={dataDiv.length}
            sentence={currentSentence}
            subCategories={subCategories}
            handleSentimentCountChange={handleSentimentCountChange}
            getNewSentenceSentimentObj={getNewSentenceSentimentObj}
          />
        );
        sentenceCount += sentenceCount + 1;
        return true;
      });
    }
    return (<div>{dataDiv}</div>);
  };

  const getNewSentenceSentimentObj = () => {
    const insightsObj = {
      category: null,
      categoryId: null,
      feature: null,
      featureAltKeyword: null,
      featureAltNote: null,
      featureKeyword: null,
      featureNote: null,
      id: null,
      insightBy: null,
      opinion: null,
      sentiment: null,
      themeId: null,
      theme: null,
      subCategory: subCategory,
      previousCategory: null,
      previousSentiment: null,
      previousThemeId: null,
      previousTheme: null,
      previousCategoryName: null,
    };
    return insightsObj;
  };

  const getSentenceList = () => (
    <div className="sentence-block-list">
      {getSentence()}
      {getButtonList()}
    </div>
  );

  const getButtonList = () => {
    let buttonLabel = '';
    if (qc2Completed && qc1Completed) {
      buttonLabel = 'Re-QC2 Pass and Proceed';
    } else if (qc1Completed) {
      buttonLabel = 'QC2 Pass & Proceed';
    } else {
      buttonLabel = 'Accept & Proceed';
    }
    let acceptProceedBtnStyle = false;
    if (!nlpCompleted) {
      acceptProceedBtnStyle = true;
    }
    return (
      <div className="QC-Btn-list">
        <Col xs={6} md={6} className="review-btn-block">
          <Col xs={8} md={8} />
          <Col xs={2} md={2} />
          <Col xs={2} md={2} style={{ float: 'right' }}>
            <MozartButton
              data="Review"
              className="review-btn"
              id={reviewModal}
              onClick={
                () => { handleReviewModal(); }
              }
              style={{ display: (acceptProceedBtnStyle) ? 'none' : 'display' }}
            />
          </Col>
        </Col>
        <Col xs={6} md={6}>
          <Col xs={2} md={2}>
            <MozartButton
              background
              data={buttonLabel}
              className="approve-btn"
              onClick={
                () => { handleQCLevel(contentId); }
              }
              style={{ display: (acceptProceedBtnStyle) ? 'none' : 'display' }}
            />
          </Col>
        </Col>
      </div>
    );
  };

  const handleQCLevel = (currentContentId) => {
    props.handleNextAllDocument(currentContentId, qc1Completed, qc2Completed, nlpCompleted);
  };

  const handleQCLevelApprove = () => {
    handleQCLevel(contentId);
  };

  const handleReviewModal = () => {
    setReviewModal(true);
  };

  const showHideReviewModal = () => {
    setReviewModal(false);
  };

  const showReviewModal = () => {
    let buttonLabel = '';
    if (qc2Completed && qc1Completed) {
      buttonLabel = 'Re-QC2 Pass and Proceed';
    } else if (qc1Completed) {
      buttonLabel = 'QC2 Pass & Proceed';
    } else {
      buttonLabel = 'Accept & Proceed';
    }
    return (
      <div>
        <MozartModal
          show={reviewModal}
          onClose={showHideReviewModal}
          title="Select Opinion and feature"
          width="975px"
          id={reviewModal}
          contentPadding="20px"
          className="review-sentence-modal"
          key={reviewModal}
          submitLabel={buttonLabel}
          submitButtonClassName="approve-btn"
          cancelButtonClassName="review-btn"
          handleSubmit={() => { handleQCLevelApprove(); }}
          enableCancel
        >
          <ReviewPopUp
            key="review-pop"
            sentences={sentenceList}
            fullReview={fullReview}
          />
        </MozartModal>
      </div>
    );
  };

  return (
    <div className="sentenceBlock">
      {sentences && getSentenceList()}
      {showReviewModal()}
    </div>
  );
}

SentenceList.propTypes = {
  contentId: PropTypes.number,
  category: PropTypes.object,
  documentReducer: PropTypes.object,
  handleNextAllDocument: PropTypes.func,
  handleSentimentCountChange: PropTypes.func,
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    loadCategory, qclevelChanges
  }, dispatch)
);

const mapStateToProps = state => ({
  documentReducer: state.documentReducer,
  categoryReducer: state.categoryReducer.category,
  contentId: state.documentReducer.documentData.contentId
});

export default connect(mapStateToProps, mapDispatchToProps)(SentenceList);
