export const QCLEVEL = 'binary/content/QCLEVEL';
export const QCLEVEL_SUCCESS = 'binary/content/QCLEVEL_SUCCESS';
export const QCLEVEL_FAIL = 'binary/content/QCLEVEL_FAIL';
export const REFER = 'binary/content/REFER_DELETE';
export const REFER_SUCCESS = 'binary/content/REFER_SUCCESS';
export const REFER_FAIL = 'binary/content/REFER_FAIL';
export const TOGGLE = 'binary/content/TOGGLE';
export const TOGGLE_SUCCESS = 'binary/content/TOGGLE_SUCCESS';
export const TOGGLE_FAIL = 'binary/content/TOGGLE_FAIL';
export const CONTENT_DELETE = 'binary/content/CONTENT_DELETE';
export const CONTENT_DELETE_SUCCESS = 'binary/content/CONTENT_DELETE_SUCCESS';
export const CONTENT_DELETE_FAIL = 'binary/content/CONTENT_DELETE_FAIL';

export function qclevelChanges(contentId, qc1Completed, qc2Completed, nlpStatus) {
  let level = 0;
  if (nlpStatus && !qc1Completed && !qc2Completed) {
    level = 1;
  }
  if (qc1Completed) {
    level = 2;
  }
  if (qc2Completed) {
    level = 2;
  }
  return {
    types: [QCLEVEL, QCLEVEL_SUCCESS, QCLEVEL_FAIL],

    payload: {
      client: 'tagging',
      request: {
        method: 'post',
        url: 'api/contents/' + contentId + '/qclevel?level=' + level,
        headers: {
          'Content-Type': 'application/json'
        }
      }
    }
  };
}

export function referContent(contentId, reasonStr) {
  return {
    types: [REFER, REFER_SUCCESS, REFER_FAIL],
    payload: {
      client: 'tagging',
      request: {
        method: 'post',
        url: '/api/contents/' + contentId + '/refer?reason=' + reasonStr,
        headers: {
          'Content-Type': 'application/json'
        }
      }
    }
  };
}

export function deleteContent(contentId) {
  return {
    types: [CONTENT_DELETE, CONTENT_DELETE_SUCCESS, CONTENT_DELETE_FAIL],
    payload: {
      client: 'tagging',
      request: {
        method: 'DELETE',
        url: '/api/contents/' + contentId,
        headers: {
          'Content-Type': 'application/json'
        }
      }
    }
  };
}

export function toggleContent(contentId) {
  const toggleData = {
    contentIds: [contentId],
    status: 'N'
  };
  return {
    types: [TOGGLE, TOGGLE_SUCCESS, TOGGLE_FAIL],

    payload: {
      client: 'tagging',
      request: {
        method: 'post',
        data: [toggleData],
        url: '/api/contents/updateStatus',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    }
  };
}
