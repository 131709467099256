import { createStore, applyMiddleware } from 'redux';
import { multiClientMiddleware } from 'redux-axios-middleware';
import axios from 'axios';
import moment from 'moment';
import reactCookie from 'react-cookie';
import { logout, getCookie } from 'utils/util-element';
import rootReducer from './redux/reducers/rootReducer';

export default function configureStore(initialState = {}) {
  const logoutUrl = encodeURIComponent(window.location.href.replace('login', '').replace('error', ''));
  const client = {
    default: {
      client: axios.create({
        baseURL: process.env.REACT_APP_NEXTGEN_HOST,
        responseType: 'json',
        withCredentials: true,
        headers: {
          Accept: '*/*'
        }
      })
    },
    rca: {
      client: axios.create({
        baseURL: process.env.REACT_APP_RCA_HOST,
        responseType: 'json',
        withCredentials: true,
        headers: {
          Accept: '*/*',
          'Access-Control-Allow-Origin': '*'
        }
      })
    },
    tagging: {
      client: axios.create({
        baseURL: process.env.REACT_APP_TAGGING_HOST,
        responseType: 'json',
        withCredentials: true,
        credentials: 'include',
        redirect: 'follow',
        headers: {
          Accept: '*/*',
          // 'Access-Control-Allow-Origin': '*',
        }
      })
    },
    content: {
      client: axios.create({
        baseURL: process.env.REACT_APP_SOCIAL_CONTENT_HOST,
        responseType: 'json',
        withCredentials: true,
        headers: {
          Accept: '*/*',
          // 'Access-Control-Allow-Origin': '*',
        }
      })
    },
    nextgen: {
      client: axios.create({
        baseURL: process.env.REACT_APP_NEXTGEN_HOST,
        responseType: 'json',
        withCredentials: true,
        credentials: 'include',
        redirect: 'follow',
        headers: {
          Accept: '*/*',
          // 'Access-Control-Allow-Origin': '*',
        }
      })
    },
    managementengagement: {
      client: axios.create({
        baseURL: process.env.REACT_APP_ME_HOST,
        responseType: 'json',
        withCredentials: true,
        credentials: 'include',
        redirect: 'follow',
        headers: {
          Accept: '*/*',
          // 'Access-Control-Allow-Origin': '*',
        }
      })
    },
    userapi: {
      client: axios.create({
        baseURL: process.env.REACT_APP_USERAPI_HOST,
        responseType: 'json',
        withCredentials: true,
        credentials: 'include',
        redirect: 'follow',
        headers: {
          Accept: '*/*',
          // 'Access-Control-Allow-Origin': '*',
        }
      })
    },
    reportsapi: {
      client: axios.create({
        baseURL: process.env.REACT_APP_REPORTS_API_HOST,
        responseType: 'json',
        withCredentials: true,
        credentials: 'include',
        redirect: 'follow',
        headers: {
          Accept: '*/*'
        }
      })
    },
    ctlapi: {
      client: axios.create({
        baseURL: process.env.REACT_APP_CTL_HOST,
        responseType: 'json',
        withCredentials: true,
        credentials: 'include',
        redirect: 'follow',
        headers: {
          Accept: '*/*',
          'Access-Control-Allow-Origin': '*'
        }
      })
    },
  };

  const middlewareConfig = {
    onError(action) {
      if (action.error.response && action.error.response.status === 401) {
        logout(logoutUrl);
      }
      if (action.error.response && action.error.response.status === 410) {
        const errorUrl = process.env.REACT_APP_ERROR_BF_PAGE;
        window.location.href = errorUrl + '?source=' + encodeURIComponent(window.location.href);
      }
    },
    emptyObject: {},
    interceptors: {
      request: [{
        success: (emptyObject, req) => {
          const cookieName = process.env.REACT_APP_ENV === 'prod' ? 'anti-csrf-token' : process.env.REACT_APP_ENV + '_anti-csrf-token';
          const apiDomainSplit = req.baseURL.split('/');
          const apiDomainArray = apiDomainSplit[2].split('.');
          const apiDomain = apiDomainArray[0];
          if ((apiDomain === 'reports-api' || apiDomain === 'nextgenbha-api' || apiDomain === 'tagging-api' ||
          apiDomain === 'rca-api' || apiDomain === 'user-api' || apiDomain === 'social-content-api' ||
          apiDomain === 'tools-social' || apiDomain === 'tools-engagement-api' || apiDomain === 'ctl-api' || apiDomain === 'analytics' ||
          apiDomain === 'tools-bha') && req.method !== 'get') {
            req.headers['anti-csrf-token'] = reactCookie.load(cookieName);
          }
          const currTime = getCookie(process.env.REACT_APP_EXPIRY_TIME);
          if (moment(currTime).isBefore(moment()) || typeof currTime === 'undefined') {
            const nextCallTime = moment().add(300, 'seconds').format('MM/DD/YYYY HH:mm:ss');
            document.cookie = process.env.REACT_APP_EXPIRY_TIME + '=' + nextCallTime + '; domain=' + process.env.REACT_APP_DOMAIN + '; path=/';
            fetch(process.env.REACT_APP_NEXTGEN_HOST + '/api/token/accesstoken/validitystate', {
              method: 'GET',
              credentials: 'include',
              redirect: 'follow'
            }).then((rawData) => {
              if (rawData.status === 401) {
                logout(logoutUrl);
              }
              return rawData.json();
            }).then((checkData) => {
              const cookieNameCSRF = process.env.REACT_APP_ENV === 'prod' ? 'anti-csrf-token' : process.env.REACT_APP_ENV + '_anti-csrf-token';
              const expireTime = checkData.accessTokenExpiresIn ? checkData.accessTokenExpiresIn : 1800;
              if (checkData.generateNewAccessToken || parseInt(expireTime, 10) - 1800 <= 0) {
                fetch(process.env.REACT_APP_NEXTGEN_HOST + '/api/token/accesstoken', {
                  headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'anti-csrf-token': reactCookie.load(cookieNameCSRF),
                  },
                  method: 'POST',
                  credentials: 'include',
                  redirect: 'follow',
                  body: JSON.stringify({
                    replaceAccessTokenCookie: true
                  })
                }).then((rawData) => {
                  if (rawData.status === 401) {
                    logout(logoutUrl);
                  }
                  return rawData.json();
                }).then(() => {
                  axios.request(req);
                }).catch((error) => {
                  console.log('error:', error);
                  window.location.href = '/#/error';
                });
              }
            }).catch((error) => {
              console.log('error:', error);
              window.location.href = '/#/error';
            });
          }
          return req;
        },
        error: (error) => (
          error
        )
      }],
      response: []
    }
  };

  const axiosMiddleware = multiClientMiddleware(client, middlewareConfig);

  return createStore(
    rootReducer,
    initialState,
    applyMiddleware(
      axiosMiddleware
    )
  );
}
