import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Col, Row } from 'react-bootstrap';
import {
  MozartRadio,
  MozartTable,
  MozartButton,
  MozartLoading,
  MozartModal
} from '@pgforsta/mozart_ui';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadAssignUser, saveUser } from 'redux/actions/queueAssignAction';
import './QueueAssignmentCard.scss';

class QueueAssignmentCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      queueUser: [],
      modal: false,
      modal_description: '',
      load: false
    };
  }

  componentDidMount() {
    this.props.loadAssignUser().then(() => {
      if (this.props.user) {
        this.setState({
          queueUser: this.props.user ? this.props.user : [],
        });
      }
    });
  }

  onInputChange(e) {
    const clientClicked = e.target.name;
    const valueClicked = e.target.value;
    const { queueUser } = this.state;
    const nexState = queueUser.map(card => {
      if (card.id === clientClicked) {
        const checked = card.queueId === valueClicked ? card.queueId : valueClicked;
        return {
          ...card,
          id: card.id,
          name: card.name,
          queueId: parseInt(checked, 10),
          roleId: card.roleId
        };
      }
      return card;
    });
    this.setState({ queueUser: nexState });
  }

  onSubmit() {
    const updateUser = this.state.queueUser;
    this.props.saveUser(updateUser).then(() => {
      const msg = 'Users has been assigned for Queue';
      this.setState({ load: true }, () => {
        setTimeout(() => {
          this.setState({ load: false }, () => {
            setTimeout(() => {
              this.setState({ modal: true, modal_description: msg });
            }, 500);
          });
        }, 3000);
      });
    });
  }

  getRowData() {
    const DATA = [];
    const { queueUser } = this.state;
    queueUser.map((items) => {
      const QUEUE_RENDER = [];
      const ownerStatus = (
        <Row className="queue-status">
          <Col xs={3} md={3} className="queue-status-client">{items.name}</Col>
          <Col xs={3} md={3} className="queue-status-high">
            <MozartRadio>
              <input
                value={1}
                onChange={(e) => this.onInputChange(e)}
                type="radio"
                id={`high_${items.id}`}
                name={items.id}
                checked={items.queueId === 1 ? 'checked' : ''}
              />
              <label htmlFor={`high_${items.id}`} title="radio">&nbsp;</label>
            </MozartRadio>
          </Col>
          <Col xs={3} md={3} className="queue-status-medium">
            <MozartRadio>
              <input
                value={2}
                onChange={(e) => this.onInputChange(e)}
                type="radio"
                id={`medium_${items.id}`}
                name={items.id}
                checked={items.queueId === 2 ? 'checked' : ''}
              />
              <label htmlFor={`medium_${items.id}`} title="radio">&nbsp;</label>
            </MozartRadio>
          </Col>
          <Col xs={3} md={3} className="queue-status-normal">
            <MozartRadio>
              <input
                value={3}
                onChange={(e) => this.onInputChange(e)}
                type="radio"
                id={`normal_${items.id}`}
                name={items.id}
                checked={items.queueId === 3 ? 'checked' : ''}
              />
              <label htmlFor={`normal_${items.id}`} title="radio">&nbsp;</label>
            </MozartRadio>
            {/* <div><span onClick={(e) => this.deselectAll(e)} id={items.id}>deslect</span></div> */}
          </Col>
        </Row>
      );
      QUEUE_RENDER.push(ownerStatus);
      DATA.push(QUEUE_RENDER);
      return DATA;
    });
    return DATA;
  }

  getTitleData() {
    const DATA = [];
    DATA.push(
      'USERS',
      'HIGH',
      'Medium',
      'NORMAL',
    );
    return DATA;
  }

  goToPage() {
    window.location.href = '/#/me/manager';
  }

  closeModal() {
    this.setState({ modal: false, modal_description: '' });
    window.location.href = '/#/me/manager';
  }

  deselectAll(e) {
    const clientClicked = e.target.id;
    const { queueUser } = this.state;
    const nexState = queueUser.map(card => {
      if (card.id === clientClicked) {
        return {
          ...card,
          id: card.id,
          name: card.name,
          queueId: 0,
          roleId: card.roleId
        };
      }
      return card;
    });
    this.setState({ queueUser: nexState });
  }

  render() {
    console.log(this.state.load);
    const dataSave =
      (
        <div style={{ color: '#FFFFFF', fontSize: '15px', paddingTop: '0px' }}>
          <span style={{ color: '#FFFFFF', fontSize: '14px', paddingLeft: '4px' }}>Save</span>
          <span
            className="icon-Save"
            style={{
              color: '#FFFFFF',
              fontSize: '20px',
              paddingLeft: '34px',
              position: 'relative',
              verticalAlign: 'middle'
            }}
          />
        </div>
      );
    return [
      <Container fluid className="queue-user-card">
        <Row>
          <Col xs={6} md={6} className="lable-header">Queue Assignment</Col>
          <Col xs={6} md={6} className="lable-header-button">
            <div className="lable-header-cancel"><span className="lable-header-cancel1" onClick={(e) => this.goToPage(e)}>Cancel</span></div>
            <div className="lable-header-save">
              <MozartButton
                background
                data={dataSave}
                onClick={(e) => this.onSubmit(e)}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}><hr /></Col>
        </Row>
        {this.props.loadingUser ?
          <MozartLoading inline loading={this.props.loadingUser} />
          :
          this.state.queueUser &&
          (
            <MozartTable
              headers={this.getTitleData()}
              rows={this.getRowData()}
              style={{ margin: '0', width: '100%' }}
              headStyle={{ fontSize: '0.75em', width: '320px', paddingLeft: '88px' }}
              mainHeaderStyle={
                {
                  display: 'inline-block', textAlign: 'center', borderRadius: '55px'
                }
              }
              bodyStyle={{ fontSize: '14px', display: 'block' }}
              rowStyle={{ height: '4em' }}
              sticky={this.state.queueUser && this.state.queueUser.length > 0}
              scrollUpPos="0"
              colsStyle={[{ minWidth: '6em', width: '57%' }, { minWidth: '10em' }, { minWidth: '2em' }, { minWidth: '16em' }, { minWidth: '33em', width: '100%' }]}
            />
          )}
        {this.state.load && (
          <MozartLoading inlinefull loading />
        )}
        <MozartModal
          title="Queue User Assignment"
          key="queue_user"
          show={this.state.modal}
          onClose={() => this.closeModal()}
          handleSubmit={() => this.closeModal()}
          submitLabel="OK"
        >
          {this.state.modal_description}
        </MozartModal>
      </Container>
    ];
  }
}

QueueAssignmentCard.propTypes = {
  loadAssignUser: PropTypes.func,
  user: PropTypes.object,
  saveUser: PropTypes.func,
  loadingUser: PropTypes.bool
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    loadAssignUser, saveUser
  }, dispatch)
);

const mapStateToProps = state => ({
  loadingUser: state.queueAssignReducer.loadingUser,
  user: state.queueAssignReducer.user,
  saveConfig: state.userSaveReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(QueueAssignmentCard);
