import React from 'react';
import propTypes from 'prop-types';
import {
  MozartIcon
} from '@pgforsta/mozart_ui';
import './AddNewInsight.scss';

function AddNewInsight(props) {
  const {
    currentSentence
  } = props;

  return (
    <div className="add-new-insight" style={{ paddingTop: '14px', paddingLeft: '5px' }}>
      <MozartIcon
        onClick={() => {
          const insightLength = currentSentence.insights && currentSentence.insights !== null ? currentSentence.insights.length : 0;
          props.handleNewSentiment(currentSentence.id);
          props.handleOpinionModal(insightLength);
        }}
        width="32px"
        height="32px"
        data={<span className="icon-Add add-manual-sentiment" />}
      />
    </div>
  );
}

AddNewInsight.propTypes = {
  currentSentence: propTypes.object,
  handleOpinionModal: propTypes.func,
  handleNewSentiment: propTypes.func
};

export default AddNewInsight;
