import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Col from 'react-bootstrap/Col';
import { SentenceList } from 'components';
import { referContent, deleteContent, toggleContent } from 'redux/actions/contentAction';
import { load as loadDocumentAutoGenerate } from 'redux/actions/documentAutoGenerateAction';
import moment from 'moment';
import {
  MozartButton, MozartModal, MozartRadio, MozartToolTip
} from '@pgforsta/mozart_ui';
import './DocumentBlock.scss';

class DocumentBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      referDocument: false,
      referContentReason: [],
      documentData: props.documentReducer.documentData,
      contentReferReasonSelected: [],
      contentReferReason: [
        {
          name: 'Wrong provider name',
          value: 'Wrong_provider_name',
          id: 'Wrong_provider_name',
        },
        {
          name: 'Wrong location name',
          value: 'Wrong_location_name',
          id: 'Wrong_location_name',
        },
        {
          name: 'Translation / Language issue',
          value: 'Translation_Language_issue',
          id: 'Translation_Language_issue',
        },
        {
          name: 'Wrong industry',
          value: 'Wrong_industry',
          id: 'Wrong_industry',
        }
      ]
    };
    this.handleDeleteDocument = this.handleDeleteDocument.bind(this);
    this.handleToggleDocument = this.handleToggleDocument.bind(this);
    this.handleReferContentState = this.handleReferContentState.bind(this);
    this.handleSentimentCountChange = this.handleSentimentCountChange.bind(this);
  }

  getDocumentDetails() {
    const { documentData } = this.state;
    const dataObj = documentData;
    const containerStyle = { marginTop: '3px', display: 'flex', justifyContent: 'flex-end' };
    const itemStyle = { margin: '5px' };
    const containerStatusStyle = { marginTop: '3px', display: 'flex', justifyContent: 'flex-end' };
    const dateString = new Date(dataObj.fetchedDate).toLocaleDateString();
    const nlpStatusBtn = dataObj.nlpCompleted ? {} : { border: '1px solid #776BB2', backgroundColor: '#403788' };
    const qc1StatusBtn = dataObj.qc1Completed ? {} : { border: '1px solid #776BB2', backgroundColor: '#403788' };
    const qc2StatusBtn = dataObj.qc2Completed ? {} : { border: '1px solid #776BB2', backgroundColor: '#403788' };
    let toggleAccess = false;
    const enableDeleteDoc = false;
    if (this.props.userPermissions && this.props.userPermissions.length > 0) {
      if (this.props.userPermissions.includes(580) || this.props.userPermissions.includes(581)) {
        if (dataObj.qc1Completed || dataObj.qc2Completed) {
          toggleAccess = true;
        }
      }
    }
    return (
      <div className="documentHeaderBlock">
        <Col xs={12} md={12} className="documentHeaderBlockParentDoc">
          <Col xs={12} md={7} className="documentHeaderLeftBlockParent">
            <Col xs={4} md={2}>
              <div className="documentHeaderTitle">Document ID:</div>
              <div className="documentHeaderValue">{dataObj.documentId}</div>
            </Col>
            <Col xs={4} md={2}>
              <div className="documentHeaderTitle">Content ID:</div>
              <div className="documentHeaderValue">{dataObj.contentId}</div>
            </Col>
            <Col xs={4} md={3}>
              <div className="documentHeaderTitle">No. of Sentences:</div>
              <div className="documentHeaderValue">{dataObj.numberOfSentences}</div>
            </Col>
            <Col xs={8} md={4}>
              <div className="documentHeaderTitle">No. of Insights:</div>
              <div className="documentHeaderValue">{dataObj.numberOfInsights}</div>
            </Col>
          </Col>
          <Col xs={8} md={5} style={containerStyle}>
            <Col xs={4} md={4} style={{ textAlign: 'right', maxWidth: '190px', display: toggleAccess ? 'display' : 'none' }} key={toggleAccess}>
              <MozartButton
                data="Toggle Status"
                className="btn-toggle-document"
                onClick={
                  () => { this.handleToggleDocument(); }
                }
                icon={<span className="icon-ToggleBack" />}
                style={{ display: toggleAccess ? 'display' : 'none' }}
              />
            </Col>
            <Col xs={4} md={4} style={{ textAlign: 'right', maxWidth: '190px', display: enableDeleteDoc ? 'display' : 'none' }}>
              <MozartButton
                data="Delete Document"
                className="btn-delete-document"
                onClick={
                  () => { this.handleDeleteDocument(); }
                }
                icon={<span className="icon-Delete" />}
                style={{ display: enableDeleteDoc ? 'display' : 'none' }}
              />
            </Col>
            <Col xs={4} md={4} style={{ textAlign: 'right', maxWidth: '190px' }}>
              <MozartButton
                data="Refer Content"
                className="btn-refer-document"
                onClick={
                  () => { this.handleReferDocument(); }
                }
                icon={<span className="icon-Flagged" />}
                id={this.state.referDocument ? this.state.referDocument : false}
              />
            </Col>
          </Col>
        </Col>
        <div className="content-header-parent">
          <Col xs={12} md={12} className="documentHeaderBlockParent">
            <Col xs={12} md={10} className="contentHeaderLeftBlockParent">
              <Col xs={3} md={3}>
                <div className="contentHeaderTitle">Provider / Location</div>
                <div className="contentHeaderValue">{dataObj.providerLocation}</div>
              </Col>
              <Col xs={1} md={1}>
                <div className="contentHeaderTitle">Date</div>
                <div className="contentHeaderValue">{dateString}</div>
              </Col>
              <Col xs={2} md={2}>
                <div className="contentHeaderTitleSource">Content Source</div>
                <div className="contentHeaderValueSource"><a href={dataObj.documentLink} target="_blank" rel="noopener noreferrer">{dataObj.sourceName}</a></div>
              </Col>
              <Col xs={6} md={6}>
                <div className="contentHeaderTitle">Modified By</div>
                <div className="contentHeaderValue">{dataObj.sentimentUpdatedBy && dataObj.sentimentUpdatedBy !== null && dataObj.sentimentUpdatedAt && dataObj.sentimentUpdatedAt !== null ? '`' + dataObj.sentimentUpdatedBy + '` On ' + moment(dataObj.sentimentUpdatedAt).utc().format('MM/DD/YYYY hh:mm:ss A') + ' UTC' : '-'}</div>
              </Col>
            </Col>
            <Col xs={8} md={2} style={containerStatusStyle}>
              <div style={itemStyle}>
                <div data="Delete Document" className="nlp-status" style={nlpStatusBtn}>
                  <span className="nlp-status-text">NLP</span>
                  <span className="icon-CheckCircle nlp-status-icon" />
                </div>
              </div>
              <div style={itemStyle}>
                <div data="Delete Document" className="nlp-status" style={qc1StatusBtn}>
                  {dataObj.qc1Completed ?
                    (
                      <div>
                        <MozartToolTip
                          key="binary_stats_tooltip"
                          node={
                            (
                              <label htmlFor="qctool" className="nlp-status-text">
                                QC1
                                <span className="icon-CheckCircle nlp-status-icon" />
                              </label>
                            )
                          }
                          placement="bottom"
                          data={
                            (
                              <span
                                id="qctool"
                                style={{
                                  fontSize: '12px',
                                  color: '#776BB2',
                                  minWidth: '150px',
                                  width: 'auto'
                                }}
                              >
                                <span style={{ fontWeight: 'bold' }}>
                                  QC1 by :
                                </span>
                                {' `' + dataObj.qc1By + '` On ' + moment(dataObj.qc1Time).utc().format('MM/DD/YYYY hh:mm:ss A') + ' UTC'}
                              </span>
                            )
                          }
                        />
                      </div>
                    )
                    :
                      <span className="nlp-status-text">QC1</span>}
                  <span className="icon-CheckCircle nlp-status-icon" />
                </div>
              </div>
              <div style={itemStyle}>
                <div data="Delete Document" className="nlp-status" style={qc2StatusBtn}>
                  {dataObj.qc2Completed ?
                    (
                      <div>
                        <MozartToolTip
                          key="binary_stats_tooltip"
                          node={
                            (
                              <label htmlFor="qctool" className="nlp-status-text">
                                QC2
                                <span className="icon-CheckCircle nlp-status-icon" />
                              </label>
                            )
                          }
                          placement="bottom"
                          data={
                            (
                              <span
                                id="qctool"
                                style={{
                                  fontSize: '12px',
                                  color: '#776BB2'
                                }}
                              >
                                <span style={{ fontWeight: 'bold' }}>
                                  QC2 by :
                                </span>
                                {' `' + dataObj.qc2By + '` On ' + moment(dataObj.qc2Time).utc().format('MM/DD/YYYY hh:mm:ss A') + ' UTC'}
                              </span>
                            )
                          }
                        />
                      </div>
                    )
                    :
                      <span className="nlp-status-text">QC2</span>}
                  <span className="icon-CheckCircle nlp-status-icon" />
                </div>
              </div>
            </Col>
          </Col>
          <Col xs={12} md={12} className="documentContentBlock" id="documentContentBlock" style={{ backgroundColor: 'white' }}>
            {this.getDocumentContent(dataObj.fullReview, dataObj.surveyQuestion)}
          </Col>
          <Col xs={12} md={12} className="sentence-list-block">
            {this.getSentenceList(dataObj.sentences)}
          </Col>
          <div>
            {this.state.referDocument && this.getReferContentModal()}
          </div>
        </div>
      </div>
    );
  }

  getDocumentContent(fullReview, surveyQuestion) {
    let surveyQuestionSpan = '';
    if (surveyQuestion !== null) {
      surveyQuestionSpan = (
        <div className="survey-question-div">
          <div>
            <MozartButton
              className="survey-question-btn"
              data="Survey Question"
            />
          </div>
          <div style={{ paddingLeft: '16px', paddingTop: '2px' }}>
            <span>{surveyQuestion}</span>
          </div>
        </div>
      );
    }
    return (
      <div className="documentContent">
        <div>{surveyQuestionSpan}</div>
        <div style={{ overflowY: 'auto', maxHeight: '175px', paddingLeft: '10px' }}>
          {fullReview}
        </div>
      </div>
    );
  }

  getSentenceList(sentences) {
    const { category } = this.props;
    return (
      <div>
        {sentences && sentences.length > 0 && category !== null && (
        <SentenceList
          category={category}
          handleNextDocument={this.props.handleNextDocument}
          handleNextAllDocument={this.props.handleNextAllDocument}
          handleSentimentCountChange={this.handleSentimentCountChange}
        />
        )}
      </div>
    );
  }

  getReferContentModal() {
    const content = [];
    this.state.contentReferReason.forEach((refer) => {
      content.push({
        name: refer.name,
        value: refer.id,
        checked: this.state.contentReferReasonSelected && this.state.contentReferReasonSelected.value ? this.state.contentReferReasonSelected.value === refer.id : 0,
        disabled: false
      });
    });
    let enableClass = 'add-button-disable';
    if (this.state.contentReferReasonSelected && this.state.contentReferReasonSelected.value) {
      enableClass = 'add-button-enable';
    }
    return (
      <MozartModal
        show={this.state.referDocument}
        onClose={() => { this.showHide(); }}
        title="Select Reason for review Submission"
        width="935px"
        contentPadding="20px"
        className="review-modal"
        submitLabel="Submit"
        handleSubmit={() => { this.handleReferContent(); }}
        id={this.state.referDocument}
        submitButtonClassName={enableClass}
        enableCancel
      >
        <div className="reviewsSentimentsBlock">
          <Col xs={12} md={12} className="review-sentiment-content-section-refer-content">
            <MozartRadio
              onChange={(data) => { this.handleReferContentState(data); }}
              groupname="refer_content_radio"
              content={content}
              key="refer_content_radio"
            />
          </Col>
        </div>
      </MozartModal>
    );
  }

  showHide() {
    this.setState({
      referDocument: false
    });
  }

  handleReferContentState(reasonList) {
    this.state.referContentReason = reasonList;
    this.state.contentReferReasonSelected = reasonList;
    this.setState(previousState => ({
      referContentReason: previousState.referContentReason,
      contentReferReasonSelected: previousState.contentReferReasonSelected
    }));
  }

  handleReferContent() {
    this.props.referContent(this.props.documentAutoGenerateReducer.contentId, this.state.contentReferReasonSelected.value).then((response) => {
      if (response && response.payload.status && response.payload.status === 200) {
        this.props.handleNextDocument();
      }
    });
  }

  handleDeleteDocument() {
    if (window.confirm('Are you sure you want to delete this content?')) {
      this.props.deleteContent(this.props.documentAutoGenerateReducer.contentId).then((response) => {
        if (response && response.payload.status && response.payload.status === 200) {
          this.props.handleNextDocument();
        }
      });
    }
  }

  handleToggleDocument() {
    this.props.toggleContent(this.props.documentAutoGenerateReducer.contentId).then((response) => {
      if (response && response.payload.status && response.payload.status === 200) {
        this.props.handleNextDocument();
      }
    });
  }

  handleSentimentCountChange(action) {
    if (action === 'add') {
      this.state.documentData.numberOfInsights += 1;
    } else {
      this.state.documentData.numberOfInsights -= 1;
    }
    this.setState(previousState => ({
      documentData: previousState.documentData
    }));
  }

  handleReferDocument() {
    this.setState({
      referDocument: true
    });
  }

  render() {
    return (
      <div>
        {this.getDocumentDetails()}
      </div>
    );
  }
}

DocumentBlock.propTypes = {
  userPermissions: PropTypes.array,
  category: PropTypes.object,
  documentData: PropTypes.object,
  documentAutoGenerateReducer: PropTypes.object,
  referContent: PropTypes.func,
  toggleContent: PropTypes.func,
  deleteContent: PropTypes.func,
  documentReducer: PropTypes.func,
  handleNextDocument: PropTypes.func,
  handleNextAllDocument: PropTypes.func
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    loadDocumentAutoGenerate, referContent, deleteContent, toggleContent
  }, dispatch)
);

const mapStateToProps = state => ({
  documentReducer: state.documentReducer,
  userPermissions: state.authReducer.permissions,
  documentAutoGenerateReducer: state.documentAutoGenerateReducer
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentBlock);
