export const LOAD = 'binary/report/LOAD';
export const LOAD_SUCCESS = 'binary/report/LOAD_SUCCESS';
export const LOAD_FAIL = 'binary/report/LOAD_FAIL';

export function loadFlatReports(data) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    payload: {
      client: 'reportsapi',
      request: {
        method: 'get',
        url: 'api/reports/list?excludeDashboardReports=true',
        data: data
      }
    }
  };
}
