import { LOAD, LOAD_SUCCESS, LOAD_FAIL } from 'redux/actions/queueAction';

const initialState = {
  loaded: false,
  loading: false,
  userBuckets: [],
  selectedQueue: false,
  // userBuckets: JSON.parse('[{"id":2,"name":"Multifamily"},{"id":4,"name":"Healthcare Toggle"},{"id":5,"name":"Multifamily Toggle"}]'),
};

function queueReducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case LOAD_SUCCESS:
      const userBucketList = state.userBuckets ? { ...state.userBuckets, ...action.payload.data } : action.payload.data;
      return {
        ...state,
        loading: false,
        loaded: true,
        userBuckets: userBucketList,
        selectedQueue: userBucketList && userBucketList[0] ? userBucketList[0].id : true,
      };
    case LOAD_FAIL:
      // const data = '[{"id":2,"name":"Multifamily"},{"id":4,"name":"Healthcare Toggle"},{"id":5,"name":"Multifamily Toggle"}]';
      return {
        ...state,
        loading: false,
        loaded: false,
        // userBuckets: JSON.parse(data)
        error: action.error,
        selectedQueue: false
      };
    default:
      return state;
  }
}

export default queueReducer;
