export const LOAD = 'binary/document/LOAD';
export const LOAD_SUCCESS = 'binary/document/LOAD_SUCCESS';
export const LOAD_FAIL = 'binary/document/LOAD_FAIL';
export const LOCK = 'binary/document/LOCK_DELETE';
export const LOCK_SUCCESS = 'binary/document/LOCK_SUCCESS';
export const LOCK_FAIL = 'binary/document/LOCK_FAIL';
export const DELETE = 'binary/document/DELETE';
export const DELETE_SUCCESS = 'binary/document/DELETE_SUCCESS';
export const DELETE_FAIL = 'binary/document/DELETE_FAIL';
export const LOCK_DOCUMENT = 'binary/document/LOCK_DOCUMENT';
export const LOCK_DOCUMENT_SUCCESS = 'binary/document/LOCK_DOCUMENT_SUCCESS';
export const LOCK_DOCUMENT_FAIL = 'binary/document/LOCK_DOCUMENT_FAIL';

export function load(contentId) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],

    payload: {
      client: 'content',
      request: {
        method: 'get',
        url: '/api/contents/' + contentId + '/sentences'
      }
    }
  };
}

export function deleteDocument(contentId) {
  return {
    types: [DELETE, DELETE_SUCCESS, DELETE_FAIL],

    payload: {
      client: 'content',
      request: {
        method: 'get',
        url: '/api/contents/' + contentId
      }
    }
  };
}

export function lock(contentId) {
  const lockTrueData = {
    contentIds: [contentId],
    release: true
  };
  return {
    types: [LOCK, LOCK_SUCCESS, LOCK_FAIL],

    payload: {
      client: 'tagging',
      request: {
        method: 'post',
        data: lockTrueData,
        url: '/api/content-edit/lock',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    }
  };
}

export function lockDocument(postData) { // this api combine 4 api into this
  return {
    types: [LOCK_DOCUMENT, LOCK_DOCUMENT_SUCCESS, LOCK_DOCUMENT_FAIL],

    payload: {
      client: 'tagging',
      request: {
        method: 'post',
        data: postData,
        url: '/api/content/tag',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    }
  };
}
