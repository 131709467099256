import {
  LOAD_TASK,
  LOAD_TASK_SUCCESS,
  LOAD_TASK_FAIL
} from 'redux/actions/taskUserAction';

const initialState = {
  loadedTask: false,
  loadingTask: false,
  task: []
};

function taskUserReducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_TASK:
      return {
        ...state,
        loadingTask: true,
        loadedTask: false
      };
    case LOAD_TASK_SUCCESS:
      const userTask = state.data ? { ...state.data, ...action.payload.data } : action.payload.data;
      return {
        ...state,
        loadingTask: false,
        loadedTask: true,
        task: userTask
      };
    case LOAD_TASK_FAIL:
      return {
        ...state,
        loadingTask: false,
        loadedTask: false,
        error: action.error
      };
    default:
      return state;
  }
}
export default taskUserReducer;
