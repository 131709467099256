export const LOAD = 'binary/responderList/LOAD';
export const LOAD_SUCCESS = 'binary/responderList/LOAD_SUCCESS';
export const LOAD_FAIL = 'binary/responderList/LOAD_FAIL';

export function loadResponderList(clientId, taskId) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    payload: {
      client: 'managementengagement',
      request: {
        method: 'get',
        url: 'api/tasks/' + clientId + '/' + taskId + '/taskowners'
      }
    }
  };
}
