import React, { useState } from 'react';
import propTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import { connect } from 'react-redux';
import { DeleteSentimentModal } from 'components';
import {
  MozartSelectField, MozartButton, MozartIcon, MozartTextBox, MozartPopover
} from '@pgforsta/mozart_ui';
import './Insight.scss';

function Insight(props) {
  const {
    insight, index, openEditor, sentencesId, category, documentReducer
  } = props;

  const badgePillerstyle = { maxWidth: '100px', margin: '5px', textAlign: 'center' };
  const [actionsKey, setActionsKey] = useState(Math.random());
  const [subCategoryKey, setSubCategoryKey] = useState(Math.random());
  const [badgeKey, setBadgeKey] = useState(Math.random());
  const [currentInsightId, setCurrentInsightId] = useState('');
  const [openDeleteSentimentModal, setOpenDeleteSentimentModal] = useState(false);
  const [currentInsightIndexForDelete, setCurrentInsightIndexForDelete] = useState('');
  const [currentSentenceIdForDelete, setCurrentSentenceIdForDelete] = useState('');

  let sentimentPosivieBtnStyle = { borderRadius: '25px', border: '1px solid #47B300' };
  let sentimentNegativeBtnStyle = { borderRadius: '25px', border: '1px solid #E66B6B' };
  let sentimentNeutralBtnStyle = { borderRadius: '25px', border: '1px solid #979797' };
  let postiveBgColor = '#FFFF';
  let positiveTextColor = '#47B300';
  let negativeTextColor = '#E66B6B';
  let negativeBgColor = '#ffff';
  let neutralTextColor = '#979797';
  let neutralBgColor = '#FFFF';
  let defaultCategory = (insight && insight.categoryId && insight.categoryId > 0 && insight.id > 0) ? insight.categoryId : 0;
  if (insight.previousCategory !== null) {
    defaultCategory = insight.categoryId;
  }
  const defaultOpinion = insight.opinion;
  let defaultOpinionText = defaultOpinion;
  if (!insight.newSentimentStatus) {
    defaultOpinionText = '';
  }
  let currentFeature = insight.feature ? insight.feature : '';
  if (insight.featureAltKeyword && insight.id > 0) {
    currentFeature = insight.featureAltKeyword;
  } else if (insight.id === null) {
    currentFeature = insight.feature ? insight.feature : insight.featureAltKeyword;
  }
  const defaultFeature = insight.feature;
  let defaultFeatureText = defaultOpinion;
  if (insight.newSentimentStatus) {
    defaultFeatureText = insight.featureAltKeyword ? insight.featureAltKeyword : defaultFeature;
  } else {
    defaultFeatureText = '';
  }
  let rowHighlightStyleFlag = true;
  let styleSaveButton = { display: 'none' };
  if (insight.sentiment && insight.opinion && currentFeature && insight.categoryId && insight.themeId) {
    if (!openEditor && (insight.previousSentiment !== insight.sentiment || insight.previousCategory !== insight.categoryId || insight.previousThemeId !== insight.themeId)) {
      styleSaveButton = {};
    }
    rowHighlightStyleFlag = false;
  }
  let rowHighlightStyle = { };
  if (insight.id === null || rowHighlightStyleFlag) {
    rowHighlightStyle = { background: '#FFE8E3' };
  }
  let opinionCustomText = '';
  let featureCustomText = '';

  if (insight.opinion && insight.id > 0) {
    const infoOpinionBlock = (
      <MozartPopover
        icon={<a style={{ cursor: 'pointer', color: '#17A589', fontSize: '12px' }} href>view more</a>}
        style={{
          minWidth: '600px', padding: '20px 10px', maxHeight: 'auto', zIndex: 100000, position: 'absolute', border: '1px solid #D3CFF8', boxShadow: 'unset'
        }}
        node={(
          <div>
            {insight.opinion}
          </div>
        )}
      />
    );
    opinionCustomText = insight.opinion && insight.opinion.length >= 45 ?
      (
        <div>
          <div>
            {insight.opinion.substring(0, 45) + '...'}
          </div>
          <div>
            {infoOpinionBlock}
          </div>
        </div>
      ) : insight.opinion;
  }
  if (insight.feature && insight.id > 0) {
    const infoFeatureBlock = (
      <MozartPopover
        icon={<a style={{ cursor: 'pointer', color: '#17A589', fontSize: '12px' }} href>view more</a>}
        style={{
          minWidth: '600px', padding: '20px 10px', maxHeight: 'auto', zIndex: 100000, position: 'absolute', border: '1px solid #D3CFF8', boxShadow: 'unset'
        }}
        node={(
          <div>
            {currentFeature}
          </div>
        )}
      />
    );
    featureCustomText = currentFeature && currentFeature.length >= 45 ?
      (
        <div>
          <div>
            {currentFeature.substring(0, 40) + '...'}
          </div>
          <div>
            {infoFeatureBlock}
          </div>
        </div>
      ) : currentFeature;
  }

  const optionTextBox = (
    <MozartTextBox
      id="opinion"
      className="opinion"
      name="opinion"
      multiLine={false}
      validationBool={false}
      width="300px"
      disabled={false}
      onFocus={
        () => { props.handleOpinionModal(index, sentencesId); }
      }
      defaultValue={defaultOpinionText}
    />
  );

  const featureTextBox = (
    <MozartTextBox
      id="feature"
      className="feature"
      name="feature"
      multiLine={false}
      validationBool={false}
      width="300px"
      disabled={false}
      onFocus={
        () => { props.handleOpinionModal(index, sentencesId); }
      }
      defaultValue={defaultFeatureText}
    />
  );

  const setBadgeStyle = (type) => {
    if (type && type === 'negative') {
      sentimentNegativeBtnStyle = { backgroundColor: '#E66B6B', borderRadius: '25px', border: '1px solid #E66B6B' };
      negativeTextColor = '#ffff';
      negativeBgColor = '#E66B6B';
    }
    if (type && type === 'neutral') {
      sentimentNeutralBtnStyle = { backgroundColor: '#979797', borderRadius: '25px', border: '1px solid #979797' };
      neutralTextColor = '#ffff';
      neutralBgColor = '#979797';
    }
    if (type && type === 'positive') {
      postiveBgColor = '#47B300';
      positiveTextColor = '#FFFF';
      sentimentPosivieBtnStyle = { borderRadius: '25px', border: '1px solid #47B300' };
    }
  };

  const getCategoryList = () => {
    const categoryList = [];
    const categories = category && category.children ? category.children : [];
    if (categories.length > 0) {
      categories.forEach((categoryItem) => {
        categoryList.push({ value: categoryItem.id, name: categoryItem.name, id: categoryItem.id });
      });
    }
    return categoryList;
  };

  const getSubCategoryList = () => {
    let categoryList = [];
    const product = documentReducer && documentReducer.documentData ? documentReducer.documentData.product : null;
    if (insight.subCategory.length > 0) {
      insight.subCategory.forEach((cat) => {
        if (product === 'employee' || product === 'nursing' || product === 'pharmacy') {
          if ((product === 'employee' || product === 'nursing' || product === 'pharmacy') && (cat.parentId === insight.categoryId || cat.id === insight.categoryId)) {
            categoryList.push({ value: cat.id, name: cat.name, id: cat.id });
          }
        } else if (cat.parentId === insight.categoryId && (product !== 'employee' || product !== 'nursing' || product !== 'pharmacy')) {
          categoryList.push({ value: cat.id, name: cat.name, id: cat.id });
        }
      });
      if (insight.id === null && insight.categoryId > 0) {
        categoryList = [];
        insight.subCategory.forEach((cat) => {
          if ((cat.parentId === insight.categoryId) || ((product === 'employee' || product === 'nursing' || product === 'pharmacy') && cat.id === insight.categoryId)) {
            categoryList.push({ value: cat.id, name: cat.name, id: cat.id });
          }
        });
      }
    }
    return categoryList;
  };

  const handleSentiment = (type) => {
    setBadgeKey(Math.random());
    setBadgeStyle(type);
    props.handleSentiment(index, sentencesId, type);
  };

  const handleDeleteSentimentById = () => {
    setOpenDeleteSentimentModal(false);
    props.handleDeleteSentimentById(currentSentenceIdForDelete, currentInsightIndexForDelete);
  };

  const showHideDeleteSentimentModal = () => {
    setOpenDeleteSentimentModal(false);
  };

  const handleSaveSentiment = () => {
    props.handleSaveSentiment(index, sentencesId, insight.id);
  };

  setBadgeStyle(insight.sentiment);

  return (
    <div className="sentance-sentiment-container" style={rowHighlightStyle}>
      <Col xs={12} md={12} className="sentence-sentiment-sub-block">
        <Col xs={3} md={3} className="sentiment-opinion-block sentiment-block-items">
          <div className="sentiment-opinion">Opinion</div>
          <div className="sentiment-opinion-value" style={{ wordBreak: 'break-all' }}>{(insight.opinion && insight.id > 0) ? opinionCustomText : optionTextBox}</div>
        </Col>
        <Col xs={3} md={2} className="sentiment-opinion-block sentiment-block-items">
          <div className="sentiment-opinion">Feature</div>
          <div className="sentiment-opinion-value sentiment-feature-text" style={{ wordBreak: 'break-all' }}>{(insight.feature && insight.id > 0) ? featureCustomText : featureTextBox}</div>
        </Col>
        <Col
          xs={3}
          md={1}
          className="sentiment-opinion-block sentiment-block-items"
          style={{
            textAlign: 'center', marginTop: '-15px', border: 'unset', maxWidth: 'fit-content'
          }}
        >
          <div style={badgePillerstyle} key={badgeKey}>
            <MozartButton
              onClick={
                () => { handleSentiment('negative'); }
              }
              className="sentimentBtnList"
              data="Negative"
              background={negativeBgColor}
              style={sentimentNegativeBtnStyle}
              color={negativeTextColor}
              width="25px"
              key={insight.id}
            />
          </div>
          <div style={badgePillerstyle}>
            <MozartButton
              onClick={
                () => { handleSentiment('neutral'); }
              }
              className="sentimentBtnList"
              data="Neutral"
              background={neutralBgColor}
              style={sentimentNeutralBtnStyle}
              color={neutralTextColor}
              width="25px"
              key={insight.id}
            />
          </div>
          <div style={badgePillerstyle}>
            <MozartButton
              onClick={
                () => { handleSentiment('positive'); }
              }
              className="sentimentBtnList"
              data="Positive"
              background={postiveBgColor}
              style={sentimentPosivieBtnStyle}
              color={positiveTextColor}
              width="25px"
              key={insight.id}
            />
          </div>
        </Col>
        <div className="sentiment-opinion-block sentiment-block-items sentiment-block-items-category" style={{ width: '41.2%' }}>
          <div style={{ width: '50%', paddingLeft: '20px' }}>
            <div className="sentiment-opinion">Category</div>
            <div className="sentiment-opinion-value category-select-list">
              <MozartSelectField
                data={getCategoryList()}
                name="category"
                onChange={(data) => {
                  setSubCategoryKey(Math.random());
                  props.handleCategoryChange(data, index);
                }}
                width="80%"
                defaultValue={insight.categoryId > 0 ? insight.categoryId : defaultCategory}
                key={insight.categoryId > 0 ? insight.categoryId + '_category' : index + '_category'}
              />
            </div>
          </div>
          <div style={{ width: '50%' }}>
            <div className="sentiment-opinion">Sub-Category</div>
            <div className="sentiment-opinion-value sub-category-select-list" key={subCategoryKey}>
              <MozartSelectField
                data={getSubCategoryList()}
                name="theme"
                onChange={(data) => {
                  setActionsKey(Math.random());
                  props.handleCategoryThemeChange(data, index);
                }}
                width="80%"
                defaultValue={insight.themeId > 0 ? insight.themeId : ''}
                key={insight.categoryId > 0 ? insight.categoryId + '_theme' : index + '_theme'}
              />
            </div>
          </div>
        </div>
        <Col xs={2} md={1} className="sentiment-opinion-block sentiment-block-items sentiment-block-items-actions" style={{ justifyContent: 'center', padding: '0px' }}>
          <div
            className="nlp-type-sentiment"
            style={{
              marginLeft: '35%',
              minWidth: '60px',
              width: 'fit-content',
              textAlign: '-webkit-center'
            }}
          >
            {insight.sentimentByNlp ? 'NLP' : 'Manual'}
          </div>
          <div className="action-sentiment-circle-icon" style={{ justifyContent: 'center', paddingLeft: '25px' }} key={actionsKey}>
            <div className="save-icon-div">
              <MozartIcon
                width="30px"
                className="check-icon"
                height="30px"
                onClick={() => {
                  handleSaveSentiment();
                }}
                data={<span className="icon-Check" />}
                style={styleSaveButton}
              />
            </div>
            <div className="reset-icon-div">
              <MozartIcon
                width="30px"
                className="reset-icon"
                height="30px"
                data={<span className="icon-Reset" />}
                onClick={() => {
                  props.handleResetSentiment(index, sentencesId, insight.id);
                }}
                style={styleSaveButton}
              />
            </div>
            <div className="delete-icon-div">
              <MozartIcon
                width="30px"
                className="delete-icon"
                height="30px"
                id={openDeleteSentimentModal}
                key={openDeleteSentimentModal}
                onClick={() => {
                  if (insight.id && insight.id > 0) {
                    setOpenDeleteSentimentModal(true);
                    setCurrentInsightId(insight.id);
                    setCurrentSentenceIdForDelete(sentencesId);
                    setCurrentInsightIndexForDelete(index);
                  } else {
                    setOpenDeleteSentimentModal(false);
                  }
                  props.handleDeleteSentimentModal(index, sentencesId, insight.id);
                }}
                data={<span className="icon-Delete" />}
              />
            </div>
          </div>
        </Col>
      </Col>
      <DeleteSentimentModal
        currentInsightId={currentInsightId}
        openDeleteSentimentModal={openDeleteSentimentModal}
        showHideDeleteSentimentModal={showHideDeleteSentimentModal}
        handleDeleteSentimentById={handleDeleteSentimentById}
      />
    </div>
  );
}

Insight.propTypes = {
  openEditor: propTypes.bool,
  index: propTypes.number,
  sentencesId: propTypes.number,
  insight: propTypes.object,
  category: propTypes.object,
  documentReducer: propTypes.object,
  handleSentiment: propTypes.func,
  handleOpinionModal: propTypes.func,
  handleSaveSentiment: propTypes.func,
  handleCategoryChange: propTypes.func,
  handleResetSentiment: propTypes.func,
  handleDeleteSentimentById: propTypes.func,
  handleCategoryThemeChange: propTypes.func,
  handleDeleteSentimentModal: propTypes.func,
};

const mapStateToProps = state => ({
  documentReducer: state.documentReducer,
});

export default connect(mapStateToProps)(Insight);
