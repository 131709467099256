import { LOAD, LOAD_SUCCESS, LOAD_FAIL } from 'redux/actions/meRolesAction';

const initialState = {
  loaded: false,
  loading: false,
  data: [],
};

function meRolesReducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case LOAD_SUCCESS:
      const rolesData = action.payload.data;
      return {
        ...state,
        loading: false,
        loaded: true,
        data: rolesData.data,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export default meRolesReducer;
