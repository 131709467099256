import {
  DELETE,
  DELETE_SUCCESS,
  DELETE_FAIL,
  SAVE,
  SAVE_SUCCESS,
  SAVE_FAIL,
  UPDATE,
  UPDATE_SUCCESS,
  UPDATE_FAIL,
} from 'redux/actions/SentimentAction';

const initialState = {
  loaded: false,
  loading: false,
  deleteStatus: [],
  savedData: []
};

function sentimentReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SAVE:
    case UPDATE:
    case DELETE:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case SAVE_SUCCESS:
      const documentSaveData = action.payload.data ? action.payload.data : '';
      return {
        ...state,
        loading: false,
        loaded: true,
        savedData: documentSaveData,
      };
    case SAVE_FAIL:
    case UPDATE_FAIL:
    case DELETE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    case UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true
      };

    case DELETE_SUCCESS:
      const dataList = action.payload.data ? action.payload.data : '';
      return {
        ...state,
        loading: false,
        loaded: true,
        deleteStatus: dataList
      };
    default:
      return state;
  }
}

export default sentimentReducer;
