export const SAVE = 'binary/sentiment/SAVE';
export const SAVE_SUCCESS = 'binary/sentiment/SAVE_SUCCESS';
export const SAVE_FAIL = 'binary/sentiment/SAVE_FAIL';
export const UPDATE = 'binary/sentiment/UPDATE';
export const UPDATE_SUCCESS = 'binary/sentiment/UPDATE_SUCCESS';
export const UPDATE_FAIL = 'binary/sentiment/UPDATE_FAIL';
export const DELETE = 'binary/sentiment/DELETE';
export const DELETE_SUCCESS = 'binary/sentiment/DELETE_SUCCESS';
export const DELETE_FAIL = 'binary/sentiment/DELETE_FAIL';

export function saveSentimentDocument(contentId, sentencesId, insightData) {
  return {
    types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],

    payload: {
      client: 'content',
      request: {
        method: 'post',
        data: insightData,
        url: '/api/contents/' + contentId + '/sentences/' + sentencesId + '/sentiments',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    }
  };
}

export function sentimentUpdateContent(insightId, insightData) {
  return {
    types: [UPDATE, UPDATE_SUCCESS, UPDATE_FAIL],

    payload: {
      client: 'content',
      request: {
        method: 'put',
        data: insightData,
        url: '/api/sentiments/' + insightId,
        headers: {
          'Content-Type': 'application/json'
        }
      }
    }
  };
}

export function deleteSentiment(insightId, deleteReason) {
  return {
    types: [DELETE, DELETE_SUCCESS, DELETE_FAIL],

    payload: {
      client: 'content',
      request: {
        method: 'DELETE',
        data: deleteReason,
        url: '/api/sentiments/' + insightId,
        headers: {
          'Content-Type': 'application/json'
        }
      }
    }
  };
}
