import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadStatus, repushHistory, deleteHistory } from 'redux/actions/reportMonitorAction';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import { NotAvailable } from 'components';
import {
  MozartButton, MozartModal, MozartTable, MozartLoading
} from '@pgforsta/mozart_ui';

function Action(props) {
  const [actionId, setActionId] = useState([]);
  const [actionType, setActionType] = useState();
  const [actionButtonText, setActionButtonText] = useState();
  const [confirmDialog, setConfirmDialog] = useState(false);
  const {
    data, loading, openFilter
  } = props;
  let { filter } = props;

  useEffect(() => {
    if (filter) filter.status = ['error']; else filter = { status: ['error'] };
    props.loadStatus(filter);
  }, [filter]);

  const getMonitorInfo = () => {
    const headerBlock = (
      <div>
        <Row className="monitorHeader">
          <Col xs="3" md="3" className="infoBlock">
            <div className="infoBlockTitle">CLIENT</div>
            <div className="infoBlockTitleValue">{getFilterInfo('clients')}</div>
          </Col>
          <Col xs="2" md="2" className="infoBlock">
            <div className="infoBlockTitle">USER</div>
            <div className="infoBlockTitleValue">{getFilterInfo('users')}</div>
          </Col>
          <Col xs="3" md="3" className="infoBlock">
            <div className="infoBlockTitle">REPORT</div>
            <div className="infoBlockTitleValue">{getFilterInfo('reports')}</div>
          </Col>
          <Col xs="2" md="2" className="infoBlock">
            <div className="infoBlockTitle">DATE RANGE</div>
            {/* <div className="infoBlockTitleValue">04/12/2012 - 04/12/2012</div> */}
            <div className="infoBlockTitleValue">{getFilterInfo('dateRange')}</div>
          </Col>
          <Col xs="2" md="2" className="infoBlock">
            <div className="infoBlockTitle">
              <MozartButton
                id="filter"
                data={(
                  <span>
                    Filter
                    <i className="icon-Configuration filterpaddingIcon" />
                  </span>
                )}
                onClick={() => openFilter()}
                background
                style={{
                  width: '1em',
                  marginTop: '5px'
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
    return headerBlock;
  };

  const getFilterInfo = (type) => {
    const info = [];
    switch (type) {
      case 'clients':
        if (filter && filter.clientNames && filter.clientNames.length > 0) {
          filter.clientNames.forEach(client => info.push(client));
        }
        break;
      case 'users':
        if (filter && filter.userNames && filter.userNames.length > 0) {
          filter.userNames.forEach(user => info.push(user));
        }
        break;
      case 'reports':
        if (filter && filter.reportNames && filter.reportNames.length > 0) {
          filter.reportNames.forEach(report => info.push(report));
        }
        break;
      case 'dateRange':
        if (filter && filter.dateRange && filter.dateRange.split('_').length > 0) {
          info.push(
            moment(filter.dateRange.split('_')[0]).format('MM/DD/YYYY') +
            ' - ' +
            moment(filter.dateRange.split('_')[1]).format('MM/DD/YYYY')
          );
        }
        break;
      default:
        break;
    }
    return info.length ? info.join(', ') : '--';
  };

  const getTitleData = () => {
    let DATA = [];
    DATA = [
      <div style={{ paddingLeft: '15px' }}>DATE</div>,
      <div>CLIENT</div>,
      <div>REPORT</div>,
      <div>USER</div>,
      <div>STATUS</div>,
      <div>ACTIONS</div>
    ];
    return DATA;
  };

  const getRowData = () => {
    const DATA = [];
    if (!loading && data) {
      data.forEach(row => {
        const listingRender = [];
        listingRender.push(
          getDate(row),
          getClient(row),
          getReport(row),
          getUser(row),
          getStatus(row),
          getActions(row)
        );
        DATA.push(listingRender);
      });
    }
    return DATA;
  };

  const getDate = (row) => {
    const cell = (<div className="cellview">{moment(row.pushedTime).format('M/D/YY')}</div>);
    return cell;
  };

  const getClient = (row) => {
    const cell = (
      <div className="cellview">
        {row.clientId + ' '}
        <span
          title={row.historyId}
          className="icon-InformationCircle"
          onClick={() => enableLogs(row.historyId)}
          style={{ color: 'blue', cursor: 'pointer' }}
        />
      </div>
    );
    return cell;
  };

  const enableLogs = (historyId) => {
    window.open(
      process.env.REACT_APP_REPORTS_API_HOST + '/api/report-history/' + historyId + '/logs',
      '_blank'
    );
  };

  const getReport = (row) => {
    const cell = (<div className="cellview">{row.reportName}</div>);
    return cell;
  };

  const getUser = (row) => {
    const cell = (<div className="cellview">{row.userId}</div>);
    return cell;
  };

  const getStatus = (row) => {
    const cell = (<div className="cellview">{row.status}</div>);
    return cell;
  };

  const disabled = (scheduleId) => {
    let disableDelete = true;
    if (scheduleId !== null) {
      disableDelete = false;
    }
    return disableDelete;
  }

  const deleteIconColor = (row) => {
    let color='red';
    if (disabled(row.scheduleId) === true) {
      color='#D9D9D9';
    }
    return color;
  }

  const getActions = (row) => {
    const cell = (
      <div className="cellview">
        <Row>
          <Col xs="6" md="6">
            <MozartButton
              data={(
                <div className="ActionButton">
                  <span style={{ paddingRight: '6px' }}>Repush</span>
                  <span style={{ fontSize: '20px', color: 'green' }} className="icon-Send" />
                </div>
              )}
              style={{ fontSize: '14px' }}
              hoverStyle={{ borderRadius: '50px' }}
              background="#FFFFFF"
              onClick={() => rePushHistory(row.historyId)}
            />
          </Col>
          <Col xs="6" md="6">
            <MozartButton
              data={(
                <div className="ActionButton">
                  <span style={{ paddingRight: '6px' }}>Delete Schedule</span>
                  <span style={{ fontSize: '20px', color: deleteIconColor(row) }} className="icon-Delete" />
                </div>
              )}
              style={{ fontSize: '14px' }}
              background="#FFFFFF"
              onClick={() => deleteSchedule(row.historyId)}
              disabled={disabled(row.scheduleId)}
            />
          </Col>
        </Row>
      </div>
    );
    return cell;
  };

  const rePushHistory = (historyId) => {
    setActionId(historyId);
    setActionType('repush');
    setActionButtonText('Repush');
    setConfirmDialog(true);
  };

  const deleteSchedule = (historyId) => {
    setActionId(historyId);
    setActionType('delete');
    setActionButtonText('Delete');
    setConfirmDialog(true);
  };

  const hideConfirm = () => {
    setActionId([]);
    setActionType(null);
    setActionButtonText(null);
    setConfirmDialog(false);
  };

  const performAction = () => {
    setConfirmDialog(false);
    if (actionType === 'repush') {
      props.repushHistory([actionId]);
    }
    if (actionType === 'delete') {
      props.deleteHistory([actionId]);
    }
  };

  return (
    <div className="PageBlock">
      {loading && <MozartLoading />}
      {getMonitorInfo()}
      {data && (
        <MozartTable
          id="action"
          headers={getTitleData()}
          rows={getRowData()}
          bodyStyle={{ fontSize: '0.8em', color: '#666', borderTop: '19px solid #FFFFFF' }}
          colStyle={{ height: '40px', textAlign: 'center' }}
          bgRowColors={['#F5F4FF', '#FFFFFF']}
          rowStyle={{ borderBottom: '5px' }}
          headStyle={{
            textAlign: 'left',
            paddingLeft: '27px',
            height: '13px',
            width: 'auto',
            color: '#FFFFFF',
            fontFamily: 'Roboto',
            fontSize: '13px',
            fontWeight: '500',
            letterSpacing: '1.1px',
            lineHeight: '13px'
          }}
        />
      )}
      {!data && <NotAvailable loadingMessage="No records available!" />}
      {confirmDialog && (
        <MozartModal
          enableCancel
          submitLabel="Yes"
          show={confirmDialog}
          onClose={() => hideConfirm()}
          title="Confirm"
          buttonText={actionButtonText}
          handleSubmit={() => performAction()}
        >
          <span>Are you sure?</span>
        </MozartModal>
      )}
    </div>
  );
}

Action.propTypes = {
  data: PropTypes.array,
  filter: PropTypes.object,
  loading: PropTypes.bool,
  loadStatus: PropTypes.func,
  openFilter: PropTypes.func,
  repushHistory: PropTypes.func,
  deleteHistory: PropTypes.func
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    loadStatus, repushHistory, deleteHistory
  }, dispatch)
);

const mapStateToProps = (state) => ({
  data: state.reportMonitorReducer.data,
  loading: state.reportMonitorReducer.loading
});

export default connect(mapStateToProps, mapDispatchToProps)(Action);
