export const LOAD_BREADCRUM = 'binary/breadcrum/LOAD_BREADCRUM';
export const LOAD_BREADCRUM_DASHBOARD = 'binary/breadcrum/LOAD_BREADCRUM_DASHBOARD';
export const SET_CHART_BREADCRUM_LEVEL = 'binary/breadcrum/SET_CHART_BREADCRUM_LEVEL';

export function loadBreadCrums(path) {
  return {
    type: LOAD_BREADCRUM,
    path
  };
}

export function loadBreadCrumsDashboard(path) {
  return {
    type: LOAD_BREADCRUM_DASHBOARD,
    path
  };
}

export function setBreadCrumLevel(level, prirority) {
  const data = [level, prirority];
  return {
    type: SET_CHART_BREADCRUM_LEVEL,
    data
  };
}
