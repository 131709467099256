export const LOAD = 'binary/roles/LOAD';
export const LOAD_SUCCESS = 'binary/roles/LOAD_SUCCESS';
export const LOAD_FAIL = 'binary/roles/LOAD_FAIL';

export function loadRoles() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],

    payload: {
      client: 'userapi',
      request: {
        method: 'get',
        url: '/api/roles?productId=me'
      }
    }
  };
}
