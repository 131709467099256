import React, { Component } from 'react';
import {
  Logo, Profile,
  UserBucket
} from 'components';
import { connect } from 'react-redux';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Headroom from 'react-headroom';
// import { MozartBreadCrum } from '@pgforsta/mozart_ui';
import { MozartIcon, MozartBreadCrum } from '@pgforsta/mozart_ui';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

class Header extends Component {
  getHeaderBar() {
    const { hasBucket, hasBreadcrum, breadcrums } = this.props;
    const propfileImageUrl = this.props.authReducer.user.profilePictureUrl ? this.props.authReducer.user.profilePictureUrl : false;
    const profileObj = <Profile propfileImageUrl={propfileImageUrl} />;
    const homeIcon = <MozartIcon data={<span className="icon-Home" />} onClick={() => this.goToHomePage()} />;
    const DATA = [];
    DATA.push(
      <Col xs={12} md={12} className="header-box" key="header-sec">
        <Col xs={6} md={6} className="header-box-logo">
          <Navbar>
            <Navbar.Brand>
              <Logo logo="/no_logo.png" />
              {hasBreadcrum && <MozartBreadCrum data={breadcrums} />}
            </Navbar.Brand>
          </Navbar>
        </Col>
        <Col xs={5} md={5} className="header-box-user-queue-list">{hasBucket && <UserBucket onChange={this.props.onChange} hasBucket={hasBucket} />}</Col>
        <Col xs={1} md={1} className="header-box-profile">
          <Col xs={6} md={6}>{homeIcon}</Col>
          <Col xs={6} md={6}>{profileObj}</Col>
        </Col>
      </Col>
    );
    return (
      <div>
        {DATA}
      </div>
    );
  }

  goToHomePage() {
    window.location.href = '/#/';
  }

  subComponentProps(props) {
    return {
      props
    };
  }

  render() {
    return (
      <div>
        <Headroom pinStart={150}>{this.props.authReducer.user && this.getHeaderBar()}</Headroom>
      </div>
    );
  }
}

Header.propTypes = {
  hasBreadcrum: PropTypes.bool,
  hasBucket: PropTypes.bool,
  authReducer: PropTypes.object,
  breadcrums: PropTypes.array,
  onChange: PropTypes.func
};

Header.defaultProps = {
  hasBucket: false
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({}, dispatch)
);

const mapStateToProps = state => ({
  queueReducer: state.queueReducer,
  authReducer: state.authReducer,
  breadcrums: state.breadcrumReducer.breadcrums,
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
