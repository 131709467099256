export const LOAD_ASSIGN = 'binary/queassign/LOAD_ASSIGN';
export const LOAD_ASSIGN_SUCCESS = 'binary/queassign/LOAD_ASSIGN_SUCCESS';
export const LOAD_ASSIGN_FAIL = 'binary/queassign/LOAD_ASSIGN_FAIL';

export const LOAD_SAVE = 'binary/queassign/LOAD_SAVE';
export const LOAD_SAVE_SUCCESS = 'binary/queassign/LOAD_SAVE_SUCCESS';
export const LOAD_SAVE_FAIL = 'binary/queassign/LOAD_SAVE_FAIL';

export function loadAssignUser() {
  return {
    types: [LOAD_ASSIGN, LOAD_ASSIGN_SUCCESS, LOAD_ASSIGN_FAIL],
    payload: {
      client: 'managementengagement',
      request: {
        method: 'get',
        url: '/api/users'
      }
    }
  };
}

export function saveUser(data) {
  return {
    types: [LOAD_SAVE, LOAD_SAVE_SUCCESS, LOAD_SAVE_FAIL],
    payload: {
      client: 'managementengagement',
      request: {
        method: 'post',
        url: '/api/users',
        data: data
      }
    }
  };
}
