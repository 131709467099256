import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
// import { PriorityChartLegend } from 'components';
import {
  BarChart, Bar, YAxis, CartesianGrid, Tooltip, LabelList, Cell, ResponsiveContainer
} from 'recharts';
import { loadPriority, loadClientPriority, loadSourcePriority } from 'redux/actions/priorityAction';
import { loadBreadCrumsDashboard } from 'redux/actions/breadcrumAction';
import PropTypes from 'prop-types';
import './PriorityChart.scss';

class PriorityChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      priorityData: this.props.priorityReducer ? this.props.priorityReducer.data : [],
      priorityClientData: this.props.priorityReducer ? this.props.priorityReducer.clientData : [],
      currentLevel: this.props.currentLevel,
      currentPriorityLevel: 1,
      currentClient: this.props.selectedClient ? this.props.selectedClient : null,
      currentSource: '100171',
      selectedClient: this.props.selectedClient ? this.props.selectedClient : null,
      breadCrumList: [{ id: 1, name: 'Queue Status Dashboard' }],
    };
  }

  componentDidMount() {
    console.log(this.state.breadCrumList);
    this.props.loadPriority().then(() => {
      if (this.props.priorityReducer && this.props.priorityReducer.data) {
        this.setState({
          priorityData: this.props.priorityReducer ? this.props.priorityReducer.data : [],
        });
      }
    });
  }

  getCustomBreadCrum(item, currentLevel) {
    this.props.loadBreadCrumsDashboard(this.getBreadCrums(item, currentLevel));
  }

  getBreadCrums(item, currentLevel) {
    const breadCrumArray = [];
    breadCrumArray.push({ id: 1, name: 'Queue Status Dashboard' });
    if (currentLevel === 1) {
      breadCrumArray.push({ id: 2, name: this.getTitle(item), onClick: () => { this.getNextLevelChart(2, 1); } });
    }
    if (currentLevel === 2) {
      breadCrumArray.push({ id: 2, name: this.getTitle(item), onClick: () => { this.getNextLevelChart(2, 1); } });
      breadCrumArray.push({ id: 3, name: item, onClick: () => { this.getNextLevelChart(3, 2); } });
    }
    return breadCrumArray;
  }

  getBarChart(priorityType) {
    const chartData = [];
    let priorutyDataObj = this.state.priorityData;
    if (this.state.currentLevel === 1) {
      priorutyDataObj = this.state.priorityClientData;
    }
    if (priorutyDataObj) {
      let chartDataObj = '';
      Object.keys(priorutyDataObj).forEach(key => {
        if (priorutyDataObj[key].queueName === priorityType) {
          chartDataObj = priorutyDataObj[key];
        }
      });
      chartData.push({ name: 'queueSize', count: chartDataObj.queueSize > 0 ? chartDataObj.queueSize : 0, fillColor: '#403788' });
      chartData.push({ name: 'numberOfTasksInprogressCount', count: chartDataObj.numberOfTasksInprogressCount ? chartDataObj.numberOfTasksInprogressCount : 0, fillColor: '#715AFF' });
      chartData.push({ name: 'numberOfTasksCompletedCount', count: chartDataObj.numberOfTasksPendingApprovalCount ? chartDataObj.numberOfTasksPendingApprovalCount : 0, fillColor: '#D3CFF8' });
      chartData.push({ name: 'numberOfTaskSuccessCount', count: chartDataObj.numberOfTaskSuccessCount ? chartDataObj.numberOfTaskSuccessCount : 0, fillColor: '#47B300' });
      chartData.push({ name: 'numberOfTaskInSupportCount', count: chartDataObj.numberOfTaskInSupportCount ? chartDataObj.numberOfTaskInSupportCount : 0, fillColor: '#F76E27' });
      chartData.push({ name: 'numberOfTaskFailedCount', count: chartDataObj.numberOfTaskFailedCount ? chartDataObj.numberOfTaskFailedCount : 0, fillColor: '#FF0000' });
    }
    const renderCustomBarLabel = ({
      x,
      y,
      width,
      value
    }) => {
      const dataVal = value;
      return <text x={x + width / 2} y={y} fill="#666" textAnchor="middle" dy={-6}>{`${dataVal}`}</text>;
    };
    return (
      <Col xs={12} md={12} style={{ width: '100%', height: '100%' }}>
        <ResponsiveContainer width="99%" height="80%">
          <BarChart
            data={chartData}
            margin={{
              top: 25,
              bottom: 5
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="count" fill="#8884d8" minPointSize={5} barSize={30} label={renderCustomBarLabel}>
              {chartData.map((entry, index) => (
                <Cell fill={chartData[index].fillColor} />
              ))}
              {chartData.map((entry, indexCount) => (
                <LabelList dataKey="" content={chartData[indexCount].count} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Col>
    );
  }

  getNextLevelChart(currentLevel, currentPriorityLevel) {
    if (currentPriorityLevel === 'HIGH') {
      currentPriorityLevel = 1;
    }
    if (currentPriorityLevel === 'MEDIUM') {
      currentPriorityLevel = 2;
    }
    if (currentPriorityLevel === 'LOW') {
      currentPriorityLevel = 3;
    }
    if (currentLevel && currentPriorityLevel) {
      if (currentLevel === 1) {
        const postData = {
          clientId: this.state.currentClient,
          priorityId: currentPriorityLevel
        };
        this.props.loadClientPriority(postData).then(() => {
          if (this.props.priorityReducer && this.props.priorityReducer.clientData) {
            this.setState({
              priorityClientData: this.props.priorityReducer ? this.props.priorityReducer.clientData : [],
              currentLevel: currentLevel,
              currentPriorityLevel: currentPriorityLevel
            });
            this.getCustomBreadCrum(currentPriorityLevel, currentLevel);
          }
        });
      }
      if (currentLevel === 2) {
        const postData = {
          clientId: this.state.currentClient,
          priorityId: currentPriorityLevel,
          source: this.state.currentSource
        };
        this.props.loadSourcePriority(postData).then(() => {
          if (this.props.priorityReducer && this.props.priorityReducer.clientData) {
            this.setState({
              priorityClientData: this.props.priorityReducer ? this.props.priorityReducer.clientData : [],
              currentLevel: currentLevel,
              currentPriorityLevel: currentPriorityLevel
            });
            this.getCustomBreadCrum(this.state.currentClient, currentLevel);
          }
        });
      }
    }
  }

  getPriorityCharts() {
    return (
      <Row className="priority-chart-block">
        <Col xs={4} md={4} onClick={() => { this.getNextLevelChart(1, 1); }}>
          <div className="priority-chart">
            <div className="chart-header"><span className="title">High Priority</span></div>
            {this.getBarChart('HIGH')}
          </div>
        </Col>
        <Col xs={4} md={4} onClick={() => { this.getNextLevelChart(1, 2); }}>
          <div className="priority-chart">
            <div className="chart-header"><span className="title">Medium Priority</span></div>
            {this.getBarChart('MEDIUM')}
          </div>
        </Col>
        <Col xs={4} md={4} onClick={() => { this.getNextLevelChart(1, 3); }}>
          <div className="priority-chart">
            <div className="chart-header"><span className="title">Low Priority</span></div>
            {this.getBarChart('LOW')}
          </div>
        </Col>
      </Row>
    );
  }

  getTitle(priorityLevel) {
    let priorityLevelTitle = 'HIGH';
    if (priorityLevel === 1) {
      priorityLevelTitle = 'HIGH';
    }
    if (priorityLevel === 2) {
      priorityLevelTitle = 'MEDIUM';
    }
    if (priorityLevel === 3) {
      priorityLevelTitle = 'LOW';
    }
    return priorityLevelTitle;
  }

  getClientPriorityCharts() {
    const title = this.getTitle(this.state.currentPriorityLevel) + ' Priority';
    return (
      <Row className="priority-chart-block priority-based-on-client">
        <Col xs={12} md={12} onClick={() => { this.getNextLevelChart(2, this.state.currentPriorityLevel); }}>
          <div className="priority-chart">
            <div className="chart-header">
              <span className="title">
                {title}
              </span>
            </div>
            {this.getBarChart(this.state.currentPriorityLevel)}
          </div>
        </Col>
      </Row>
    );
  }

  getSourcePriorityCharts() {
    const title = this.getTitle(this.state.currentPriorityLevel) + ' Priority';
    return (
      <Row className="priority-chart-block priority-based-on-source">
        <Col xs={12} md={12}>
          <div className="priority-chart">
            <div className="chart-header">
              <span className="title">
                {title}
              </span>
            </div>
            {this.getBarChart(this.state.currentPriorityLevel)}
          </div>
        </Col>
      </Row>
    );
  }

  render() {
    return (
      <div key={Math.random()}>
        {this.state.currentLevel === 0 && this.state.priorityData && this.getPriorityCharts()}
        {this.state.currentLevel === 1 && this.state.selectedClient && this.getClientPriorityCharts()}
        {this.state.currentLevel === 2 && this.getSourcePriorityCharts()}
      </div>
    );
  }
}

PriorityChart.propTypes = {
  priorityReducer: PropTypes.object,
  selectedClient: PropTypes.string,
  loadBreadCrumsDashboard: PropTypes.func,
  loadClientPriority: PropTypes.func,
  loadSourcePriority: PropTypes.func,
  loadPriority: PropTypes.func,
  currentLevel: PropTypes.number,
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    loadPriority,
    loadClientPriority,
    loadSourcePriority,
    loadBreadCrumsDashboard
  }, dispatch)
);

const mapStateToProps = state => ({
  priorityReducer: state.priorityReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(PriorityChart);
