export const LOAD = 'binary/documentAutoGenerate/LOAD';
export const LOAD_SUCCESS = 'binary/documentAutoGenerate/LOAD_SUCCESS';
export const LOAD_FAIL = 'binary/documentAutoGenerate/LOAD_FAIL';
export const RESET = 'binary/documentAutoGenerate/RESET';

export function load(isAdmin, params) {
  let path = '';
  if (isAdmin) {
    path = '/api/content-edit/nextlocked';
  } else {
    path = '/api/content-edit/generate';
  }
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],

    payload: {
      client: 'tagging',
      request: {
        method: isAdmin ? 'GET' : 'POST',
        url: path,
        data: isAdmin ? {} : params
      }
    }
  };
}

export function reset() {
  return {
    type: RESET
  };
}
