import {
  LOAD,
  LOAD_SUCCESS,
  LOAD_FAIL,
  LOAD_MORE,
  LOAD_MORE_SUCCESS,
  LOAD_MORE_FAIL,
  CREATE_EXPORT,
  CREATE_EXPORT_SUCCESS,
  CREATE_EXPORT_FAIL
} from 'redux/actions/priorityTableAction';

const initialState = {
  loaded: false,
  loading: false,
  loadedMore: false,
  loadingMore: false,
  loadingExport: false,
  loadedExport: false,
  data: [],
  dataLoadMore: [],
  pagination: false,
  paginationCount: 0
};

function priorityTableReducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
        loaded: false,
        // pagination: true
      };
    case LOAD_SUCCESS:
      const priorityData = action.payload.data;
      let paginationStatus = false;
      if (priorityData.length >= 20) {
        paginationStatus = true;
      }
      return {
        ...state,
        loading: false,
        loaded: true,
        data: priorityData,
        pagination: paginationStatus,
        paginationCount: priorityData.length,
        dataLoadMore: priorityData
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
        pagination: false
      };
    case LOAD_MORE:
      return {
        ...state,
        loadingMore: true,
        loadedMore: false,
      };
    case LOAD_MORE_SUCCESS:
      const stateData = state.data;

      const priorityMoreData = stateData.concat(action.payload.data);
      const totalCount = priorityMoreData.length;
      let paginationMoreStatus = false;
      if (priorityMoreData.length % 20 === 0) {
        paginationMoreStatus = true;
      }
      return {
        ...state,
        loadingMore: false,
        loadedMore: true,
        pagination: paginationMoreStatus,
        paginationCount: totalCount,
        data: priorityMoreData,
        dataLoadMore: action.payload.data
      };
    case LOAD_MORE_FAIL:
      return {
        ...state,
        loadingMore: false,
        loadedMore: false,
        error: action.error,
        pagination: false,
        paginationCount: 0
      };
    case CREATE_EXPORT:
      return {
        ...state,
        loadingExport: true,
        loadedExport: false
      };
    case CREATE_EXPORT_SUCCESS:
      return {
        ...state,
        loadingExport: false,
        loadedExport: true,
      };
    case CREATE_EXPORT_FAIL:
      return {
        ...state,
        loadingExport: false,
        loadedExport: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export default priorityTableReducer;
