export const LOAD = 'binary/date/LOAD';
export const UPDATE = 'binary/date/UPDATE';
export const UPDATE_ORIGINAL_DATA_FROM_DATA =
  'binary/date/UPDATE_ORIGINAL_DATA_FROM_DATA';
export const UPDATE_DATA_FROM_ORIGINAL_DATA =
  'binary/date/UPDATE_DATA_FROM_ORIGINAL_DATA';
export const RESET = 'binary/date/RESET';
export const LOAD_SAVED_FILTER = 'binary/date/LOAD_SAVED_FILTER';

export function isLoaded(globalState) {
  return globalState.filterDate && globalState.filterDate.loaded;
}

export function isLoading(globalState) {
  return globalState.filterDate && globalState.filterDate.loading;
}

export function load() {
  return {
    type: LOAD,
  };
}

export function update(data) {
  return {
    type: UPDATE,
    result: data,
  };
}

export function reset() {
  return {
    type: RESET,
  };
}

export function loadSavedFilter(savedData) {
  return {
    type: LOAD_SAVED_FILTER,
    result: savedData,
  };
}

export function updateOriginalDataFromData() {
  return {
    type: UPDATE_ORIGINAL_DATA_FROM_DATA,
  };
}

export function updateDataFromOriginalData() {
  return {
    type: UPDATE_DATA_FROM_ORIGINAL_DATA,
  };
}
