import { MozartTextBox } from '@pgforsta/mozart_ui';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import './UserBucket.scss';

class OptionField extends Component {
  render() {
    const refText = React.createRef();
    return (
      <div>
        <MozartTextBox
          key="chat_textarea"
          id="chat_textarea"
          className="comment_textarea"
          name="comment"
          customRef={refText}
          hintText="hjdkfhdsjkdfh"
          multiLine={false}
        />
      </div>
    );
  }
}

export default connect()(OptionField);
