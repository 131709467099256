export const LOAD_TASK = 'binary/supportTask/LOAD_TASK';
export const LOAD_TASK_SUCCESS = 'binary/supportTask/LOAD_TASK_SUCCESS';
export const LOAD_TASK_FAIL = 'binary/supportTask/LOAD_TASK_FAIL';
export const LOAD_PAGE_LOAD_TASK = 'binary/supportTask/LOAD_PAGE_LOAD_TASK';
export const LOAD_PAGE_LOAD_TASK_SUCCESS = 'binary/supportTask/LOAD_PAGE_LOAD_TASK_SUCCESS';
export const LOAD_PAGE_LOAD_TASK_FAIL = 'binary/supportTask/LOAD_PAGE_LOAD_TASK_FAIL';

export function loadSupportTask(postData) {
  return {
    types: [LOAD_TASK, LOAD_TASK_SUCCESS, LOAD_TASK_FAIL],
    payload: {
      client: 'managementengagement',
      request: {
        method: 'post',
        data: postData,
        url: '/api/tasks/details'
      }
    }
  };
}

export function loadSupportTaskPageLoad(postData) {
  return {
    types: [LOAD_PAGE_LOAD_TASK, LOAD_PAGE_LOAD_TASK_SUCCESS, LOAD_PAGE_LOAD_TASK_FAIL],
    payload: {
      client: 'managementengagement',
      request: {
        method: 'post',
        data: postData,
        url: '/api/tasks/details'
      }
    }
  };
}
