export const LOAD = 'binary/category/LOAD';
export const LOAD_SUCCESS = 'binary/category/LOAD_SUCCESS';
export const LOAD_FAIL = 'binary/category/LOAD_FAIL';

export function load(product) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],

    payload: {
      client: 'nextgen',
      request: {
        method: 'get',
        url: '/api/categories/tree/' + product
        // url: '/api/content-edit/queue/user-buckets'
      }
    }
  };
}
